import { Circles } from "react-loader-spinner";
import React from "react";
import { Box, Modal } from "@mui/material";

interface LoadingProps {
    isLoading : boolean;
}

const Loading = ({isLoading}:LoadingProps) => {
  return (
    <Modal open={isLoading} >
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Circles
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    </Modal>
  );
};
export default Loading;
