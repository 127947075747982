import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ErrorData, ResponseData } from "../types/signup/User";
import { InterestTypes } from "../types/signup/interests";

 // Function to fetch invite count from the server
 export const fetchInviteCount = async () => {
    try {
      // Perform an API call to fetch the invite count
      const response = await getAxiosClient(false).get(HttpService.ARTICLE_COUNT);
      console.log(response);
      console.log(response.data);
      return response.data;  


    } catch (error) {
      console.error('Error fetching invite count:', error);
    }
  };

export const updateInviteCount = async() => {
    try {
        const data = await getAxiosClient(false).post(HttpService.ARTICLE_COUNT, 
        {});
      } catch (e: any) {
       // throw e.response.data as ErrorData;
      }
};