// Post.js
import {
  Box,
  Link,
  Typography,
  useTheme,
  Grid,
  Card,
  Button,
  Icon,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/logo";
import LadyImage from "../../assets/images/ladyImage";
import { Border } from "react-bootstrap-icons";
import "./blogmobile.styles.scss";
import HttpService from "../../http-client/services";
import PressureCooker from "../../assets/images/pressureCooker";
import { fetchInviteCount, updateInviteCount } from "../../service/blog";
import CoupleImage from "../../assets/images/couple";
import { getOrderStatus } from "../../service/order";
import { useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import Icons from "../../assets/icons";
import heic2any from "heic2any";
import { uploadPaymentProof, updateManualPayment } from "../../service/user";
import { getUserSelectedPlanDetails } from "../../service/order";
import useUser from "../../context/UserContext";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface OrderStatus {
  payment_status: string;
  membership_name: string;
  membership_starts_at: string;
  membership_ends_at: string;
}

const UPIPaymentLayout = () => {
  const orderId = localStorage.getItem("pgorderId");
  const [membershipActivated, setMembershipActivated] = useState(false);
  const [paymentFailedOrUnknown, setPaymentFailedOrUnknown] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderStatus>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [paymentScreenshotUrl, setPaymentScreenshotUrl] = useState("");
  const [amount, setAmount] = useState("");
  const [planId, setPlanId] = useState<string>();
  
  const theme = useTheme();

  const navigate = useNavigate();
  const { userId } = useUser();

  const redirectToDashboard = () => {
    localStorage.removeItem("pgdetails");
    localStorage.removeItem("pgorderId");
    navigate(RouterPath.Dashboard);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const planId = localStorage.getItem("plan_id");
        setPlanId(planId!)
        const result = await getUserSelectedPlanDetails(planId!);
        setAmount(result);
      } catch (error) {
        console.error("Error fetching plan details:", error);
      }
    };

    fetchData(); // Call the async function

    // Cleanup logic goes here if needed
  }, []);

  const paymentVerification = async () => {
    console.log("userid is" + userId);
    console.log("paymentScreenshotUrl" + paymentScreenshotUrl);
    console.log("amount", amount);
    const result = await updateManualPayment(
      userId!,
      paymentScreenshotUrl,
      parseInt(amount),
      planId!
    );
    if (result == "OK") {
      navigate(RouterPath.Dashboard);
    }
  };

  const openSavedDocument = (url: string) => {
    window.open(paymentScreenshotUrl, "_blank");
  };

  const removedSavedDocument = (url: string) => {
    setPaymentScreenshotUrl("");
    setIsFileUploaded(false);
  };

  const handleCopy = async () => {
    try {
      console.log("inside copy");
      var text = "9008992736@okbizaxis";
      await navigator.clipboard.writeText(text);
      toast.info("Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const onSelectFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length && userId) {
        console.log("userId", userId);
        try {
          let file: File = event.target.files[0];
          setShowLoading(true);

          const fileBlocks = file.name.split(".");
          const fileExtension = fileBlocks[fileBlocks.length - 1];
          if (
            event.target.files[0].type === "image/heic" ||
            fileExtension === "heic"
          ) {
            const blob = (await heic2any({
              blob: file,
              toType: "image/jpeg",
            })) as Blob;
            fileBlocks[fileBlocks.length - 1] = "jpeg";
            const fileName = fileBlocks.join(".");
            file = new File([blob], fileName, { type: "image/jpeg" });
          }

          const result = await uploadPaymentProof({
            file: file,
            user: userId,
          });
          setIsFileUploaded(true);
          setPaymentScreenshotUrl(result.data.url);
          setShowLoading(false);
        } catch (e) {
          // TODO
          setShowLoading(false);
        }
      }
    },
    []
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
    <Header  showHamburgerMenu={false} />

      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          margin: "16px",
          paddingBottom:"200px !important"
        }}
      >
        <ToastContainer />

        <Grid
          container
          spacing={2}
          marginTop={"16px"}
          paddingLeft={"16px"}
          textAlign={"center"}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            <div style={{ padding: "50", textAlign: "center" }}>
              <br />
              <span style={{ fontSize: "1.6em" }}>
                {" "}
                Pay &#8377; {amount}/- <br /> and Upload the screenshot.{" "}
              </span>{" "}
              <br />
              <strong>UPI ID:9008992736@okbizaxis</strong>{" "}
              <img
                src={Icons.Copy}
                style={{ width: "16px", height: "16px" }}
                onClick={handleCopy}
              />
            </div>
            <img src={Icons.QRCode} style={{ width: "100%" }} />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          marginTop={"16px"}
          paddingLeft={"16px"}
          textAlign={"center"}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            <Link
              component="button"
              variant="body2"
              hidden={!isFileUploaded}
              style={{
                color: "gray",
                textDecoration: "underline",
                textAlign: "left",
                marginTop: "5px",
              }}
              onClick={() => openSavedDocument("")}
            >
              View
            </Link>

            <Link
              component="button"
              variant="body2"
              lineHeight={"1.5"}
              hidden={!isFileUploaded}
              style={{
                color: "gray",
                textDecoration: "underline",
                textAlign: "left",
                marginLeft: "10px",
                marginTop: "5px",
              }}
              onClick={() => removedSavedDocument("")}
            >
              Remove
            </Link>

            <Button
              variant="contained"
              component="label"
              hidden={isFileUploaded}
            >
              Upload Screenshot
              <input type="file" hidden accept="*" onChange={onSelectFile} />
            </Button>
            <Button
              variant="contained"
              component="label"
              // disabled={!linkedin_url || !instagram_url || !isFileUploaded}
              disabled={!isFileUploaded}
              hidden={!isFileUploaded}
              style={{
                marginLeft: "20px",
              }}
              onClick={() => paymentVerification()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} paddingLeft={"16px"} marginTop={"0"}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          ></Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default UPIPaymentLayout;
