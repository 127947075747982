import HttpService from "../http-client/services";
import { PincodeData } from "../types/signup/pincode";
import getAxiosClient from "../http-client/httpClient";

export const subscribeUserForNotifications = async (userId: string, subscription: any) => {
  try {
   const data = await getAxiosClient(false).post(HttpService.SUBSCRIBE, {
    userId: userId,
    subscription: subscription,
   });

   console.log(data);
  } catch (e) {
    throw e;
  }
};
