import React, { useCallback } from "react";

import Header from "../../components/Header";
import { Box, useTheme } from "@mui/material";
import { SignupSteps } from "../../constants/signup-steps";
import UserProfileLayout from "./UserProfileLayout";
import QuestionLayout from "./QuestionLayout";
import { Question } from "../../types/signup";
import PhotosLayout from "./PhotosLayout";
import InterestLayout from "./InterestLayout";
import HowItWorksLayout from "./HowItWorksLayout";
import SlotsLayout from "./SlotLayout";
import TermsLayout from "./TermsLayout";
import ReferralLayout from "./ReferralLayout";
import { InterestTypes } from "../../types/signup/interests";
import Loading from "../../components/Loading";
import { Slots } from "../../types/signup/slots";
import UserNamesLayout from "./UserNamesLayout";
import PhoneLayout from "./PhoneLayout";
import { UserProfile } from "../../types/signup/profile";
import DateOfBirthLayout from "./DateOfBirthLayout";
import AreaLayout from "./AreaLayout";
import AboutMeLayout from "./AboutMeLayout";
import MicrophoneAccessLayout from "./MicrophoneAccessLayout";
import NotificationsAccessLayout from "./NotificationsAccessLayout";

interface UserLayoutProps {
  currentStep: SignupSteps;
  currentQuestion: Question;
  onPressNext: () => void;
  onChangeValue: (value: string) => void;
  onSubmitInterests: (interest: number[]) => void;
  interestTypes: InterestTypes[];
  onSubmitUserProfile: (value: Partial<UserProfile>) => void;
  onSubmitPhotos: (file: string[]) => void;
  showLoading: boolean;
  slots?: Slots;
  onSubmitSlots: (slots: number[]) => void;
  onClickBack: ()=> void;
  onClickSkip: ()=> void;
  onSubmitAboutMe: (file: Blob) => void
}

const UserLayout = ({
  currentStep,
  currentQuestion,
  onPressNext,
  onChangeValue,
  interestTypes,
  onSubmitInterests,
  onSubmitUserProfile,
  onSubmitPhotos,
  showLoading,
  slots,
  onSubmitSlots,
  onClickBack,
  onClickSkip,
  onSubmitAboutMe
}: UserLayoutProps) => {
  const theme = useTheme();

  const getLayout = useCallback(() => {
    console.log(currentStep);
  
    if (currentStep === SignupSteps.BASIC_DETAILS) {
      return <UserProfileLayout onPressNext={onSubmitUserProfile} />;
    }
    if (currentStep === SignupSteps.FIRST_LAST_NAME) {
      return <UserNamesLayout onPressNext={onSubmitUserProfile} />;
    }
    if (currentStep === SignupSteps.PHONE_NUMBER) {
      return <PhoneLayout onPressNext={onSubmitUserProfile} />;
    }
    if (currentStep === SignupSteps.DATE_OF_BIRTH) {
      return <DateOfBirthLayout onPressNext={onSubmitUserProfile} />;
    }
    if (currentStep === SignupSteps.CITY_AREA) {
      return <AreaLayout onPressNext={onSubmitUserProfile} />;
    }
    if (currentStep === SignupSteps.PHOTOS) {
      return <PhotosLayout onPressNext={onSubmitPhotos} />;
    }
    if (currentStep === SignupSteps.QUESTIONS) {
      return (
        <QuestionLayout
          question={currentQuestion}
          onPressNext={onPressNext}
          onChangeValue={onChangeValue}
        />
      );
    }
    if (currentStep === SignupSteps.INTERESTS) {
      return (
        <InterestLayout
          onPressNext={onSubmitInterests}
          interestTypes={interestTypes}
        />
      );
    }
    if (currentStep === SignupSteps.TNC) {
      return <TermsLayout onPressNext={onPressNext} />;
    }
    if (currentStep === SignupSteps.HOW_IT_WORKS) {
      return <HowItWorksLayout onPressNext={onPressNext} />;
    }
    if (currentStep === SignupSteps.SLOTS) {
      return <SlotsLayout onPressNext={onSubmitSlots} slots={slots} />;
    }
    if (currentStep === SignupSteps.ABOUT_ME) {
      return <AboutMeLayout onPressNext={onSubmitAboutMe} />;
    }
    if (currentStep === SignupSteps.MICROPHONE_ACCESS) {
      return <MicrophoneAccessLayout onPressNext={onPressNext} />;
    }

    if (currentStep === SignupSteps.NOTIFICATION_ACCESS) {
      return <NotificationsAccessLayout onPressNext={onPressNext} />;
    }
    if (currentStep == SignupSteps.REFER) {
      return <ReferralLayout  onPressNext={onSubmitSlots} slots={slots} />;
    }
  }, [
    currentStep,
    currentQuestion,
    onChangeValue,
    onPressNext,
    interestTypes,
    onSubmitInterests,
    onSubmitUserProfile,
    onSubmitPhotos,
    slots,
    onSubmitSlots
  ]);

  return (
    <>
      <Box
        sx={{
          background: theme.palette.background.default,
          width: "100%",
          height: "100vh",
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Header onPressBack={currentStep === SignupSteps.BASIC_DETAILS ?  undefined : onClickBack} onClickSkip={onClickSkip} showHamburgerMenu={false}/>

        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={"0px 16px 16px 16px"}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent={{ xs: "flex-start", md: "center" }}
          height={"100%"}
        >
          {getLayout()}
        </Box>
        <Loading isLoading={showLoading} />
      </Box>
    </>
  );
};
export default UserLayout;
