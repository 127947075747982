import React, { useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import useUser from "../../../context/UserContext";
import { useMediaQuery } from "react-responsive";
import Icons from "../../../assets/icons";

interface PhotosItemProps {
  index: number;
  photos: string[];
  onClickAddImage: () => void;
  onClickDelete: (index: number)=>void;
}

const PhotosItem = ({ index, photos, onClickAddImage, onClickDelete }: PhotosItemProps) => {
  const { userData } = useUser();

  const isMobileDevice = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1223 });

  const photosLength = useMemo(() => {
    return userData?.data?.photos?.length ?? 0;
  }, [userData]);
  const { width, height } = useMemo(() => {
    if (isMobileDevice) {
      return {
        width: "78px",
        height: "78px",
      };
    }
    if (isTablet) {
      return {
        width: "120px",
        height: "120px",
      };
    }
    return {
      width: "144px",
      height: "144px",
    };
  }, [isMobileDevice, isTablet]);

  const url = useMemo(() => { 
    return  photos[index];
  }, [photos,index]);

  const showDelete = useMemo(() => {
    return true;
    // if (photos?.[index - photosLength]) {
    //   return true;
    // }
    // return false;
  }, [photos, photosLength, index]);

  const onDelete = useCallback(()=>{
    onClickDelete(index)
  },[photosLength, index, onClickDelete])

  return (
    <Grid item xs={4}>
      {url ? (
        <Box display={"flex"}>
          <Box
            sx={{
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            width={width}
            height={height}
          >
            <img
              src={url}
              alt="Profile pic"
              width={width}
              height={height}
              style={{
                objectFit: "cover",
              }}
            />
          </Box>

          {showDelete && (
            <img
              src={Icons.Add}
              width={"12px"}
              height={"12px"}
              alt="Delete"
              style={{
                marginTop: -6,
                marginLeft: -6,
                transform: 'rotate(45deg)'
              }}
              onClick={onDelete}
            />
          )}
        </Box>
      ) : (
        <Box display={"flex"}>
          <Box
            sx={{
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            width={width}
            height={height}
            onClick={onClickAddImage}
          />
          <img
            src={Icons.Add}
            width={"12px"}
            height={"12px"}
            alt="Add"
            style={{
              marginTop: -6,
              marginLeft: -6,
            }}
          />
        </Box>
      )}
    </Grid>
  );
};
export default PhotosItem;
