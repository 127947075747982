import { Activity } from "react-bootstrap-icons";
import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import {
  ErrorData,
  ResponseData,
  UploadPic,
  UserData,
  ActivityData,
} from "../types/signup/User";
import { MembershipPlans } from "../types/plan";
import { string } from "yup";

export const getActvityCountData = async () => {
  try {
    console.log("calling");
    const data = await getAxiosClient(false).get(HttpService.ACTIVITY);
    const activityData: ActivityData = data.data;
    console.log(activityData.male_users_count);
    return activityData;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const getUser = async (user: string) => {
  try {
    const data = await getAxiosClient(false).get(HttpService.USER + user);
    return data.data as ResponseData<UserData>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

interface UpdateUser {
  user: string;
  page: string;
  data: any;
  skipped?: boolean;
}

export const updateUser = async ({ user, page, data, skipped }: UpdateUser) => {
  try {
    let url = `${HttpService.USER}${user}?page=${page}`;
    if (skipped) {
      url = `${url}&skipped=${skipped}`;
    }
    const response = await getAxiosClient(false).put(url, {
      ...data,
    });
    return response.data as ResponseData<UserData>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

interface UploadPhoto {
  user: string;
  file: any;
}
export const uploadPhoto = async ({ user, file }: UploadPhoto) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await getAxiosClient(false).post(
      `/twist-api/api/v1/users/${user}/document/photo`,
      formData
    );
    return response.data as ResponseData<UploadPic>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const uploadAboutMeAudio = async ({ user, file }: UploadPhoto) => {
  try {
    const formData = new FormData();
    formData.append("file", file, "audio.wav");
    const response = await getAxiosClient(false).post(
      `/twist-api/api/v1/users/${user}/document/aboutme-audio`,
      formData
    );
    return response.data as ResponseData<UploadPic>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const uploadIDProof = async ({ user, file }: UploadPhoto) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await getAxiosClient(false).post(
      `/twist-api/api/v1/users/${user}/document/id-proof`,
      formData
    );
    return response.data as ResponseData<UploadPic>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const uploadPaymentProof = async ({ user, file }: UploadPhoto) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await getAxiosClient(false).post(
      `/twist-api/api/v1/users/${user}/document/payment-proof`,
      formData
    );
    return response.data as ResponseData<UploadPic>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const updateLastActiveTime = async (user: string) => {
  try {
    let url = `${HttpService.USER}${user}?page=UPDATE_LAST_ACTIVE_TIME`;
    const response = await getAxiosClient(false).put(url, {});
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

interface DeletePhoto {
  user: string;
  url: string;
}
export const deletePhoto = async ({ user, url }: DeletePhoto) => {
  try {
    const response = await getAxiosClient(false).delete(
      `/twist-api/api/v1/users/${user}/document/photo`,
      {data:{
        url: url
      }}
    );
    return response.data as ResponseData<any>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const checkMembership = async (user: string) => {
  try {
    const response = await getAxiosClient(false).get(
      `${HttpService.USER}${user}/membership`
    );
    return response.data as string;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const getUserPlans = async (user: string) => {
  try {
    const response = await getAxiosClient(false).get(
      `${HttpService.USER}${user}/plans`
    );
    console.log("response" + response);
    return response.data as MembershipPlans;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const updateManualPayment = async (
  userId: string,
  screenshotUrl: string,
  amount: number,
  planId: string
) => {
  try {
    const response = await getAxiosClient(false).post(
      `${HttpService.USER}/manual-payment-details`,
      {
        userId: userId,
        payment_screenshot_url: screenshotUrl,
        amount: amount,
        planId: planId,
      }
    );
    console.log("response" + response);
    return response.data as string;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const deleteAccount = async (userId: string) => {
  try {
    const response = await getAxiosClient(false).delete(
      `${HttpService.USER}${userId}/account`,
      {}
    );
    return response.data as string;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};


export const updateProfile = async (userId: string) => {
  try {
    const response = await getAxiosClient(false).post(
      `${HttpService.USER}${userId}/updateProfile`,
      {}
    );
    return response.data as string;
    
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};


