import { Box, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";
import { UserProfile } from "../../../types/signup/profile";


interface UserProfileLayoutProps {
  onPressNext: (value: Partial<UserProfile>) => void;
}

const PhoneLayout = ({ onPressNext }: UserProfileLayoutProps) => {
  const { userData } = useUser();

  const Schema = Yup.object({
    primary_phone: Yup.string()
      .min(10, "Please enter valid phone number")
      .required("Please enter your phone number"),
  });

  const onSubmit = useCallback(
    (values: Partial<UserProfile>) => {
      onPressNext(values);
    },
    [onPressNext]
  );

  const formik = useFormik({
    initialValues: {
      primary_phone: "",
    } as Partial<UserProfile>,
    validationSchema: Schema,
    onSubmit: onSubmit,
  });
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
  } = formik;

  useEffect(() => {
    if (userData?.data?.basic_details) {
      setFieldValue(
        "primary_phone",
        userData?.data?.basic_details?.primary_phone?.number
      );
    }
  }, [userData, setFieldValue]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="What's your mobile number?" subtitle="You would receive communications about your date on this number." />
     

        <Grid container spacing={2} marginTop={"8px"}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Mobile Number"
              variant="outlined"
              type="tel"
              fullWidth
              inputProps={{ maxLength: 10 }}
              value={values.primary_phone}
              error={Boolean(touched.primary_phone && errors.primary_phone)}
              onChange={handleChange("primary_phone")}
            />
            {touched.primary_phone && errors.primary_phone && (
              <FormHelperText>{errors.primary_phone}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <NextButton onPressNext={handleSubmit} />

      </Box>
    </>
  );
};
export default PhoneLayout;
