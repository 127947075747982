import React, { useMemo, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Laptop } from "../../layouts/Home/laptop/laptop.component";
import { Mobile } from "../../layouts/Home/mobile/mobile.component";
import { TabletMobile } from "../../layouts/Home/tablet-mobile/tablet-mobile.component";
import { sendOTP } from "../../service/signup";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import Loading from "../../components/Loading";
import { verifyOTP } from "../../service/signup";
import { ErrorData, ResponseData, SignupData } from "../../types/signup/User";
import { SignupSteps } from "../../constants/signup-steps";
import useUser from "../../context/UserContext";



const Home = () => {

  const location = useLocation();
  const { setUserId, setPage } = useUser();
  const queryParams = new URLSearchParams(location.search);
  const utmSource =  queryParams.get('utm_source') || '';
  const utmMedium = queryParams.get('utm_medium') || '';
  const utmCampaign = queryParams.get('utm_campaign') || '';


  useEffect(() => {
    
    // Save to local storage
    localStorage.setItem('utm_source', utmSource);
    localStorage.setItem('utm_medium', utmMedium);
    localStorage.setItem('utm_campaign', utmCampaign);

    
    // Perform actions based on these values, like sending analytics data, etc.
  }, [location.search]);


  const isMobileDevice = useMediaQuery({ maxWidth: 768 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1223 });
  const navigate = useNavigate();
  const {
    mutateAsync: sendOTPMutate,
    isLoading: sendOTPLoading,
    error: sendError,
  } = useMutation(sendOTP);

  const sendOTPError = useMemo(() => {
    return sendError as ErrorData;
  }, [sendError]); 


  const {
    mutate: verifyOTPMutate,
    //isLoading: verifyOTPLoading,
    error: verifyError,
  } = useMutation(verifyOTP, {
    onSuccess: (data) => {
      console.log("Sending the user to Questions")
      const userData = data.data as ResponseData<SignupData>;
      setPage(SignupSteps.QUESTIONS);
      setUserId(userData.data.userId);
    },
  });

  const onSubmitEmail = useCallback(
    async (value: string) => {
      try {
        console.log("OTP send is called")

        const result = await sendOTPMutate(value.toLowerCase());

        verifyOTPMutate({
          otp: "2345",
          verification_id: result.data.verification_id,
        });
       /*  // redirect to SignUp Page
        navigate(RouterPath.Signup, {
          state: {
            verification_id: result.data.verification_id,
            email: value,
          },
        }); */
        console.log("After navigating");
        //
      } catch (e) {
        // TODO
      }
    },
    [sendOTPMutate, navigate]
  );

  return (
    <>
      {isMobileDevice && (
        <Mobile onSubmitEmail={onSubmitEmail} sendOTPError={sendOTPError} />
      )}
      {isTablet && (
        <TabletMobile
          onSubmitEmail={onSubmitEmail}
          sendOTPError={sendOTPError}
        />
      )}
      {isDesktopOrLaptop && (
        <Laptop onSubmitEmail={onSubmitEmail} sendOTPError={sendOTPError} />
      )}

      <Loading isLoading={sendOTPLoading} />
    </>
  );
};
export default Home;
