import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserLayout from "../../layouts/User/user.layout";
import { QuestionsList, SignupSteps } from "../../constants/signup-steps";
import { Question, QuestionTypes } from "../../types/signup";
import useUser from "../../context/UserContext";
import { updateUser } from "../../service/user";
import { useMutation } from "react-query";
import {
  createBasicDetailsBody,
  createRequestBody,
} from "../../utils/UpdateUser.service";
import { getInterests } from "../../service/interests";
import { InterestTypes } from "../../types/signup/interests";
import { getSlots } from "../../service/slots";
import { Slots } from "../../types/signup/slots";
import { Route, useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import { UserData } from "../../types/signup/User";
import { UserProfile } from "../../types/signup/profile";
import {uploadAboutMeAudio} from "../../service/user"
import { pushAnalyticsEvent } from "../../service/analytics";
import { EVENT_ACTION, EVENT_TYPE } from "../../types/event";
import { Photo } from "../../types/signup/User";
import { Sign } from "crypto";

const steps = [
  SignupSteps.BASIC_DETAILS,
  SignupSteps.FIRST_LAST_NAME,
  SignupSteps.PHONE_NUMBER,
  SignupSteps.DATE_OF_BIRTH,
  SignupSteps.CITY_AREA,
  SignupSteps.PHOTOS,
  SignupSteps.QUESTIONS,
  SignupSteps.INTERESTS,
  SignupSteps.TNC,
  SignupSteps.HOW_IT_WORKS,
  SignupSteps.REFER,
  SignupSteps.SLOTS,
  SignupSteps.MICROPHONE_ACCESS,
  SignupSteps.ABOUT_ME,
  SignupSteps.NOTIFICATION_ACCESS
];

const User = () => {
  const [currentStep, setCurrentStep] = useState<SignupSteps>(
    SignupSteps.FIRST_LAST_NAME
  );
  const [questions, setQuestions] = useState<Question[]>(QuestionsList);
  const [step, setStep] = useState<number>(0);

  const { userData, userId, setUserData, userLoading } = useUser();

  const [interestTypes, setInterestTypes] = useState<InterestTypes[]>([]);
  const [slots, setSlots] = useState<Slots>();
  const [imageUploading, setImagesUploading] = useState<boolean>(false);
  const navigate = useNavigate();

  const getAdditionalData = useCallback(async () => {
    try {
      if (userData?.data?.next_page.page === SignupSteps.INTERESTS) {
        const interestResult = await getInterests();
        setInterestTypes(interestResult.data);
      }

      if (userData?.data?.next_page.page === SignupSteps.SLOTS) {
        const slotsResult = await getSlots();
        setSlots(slotsResult.data);
      }
    } catch (e) {}
  }, [userData]);

  useEffect(() => {
    getAdditionalData();
  }, [getAdditionalData]);

  const {
    data: updateUserData,
    mutateAsync: mutateUpdateUser,
    isLoading: updateUserLoading,
  } = useMutation(updateUser);

  useEffect(() => {
    if (updateUserData) setUserData(updateUserData);
  }, [updateUserData, setUserData]);

  useEffect(() => {
   if (userData) {
      const que = QuestionsList.map((item) => {
        if (userData.data[item.field as keyof UserData])
          if (item.type === QuestionTypes.RADIO) {
            item.value = userData.data[item.field as keyof UserData].toString();
          } else if (item.type === QuestionTypes.SLIDER) {
            item.value = userData.data[item.field as keyof UserData].toString();
          } else {
            const value = userData.data[
              item.field as keyof UserData
            ] as string[];
            item.value = value.join(",");
          }
        return item;
      });
      setQuestions(que);
    }
  }, [userData]);

  useEffect(() => {
    //check for a key in the storage. 
    //if the key exists. it means that redirect url has been hit. 
    //if not, then do the regular job. 
   

    const pgdetails = localStorage.getItem("pgdetails");
    if (pgdetails){
      const orderId = localStorage.getItem("pgorderId");
      navigate(RouterPath.Payment);
    } else {
      if (userData) {
          
            if (userData.data.next_page.page === "HOME") {
              //navigate(RouterPath.Dashboard);
              navigate(RouterPath.Welcome)
            } else if (steps.includes(userData.data.next_page.page as SignupSteps)) {
              console.log("setting" + userData.data.next_page.page + " as current step");
              setCurrentStep(userData.data.next_page.page as SignupSteps);
            } else {
              const stepIndex = QuestionsList.findIndex(
                (data) => data.page === userData.data.next_page.page
              );
              if (stepIndex !== -1) {
                setCurrentStep(SignupSteps.QUESTIONS);
                setStep(stepIndex);
              }
            }
          }
    }
   }, [userData, navigate]);

  const currentQuestion = useMemo(() => questions[step], [questions, step]);

  const onPressNext = useCallback(async () => {
    try {
      console.log("onPressNext is called");
      console.log("currentStep is " + currentStep);
      if (userId) {
        if (currentStep === SignupSteps.QUESTIONS) {
          const request = createRequestBody(currentQuestion);
          await mutateUpdateUser({
            data: request,
            page: currentQuestion.page,
            user: userId,
          });
          if (step < questions.length - 1) {
            setStep(step + 1);
          }
        }
        if (currentStep === SignupSteps.TNC) {
          await mutateUpdateUser({
            data: {
              tnc_accepted: true,
            },
            page: currentStep,
            user: userId,
          });
          setCurrentStep(SignupSteps.NOTIFICATION_ACCESS);
        }
        if (currentStep === SignupSteps.NOTIFICATION_ACCESS) {
          await mutateUpdateUser({
            data: {
              notifications_access_given: true,
            },
            page: currentStep,
            user: userId,
          });
        }

        if (currentStep === SignupSteps.PHOTOS) {
          setCurrentStep(SignupSteps.PREFERRED_VOCAL_LANGUAGE);
        }
        if (currentStep === SignupSteps.PREFERRED_VOCAL_LANGUAGE) {
          setCurrentStep(SignupSteps.NOTIFICATION_ACCESS);
        }
        if (currentStep === SignupSteps.MICROPHONE_ACCESS) {
          await mutateUpdateUser({
            data: {
              microphone_permission_given: true,
            },
            page: currentStep,
            user: userId,
          });
          setCurrentStep(SignupSteps.ABOUT_ME);
        }
        
      }

      /*     if (userId) {
        if (currentStep === SignupSteps.BASIC_DETAILS) {
          setCurrentStep(SignupSteps.QUESTIONS);
        }
        if (currentStep === SignupSteps.PHOTOS) {
          setCurrentStep(SignupSteps.QUESTIONS);
        }
        if (currentStep === SignupSteps.QUESTIONS) {
          const request = createRequestBody(currentQuestion);
          await mutateUpdateUser({
            data: request,
            page: currentQuestion.page,
            user: userId,
          });
          if (step < questions.length - 1) {
            setStep(step + 1);
          } else {
            setCurrentStep(SignupSteps.INTERESTS);
          }
        }
        if (currentStep === SignupSteps.INTERESTS) {
          setCurrentStep(SignupSteps.TNC);
        }
        if (currentStep === SignupSteps.TNC) {
          await mutateUpdateUser({
            data: {
              tnc_accepted: true,
            },
            page: currentStep,
            user: userId,
          });
          setCurrentStep(SignupSteps.HOW_IT_WORKS);
        }
        if (currentStep === SignupSteps.HOW_IT_WORKS) {
          setCurrentStep(SignupSteps.SLOTS);
        }
      } */
    } catch (e) {}
  }, [currentStep, step, questions, currentQuestion, userId, mutateUpdateUser]);

  const onSubmitInterests = useCallback(
    async (interests: number[]) => {
      if (userId) {
        try {
          await mutateUpdateUser({
            data: {
              interest_ids: interests,
            },
            page: currentStep,
            user: userId,
          });
          onPressNext();
        } catch (e) {}
      }
    },
    [userId, currentStep, mutateUpdateUser, onPressNext]
  );
  const onSubmitUserProfile = useCallback(
    async (profile: Partial<UserProfile>) => {
      if (userId) {
        console.log("Inside onSubmitUserProfile" + profile);
        const basicDetails = createBasicDetailsBody(profile);
        console.log("Inside onSubmitUserProfile basicDetails" + basicDetails);
        try {
          await mutateUpdateUser({
            data: {
              basic_details: {
                ...basicDetails,
              },
            },
            page: currentStep,
            user: userId,
          });
          onPressNext();
        } catch (e) {
          console.log("error while submitting user profile " + e);
        }
      }
    },
    [userId, currentStep, onPressNext, mutateUpdateUser]
  );

  const onChangeValue = useCallback(
    (value: string) => {
      if (currentStep === SignupSteps.QUESTIONS) {
        if (step < questions.length) {
          const data = [...questions];
          data[step].value = value;
          setQuestions(data);
        }
      }
    },
    [currentStep, step, questions]
  );

  const onSubmitPhotos = useCallback(
    async (uploadedUrls: string[]) => {// we will recieve the urls, uploaded to s3 + saved in db
      try {
        const photos: Photo[] = [];
        if (userId && uploadedUrls.length !== 0) {
          setImagesUploading(true);
          uploadedUrls.forEach((item, index) => {
            photos.push({
              url: item,
              display_order: index + 1, // this can be index + 1
            });
          });

          setImagesUploading(false);
          console.log("Just before sending the photos for upload" + photos);
          await mutateUpdateUser({
            data: {
              photos: photos,
            },
            page: currentStep,
            user: userId,
          });
        }
        onPressNext();
      } catch (e) {
        setImagesUploading(false);
      }
    },
    [userId, currentStep, onPressNext, mutateUpdateUser, userData]
  );

  const onSubmitSlots = useCallback(
    async (slots: number[]) => {
      if (userId) {
        try {
          await mutateUpdateUser({
            data: {
              slot_ids: slots,
            },
            page: currentStep,
            user: userId,
          });
          onPressNext();
        } catch (e) {}
      }
    },
    [userId, currentStep, onPressNext, mutateUpdateUser]
  );
  const onSubmitAboutMe = useCallback(
    async(file: Blob) =>{
      console.log('currentStep is ' + currentStep)
      console.log("This is audidoFile" + file);
      const result = await uploadAboutMeAudio({
        user: userId!,
        file: file
      });
      var audioUrl = result.data.url
      try {
        await mutateUpdateUser({
          data: {
            about_me_audio_url: audioUrl,
          },
          page: currentStep,
          user: userId!,
        });
        onPressNext();
      } catch (e) {}


    },
    [userId, currentStep, onPressNext, mutateUpdateUser]
  );


  const showLoading = useMemo(() => {
    return userLoading || updateUserLoading || imageUploading;
  }, [userLoading, updateUserLoading, imageUploading]);

  const onClickBack = useCallback(() => {
    if (currentStep === SignupSteps.PHOTOS) {
      setCurrentStep(SignupSteps.BASIC_DETAILS);
    }
    if (currentStep === SignupSteps.QUESTIONS) {
      if (step > 0) {
        setStep(step - 1);
      } else {
        setCurrentStep(SignupSteps.PHOTOS);
      }
    }
    if (currentStep === SignupSteps.INTERESTS) {
      setCurrentStep(SignupSteps.QUESTIONS);
      setStep(questions.length - 1);
    }
    if (currentStep === SignupSteps.TNC) {
      setCurrentStep(SignupSteps.INTERESTS);
    }
    if (currentStep === SignupSteps.HOW_IT_WORKS) {
      setCurrentStep(SignupSteps.TNC);
    }
    if (currentStep === SignupSteps.SLOTS) {
      setCurrentStep(SignupSteps.HOW_IT_WORKS);
    }
  }, [currentStep, step, questions]);

  const onClickSkip = useCallback(async () => {
    if (userId) {
      try {
        pushAnalyticsEvent(userId,EVENT_TYPE.ACTION,EVENT_ACTION.SKIP_PAGE_ABOUT_ME,"", undefined, undefined, undefined, {});
        await mutateUpdateUser({
          data: {},
          page:
            currentStep === SignupSteps.QUESTIONS
              ? currentQuestion.page
              : currentStep,
          user: userId,
          skipped: true,
        });
      } catch (e) {}
    }
  }, [currentStep, currentQuestion, userId, mutateUpdateUser]);

  return (
    <UserLayout
      currentStep={currentStep}
      onPressNext={onPressNext}
      currentQuestion={currentQuestion}
      onChangeValue={onChangeValue}
      interestTypes={interestTypes}
      onSubmitInterests={onSubmitInterests}
      onSubmitUserProfile={onSubmitUserProfile}
      onSubmitPhotos={onSubmitPhotos}
      showLoading={showLoading}
      slots={slots}
      onSubmitSlots={onSubmitSlots}
      onClickBack={onClickBack}
      onClickSkip={onClickSkip}
      onSubmitAboutMe={onSubmitAboutMe}
    />
  );
};
export default User;
