// Post.js
import {
  Box,
  Link,
  Typography,
  useTheme,
  Grid,
  Card,
  Button,
  Icon,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/logo";
import { Border } from "react-bootstrap-icons";
import HttpService from "../../http-client/services";
import PressureCooker from "../../assets/images/pressureCooker";
import { fetchInviteCount, updateInviteCount } from "../../service/blog";
import CoupleImage from "../../assets/images/couple";
import { getOrderStatus } from "../../service/order";
import { useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import Icons from "../../assets/icons";
import heic2any from "heic2any";
import {
  uploadPaymentProof,
  updateManualPayment,
  deleteAccount,
} from "../../service/user";
import { getUserSelectedPlanDetails } from "../../service/order";
import useUser from "../../context/UserContext";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteAccountLayout = () => {
  const theme = useTheme();
  const { setUserId, userId, setUserData, userData} = useUser();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    const userId = localStorage.getItem("USER_ID");
    const result = await deleteAccount(userId!);
    if (result == "OK") {
      // Get all cookies
      const cookies = document.cookie.split(";");

      // Iterate over each cookie and delete it
      cookies.forEach((cookie) => {
        const cookieParts = cookie.split("=");
        const cookieName = cookieParts[0].trim();
        console.log("deleting cookie" + cookieName);
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });

      setUserId(null)
      setUserData(null)
      
      navigate(RouterPath.Home)

      
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
      <Header showHamburgerMenu={true} />
      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          margin: "16px",
          paddingBottom: "200px !important",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          marginTop={"16px"}
          paddingLeft={"16px"}
          alignContent={"center"}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            <Box>
              <Grid
                container
                spacing={2}
                marginTop={"16px"}
                paddingLeft={"16px"}
                alignItems={"center"}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingTop: "0px",
                  }}
                >
                  <Typography
                    color={"#000"}
                    fontSize={22}
                    style={{ fontWeight: "600" }}
                  >
                    Are you sure you want to delete your account?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    marginTop: "20px",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"20px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid item xs={12} style={{ paddingTop: "8px" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleDeleteAccount}
                        // disabled={!ischecked}
                      >
                        Yes, delete my account.
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: "8px", marginTop: "10px" }}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {}}
                        // disabled={!ischecked}
                      >
                        Cancel
                      </Button>
                    </Grid>{" "}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default DeleteAccountLayout;
