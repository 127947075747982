import React, { useCallback, useEffect, useMemo } from "react";
import SignupLayout from "../../layouts/Signup/signup.layout";
import { SignupSteps } from "../../constants/signup-steps";
import useUser from "../../context/UserContext";
import { useMutation } from "react-query";
import { resendOTP, verifyOTP } from "../../service/signup";
import { ErrorData, ResponseData, SignupData } from "../../types/signup/User";
import { useLocation, useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";

const Signup = () => {

/* Commented this file as OTP is no longer needed
  
/*   const { setUserId, setPage } = useUser();

  const location = useLocation();

  const { email, verification_id: verifyId } = useMemo(() => {
    return {
      email: location.state?.email ?? "",
      verification_id: location.state?.verification_id ?? "",
    };
  }, [location.state]);
  const navigate = useNavigate();

  const {
    mutate: verifyOTPMutate,
    isLoading: verifyOTPLoading,
    error: verifyError,
  } = useMutation(verifyOTP, {
    onSuccess: (data) => {
      const userData = data.data as ResponseData<SignupData>;
      setPage(SignupSteps.QUESTIONS);
      setUserId(userData.data.userId);
    },
  });
  const {
    mutate: resendOTPMutate,
    isLoading: resendOTPLoading,
    data: resendData,
  } = useMutation(resendOTP);

  const verification_id = useMemo(()=>{
    if(resendData) {
      return resendData?.data?.verification_id
    }
    return verifyId;
  },[resendData,verifyId])

  useEffect(() => {
    if (!email || !verification_id) {
      navigate(RouterPath.Home);
    }
  }, [email, verification_id, navigate]);


  const onSubmitOTP = useCallback(
    (value: string) => {
      verifyOTPMutate({
        otp: value,
        verification_id: verification_id,
      });
    },
    [verifyOTPMutate, verification_id]
  );

  const onResendOTP = useCallback(() => {
    resendOTPMutate(verification_id)
  }, [resendOTPMutate,verification_id]);

  const verifyOTPError = useMemo(() => {
    return verifyError as ErrorData;
  }, [verifyError]); */

  return (
    <></>
  /*   <SignupLayout
      resendOTP={onResendOTP}
      email={email}
      verifyOTPError={verifyOTPError}
      onSubmitOTP={onSubmitOTP}
      isLoading={verifyOTPLoading || resendOTPLoading}
    /> */
  );
};
export default Signup;
