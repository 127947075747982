import { Activity } from "react-bootstrap-icons";
import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { UserDate, ResponseData, ErrorData } from "../types/date";

export const getUserDate = async (user: string) => {
  try {
    console.log("getUserDate called");
    const data = await getAxiosClient(false).get(HttpService.USER_DATE + user);
    const dates = [];
    if (data.data["new_dates"] != null) {
      dates.push(data.data["new_dates"]);
    }

    if (data.data["active_dates"] != null) {
      for (let i = 0; i < data.data["active_dates"].length; i++) {
        dates.push(data.data["active_dates"][i]);
      }
    }

    dates.forEach(function (element) {
      console.log("---");
      console.log(element);
    });

    return dates;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const updateDateState = async (
  date_id: string,
  user_id: string,
  status: string,
  reason: string
) => {
  const date_status = {
    date_id: date_id,
    userId: user_id,
    date_status: status,
    decline_reason: reason,
  };
  try {
    const data = await getAxiosClient(false).post(
      HttpService.USER_DATE + "status",
      {
        ...date_status,
      }
    );
    console.log("After updating the date" + data.data);
    return data.data as UserDate;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const createNotification = async (user_id: string) => {
  const post_data = {
    userId: user_id,
  };
  try {
    console.log("userId is" + user_id);
    const data = await getAxiosClient(false).post(
      HttpService.SEND_NOTIFICATION,
      {
        ...post_data,
      }
    );
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};
