export enum QuestionTypes {
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  SLIDER = "SLIDER",
  TEXTBOX = "TEXTBOX",
  DROPDOWN = "DROPDOWN",
}

export interface OptionItem {
  value: string;
  text: string;
}

export interface Question {
  question: string;
  options: OptionItem[];
  type: QuestionTypes;
  value: string;
  field: string;
  subtitle?: string;
  page: string;
  metadata?: string;
  error: string;
  eventkey: string;
}
