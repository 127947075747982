import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { getUser } from "../service/user";
import { ResponseData, UserData } from "../types/signup/User";

interface UserContextType {
  userId?: string;
  setUserId: Dispatch<string | null>;
  page?: string;
  setPage: Dispatch<string>;
  userData?: ResponseData<UserData >;
  userLoading: boolean;
  setUserData: Dispatch<ResponseData<UserData> | null>;
  accountDeleted: boolean;
  setAccountDeleted: Dispatch<boolean>;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

interface UserContextProviderType {
  children: ReactNode;
}

const UserContextProvider = ({ children }: UserContextProviderType) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [page, setPage] = useState<string>();
  const [userData, setUserData] = useState<ResponseData<UserData | null>>();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [accountDeleted, setAccountDeleted] = useState<boolean>(false);

  const getUserData = useCallback(async () => {
    try {
      if (userId) {
        setUserLoading(true);
        const result = await getUser(userId);
        setUserLoading(false);
        setUserData(result);
      }
    } catch (_) {
      // TODO
      setUserLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, [userId, getUserData]);

  useEffect(() => {
    const data = localStorage.getItem("USER_ID");
    if (data && data !== "undefined") {
      setUserId(data);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("USER_ID", userId);
    }
  }, [userId]);

  const values = useMemo(() => {
    return {
      userId,
      setUserId,
      page,
      setPage,
      userData,
      userLoading,
      setUserData,
      accountDeleted,
      setAccountDeleted
    } as UserContextType;
  }, [userId, userData, page, userLoading, accountDeleted]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export { UserContextProvider };
export default useUser;
