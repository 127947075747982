import {
    Box,
    Card,
    FormHelperText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React, { useCallback, useState, useEffect } from "react";
  import SlotItem from "../SlotLayout/SlotItem";
  import { Slots } from "../../../types/signup/slots";
  import useUser from "../../../context/UserContext";
  import TitleComponent from "../../../components/TitleComponent";
  import NextButton from "../../../components/NextButton";
  
  interface SlotsLayoutProps {
    onPressNext: (selectedSlots: number[]) => void;
    slots?: Slots;
  }
  const MIN_SLOTS = 5;
  
  const ReferralLayout = ({ onPressNext, slots }: SlotsLayoutProps) => {
    const { userData } = useUser();
    const [selectedSlots, setSelectedSlots] = useState<number[]>([]);
    const [showError, setShowError] = useState<boolean>(false);
    const referralCode = userData?.data?.basic_details?.referral_code as string;
    const newcode = 'asfads';
    useEffect(() => {
      if (userData && userData?.data?.slot_ids) {
        setSelectedSlots(userData?.data?.slot_ids);
      }
    }, [userData]);
  
    const onSelect = useCallback(
      (value: number) => {
        const values = [...selectedSlots];
        if (values.includes(value)) {
          const newValues = values.filter((item) => item !== value);
          setSelectedSlots(newValues);
        } else {
          const newValues = [...values];
          newValues.push(value);
          setSelectedSlots(newValues);
        }
      },
      [selectedSlots]
    );
  
    const onSubmit = useCallback(() => {
      if (selectedSlots.length < MIN_SLOTS) {
        setShowError(true);
      } else {
        setShowError(false);
        onPressNext(selectedSlots);
      }
    }, [selectedSlots, onPressNext]);
  
    return (
      <>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={{ xs: "flex-start", md: "center" }}
          width={{ xs: "100%", md: "450px" }}
          marginTop={"16px"}
        >
          <TitleComponent title="Get your first date free!" subtitle="Refer your friends. You and your friend get one free date each when they join!" />
        
          <Card
          sx={{
            marginTop:  "32px",
            padding: "16px 16px",
            background: "#FFFFFF",
          }}>

          
          I invite you to join Twist. Twist sets coffee dates with your best match. Use my code {referralCode.toUpperCase()} to get your first free date. Join now 
https://thetwistapp.com/
        </Card>
          <a href="#" onClick={() => {navigator.clipboard.writeText("I invite you to join Twist. Twist sets coffee dates with your best match. Use my code " + referralCode.toUpperCase() + " to get your first free date. Join now https://thetwistapp.com")}}>COPY INVITE</a>
        </Box>
  
        <NextButton onPressNext={onSubmit} />
      </>
    );
  };
  export default ReferralLayout;
  