// Post.js
import {
  Box,
  Link,
  Typography,
  useTheme,
  Grid,
  Card,
  Button,
  Icon,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/logo";
import { Border } from "react-bootstrap-icons";
import HttpService from "../../http-client/services";
import PressureCooker from "../../assets/images/pressureCooker";
import { fetchInviteCount, updateInviteCount } from "../../service/blog";
import CoupleImage from "../../assets/images/couple";
import { getOrderStatus } from "../../service/order";
import { useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import Icons from "../../assets/icons";
import heic2any from "heic2any";
import { uploadPaymentProof, updateManualPayment } from "../../service/user";
import { getUserSelectedPlanDetails } from "../../service/order";
import useUser from "../../context/UserContext";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WelcomeLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
      <Header  showHamburgerMenu={false} />
      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          margin: "16px",
          paddingBottom: "200px !important",
          textAlign:"center"
        }}
      >
         <div style={{fontSize:"1.4em", fontWeight:"700", marginTop:"10px"}}>Welcome to Twist</div>

        <Grid
          container
          spacing={2}
          marginTop={"16px"}
          paddingLeft={"16px"}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
           
            <div>
              <ul style={{"listStyleType":"none", "fontSize":"1.1 em", "lineHeight":"1.3em"}}>
                <li style={{"marginBottom":"20px"}} >Twist is different from other Dating apps.</li>
                <li style={{"marginBottom":"20px"}}>We will find only one match per day, carefully curated to be the best fit for you. </li>
                <li style={{"marginBottom":"20px"}}>We will send an invitation for a coffee date, which both you and your match must accept for it to occur.</li>
                <li style={{"marginBottom":"20px"}}>Utilize our advanced compatibility details before accepting a date invitation.</li>
                <li style={{"marginBottom":"20px"}}>Happy Dating.</li>
              </ul>
            </div>

            <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                   navigate(RouterPath.Dashboard)
                  }}
                  // disabled={!ischecked}
                >
                  I understand
                </Button>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default WelcomeLayout;
