import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          border: "1px solid  #AAAAAA",
          //boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: 8,
          color: "#000",
          "& label": {
            color: "#CCCCCC",
          },
          "& fieldset": { border: "none" },
          padding: 0,
          "& input": {
            padding: "0px 12px",
            height: "3em",
            fontSize: "16px",       
            "&:-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 100px white inset",
              background: "#FFFFFF",
              "-webkit-text-fill-color": "#000",
              border:"none",
              borderRadius: 8
            },
          },
          "& input:disabled": { WebkitTextFillColor: "#000000", color: "#000" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          background: "#FF5A5F",
          borderRadius: 8,
          height: "3em",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          color: "#FFF",
          fontWeight: "700",
        },
        outlined: {
          background: "#E0E0E0",
          borderRadius: 8,
          height: "3em",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          color: "#000",
          fontWeight: "700",
        },
      },
    },
   
    MuiSelect: {
      styleOverrides: {
        select: {
          background: "#FFFFFF",
          borderRadius: 8,
          //boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          "& label": {
            color: "#000",
          },
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          color: "#FF5A5F",
        },
        color: "error",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          color: "#FF5A5F",
        },
        color: "error",
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          fontFamily: "Raleway",
          fontWeight: "600",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#F5F5F5",
    },
    secondary: {
      main: "#444444",
    },
    background: {
      default: "#E0E0E0",
    },
    text: {
      primary: "#444444",
    },
  },
});
export default theme;
