import { Box, Link, Typography, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HowItWorksLayout from "../../layouts/User/HowItWorksLayout";
import NoDatesLayout from "../../layouts/Dashboard/NoDatesLayout";
import useUser from "../../context/UserContext";
import FirstUserDateLayout from "../../layouts/Dashboard/FirstUserDateLayout";
import { getUserDate, updateDateState } from "../../service/date";
import { updateLastActiveTime } from "../../service/user";
import { UserDate } from "../../types/date";
import { boolean, date } from "yup";
import DateConfirmationLayout from "../../layouts/Dashboard/DateConfirmationLayout";
import DateDeclinedLayout from "../../layouts/Dashboard/DateDeclinedLayout";
import DateAcceptedLayout from "../../layouts/Dashboard/DateAcceptedLayout";
import Loading from "../../components/Loading";
import TermsLayout from "../../layouts/Dashboard/TermsLayout";
import VerifyAccountLayout from "../../layouts/Dashboard/VerifyAccountLayout";
import { updateUser, checkMembership, getUserPlans } from "../../service/user";
import { createPGOrder, getOrderStatus } from "../../service/order";
import Icons from "../../assets/icons";
import { MembershipPlans } from "../../types/plan";
import MembershipPlansLayout from "../../layouts/Dashboard/MembershipPlansLayout";
import { cashfree } from "../../utils/cashfree";
import { useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import { pushAnalyticsEvent } from "../../service/analytics";
import { EVENT_TYPE } from "../../types/event";
import { EVENT_ACTION } from "../../types/event";

const Dashboard = () => {
  const theme = useTheme();
  const { userData,setUserData } = useUser();
  const [userdates, setUserDates] = useState<UserDate[]>(); // State to store the user dates
  const [userdate, setUserNewDate] = useState<UserDate>(); // State to store the user dates
  const [dateExists, setDateExists] = useState(Boolean);
  const [userActionTaken, setUserActionTaken] = useState(Boolean);
  const [userAction, setUserAction] = useState(String);
  const [userDateAccepted, setUserDateAccepted] = useState(Boolean);
  const [userDateRejected, setUserDateRejected] = useState(Boolean);
  const [userDateUpdated, setUserDateUpdated] = useState(Boolean);
  const [cancelButtonPressed, setCancelButtonPressed] = useState(Boolean);
  const [showLoading, setShowLoading] = useState(Boolean);
  const [showTerms, setShowTerms] = useState(Boolean);
  const [userVerified, setUserVerified] = useState(Boolean);
  const [userVerificationStatus, setShowUserVerificationStatus] =
    useState(String);
  const [showHowItWorks, setshowHowItWorks] = useState<boolean>(false);
  const [showVerifiedSection, setShowVerifiedSection] =
    useState<boolean>(false);
  const [currentUserDate, setCurrentUserDate] = useState<UserDate>();
  const [is_member, setPaidMember] = useState(Boolean);
  const [userPlans, setUserPlans] = useState<MembershipPlans>();
  const navigate = useNavigate();
  const [bannerText, setBannerText] = useState(String);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  // const useEffect = () => {
  //   setShowUserVerificationStatus
  // }

  const updateDateStatus = async (status: string, reason: string) => {
    const date_id = currentUserDate!["date_id"];
    const user_id = localStorage.getItem("USER_ID")!;
    if (status == "decline") {
      setUserDateRejected(true);
      console.log("reason is " + reason);
      const result = await updateDateState(date_id!, user_id!, status, reason);
      setUserDateUpdated(true);
      userdates![0] = userdates![1];
    } else if (status == "confirm") {
      setUserDateAccepted(true);
      const result = await updateDateState(date_id!, user_id!, status, reason);
      const new_date = result as UserDate;
      userdates![0] = new_date;
      setUserDateUpdated(true);
    } else if (status == "cancel") {
      //dont do anything here..
      setCancelButtonPressed(true);
    }
  };

  const receiveUserAction = (status: string) => {
    setUserActionTaken(true);
    setUserAction(status);
  };

  const receiveUserActionOnDate = (userdate: UserDate, status: string) => {
    setCurrentUserDate(userdate);
    console.log("current user date is " + userdate);
    setUserActionTaken(true);
    setUserAction(status);
  };

  const verifyUserDetails = async (
    // linkedInUrl: string,
    // instagramURL: string,
    idProofUrl: String
  ) => {
    const user = localStorage.getItem("USER_ID")!;

    await updateUser({
      user,
      data: {
        verify_account: {
          // instagram_handle: linkedInUrl,
          // linkedin_username: instagramURL,
          id_proof_url: idProofUrl,
        },
      },
      page: "VERIFY_ACCOUNT",
      skipped: false,
    }).then((data) => {
      //setShowUserVerificationStatus("PENDING");
      setUserData(data);
      BoxContents();
    });
  };

  const showterms = () => {
    console.log("Terms was clicked");
    setShowTerms(true);
  };
  const termsBackButtonClick = () => {
    setShowTerms(false);
  };

  const handleVerificationLinkClick = () => {
    console.log("handleVerificationLinkClick called");
  };

  const checkUserMembership = async (user_id: string) => {
    console.log("checking membership" + user_id);
    var is_member = await checkMembership(user_id);
    console.log("is_member" + is_member);
    if (is_member === "True") {
      console.log("User is a member");
      setPaidMember(true);
    } else {
      console.log("User is not a member");
      setPaidMember(false);
      console.log("trying to get the plans for users");
      const user_plans = await getUserPlans(user_id);
      console.log("User plans:");
      console.log("NO_MEMBERSHIP:", user_plans);
      if (user_plans.NO_MEMBERSHIP == "true") {
        is_member = "true";
        setPaidMember(true);
      } else {
        setUserPlans(user_plans);
      }
    }
  };

  const getDateDetails = () => {
    console.log("getDateDetails called");
    //getUserDate("90a25ecc-b42f-11ee-9d24-0242ac110002")
    console.log("USER_ID" + localStorage.getItem("USER_ID"));
    getUserDate(localStorage.getItem("USER_ID")!)
      .then((data) => {
        console.log("data,,");
        console.log("data" + data);
        setUserDates(data as UserDate[]);
        setShowLoading(true);
        if (data[0]?.date_id != null) {
          setDateExists(true);
          setShowLoading(false);
        } else {
          setDateExists(false);
          setShowLoading(false);
        }
        // Set the user dates in state
      })
      .catch((error) => {
        //setShowLoading(false);
        setDateExists(false);
        setShowLoading(false);
        console.error("Error fetching user dates:", error);
      });
  };
  useEffect(() => {
    //getUserDate("04bdd282-7fb5-11ee-ad25-0242ac110002")
    getDateDetails();
    updateLastActiveTime(localStorage.getItem("USER_ID")!);
    setShowUserVerificationStatus(userData?.data?.verification_status!);

    const user_id = localStorage.getItem("USER_ID")!;
    checkUserMembership(user_id);
    if (userData?.data?.state == "VERIFICATION_PENDING") {
      setShowBanner(true);
    }
  }, []); // The empty array means this will only run once, on mount

  const createOrder = async (plan_id: number) => {
    console.log("createOrder called");
    const user_id = localStorage.getItem("USER_ID")!;

    pushAnalyticsEvent(
      user_id!,
      EVENT_TYPE.ACTION,
      EVENT_ACTION.CONSIDERED_PAYMENT_AFTER_CHECKING_DATE_INVITE,
      currentUserDate?.date_id!,
      undefined,
      undefined,
      undefined,
      {}
    );

    console.log("user_id" + user_id);
    console.log("plan_id" + plan_id);
    const result = await createPGOrder(user_id, plan_id.toString());
    console.log("result" + result);
    const order_id = result.order_id;
    const payment_session_id = result.payment_session_id;
    console.log("order_id" + order_id);
    console.log("session_id" + payment_session_id);
    localStorage.setItem("plan_id", plan_id.toString());
    localStorage.setItem("order_id", order_id);

    //while creating an order, send the way payment should be handled whether via UPI, payment gaytway or something else
    //by default it should take you to UPI.
    /*   const returnUrl =
      process.env.REACT_APP_PAYMENT_REDIRECT_BASEURL +
      "/payment?pgdetails=true&orderId=" +
      order_id;

    console.log("returnUrl" + returnUrl);
    let checkoutOptions = {
      paymentSessionId: payment_session_id,
      returnUrl: returnUrl,
    };
    cashfree.checkout(checkoutOptions).then(function (result: any) {
      if (result.error) {
      
      }
      if (result.redirect) {
        console.log("Redirection");
      }
    }); */

    navigate(RouterPath.UPIPayment);
  };

  const BoxContents = () => {
    console.log("Box contents was called");
    console.log("showTerms" + showTerms);
    console.log("userDateUpdated" + userDateUpdated);
    console.log("dateExists" + dateExists);
    if (showTerms) {
      return (
        <TermsLayout
          fontSize={24}
          marginTop={"8px"}
          userdates={userdates![0]}
          onButtonClick={receiveUserAction}
          onBackButtonClick={termsBackButtonClick}
        />
      );
    }
    if (userDateUpdated) {
      if (userDateAccepted) {

        return (
          <FirstUserDateLayout
            fontSize={24}
            marginTop={"8px"}
            userdates={userdates}
            onButtonClick={receiveUserActionOnDate}
            onTermsButtonClick={showterms}
          />
        );
      } else {
        return <NoDatesLayout fontSize={24} marginTop={"8px"} />;
      }
    }
    if (cancelButtonPressed) {
      return (
        <FirstUserDateLayout
          fontSize={24}
          marginTop={"8px"}
          userdates={userdates}
          onButtonClick={receiveUserActionOnDate}
          onTermsButtonClick={showterms}
        />
      );
    } else if (dateExists) {
      console.log(userActionTaken);
      console.log(userAction);
      console.log(userVerificationStatus);
      if (userActionTaken && userAction == "confirm") {
        const user_id = localStorage.getItem("USER_ID")!;
        console.log("inside this..");
        /* 
        check if the user is a member or not. 
        get user's plan details
        once you have the plan details, move the user to a new page, plan details
      */
        console.log("is_member" + is_member);
        /*
          If the user state is ONBOARDING_COMPLETE or APPROVED or USER_UNVERIFIED, show the VerifyAccountLayout
          If the user state is ONBOARDING_COMPLETE and the verification status "VERIFIED",move the user to the top of the page where we 
          have the banner which will show Account is getting Approved
          If the user state is VERIFICATION_PENDING, move the user to the top of the page where we 
          have the banner which will show Account is getting verified
          If the user state is APPROVED, show the MembershipPlansLayout
          If the user is APPROVED_MEMBER, show the DateConfirmationLayout
        */

        if (
          userData?.data.state == "ONBOARDING_COMPLETE" ||
          userData?.data.state == "APPROVED" ||
          userData?.data.state == "USER_UNVERIFIED"
        ) {
          if (
            userData?.data.state == "ONBOARDING_COMPLETE" &&
            userData.data.verification_status == "VERIFIED"
          ) {
            setBannerText("Account is under review");
            setShowBanner(true);
          } else {
            return (
              <VerifyAccountLayout
                fontSize={24}
                marginTop={"8px"}
                userdates={userdates![0]}
                onButtonClick={verifyUserDetails}
              />
            );
          }
        } else if (userData?.data.state == "VERIFICATION_PENDING") {
          return (
            <FirstUserDateLayout
              fontSize={24}
              marginTop={"8px"}
              userdates={userdates}
              onButtonClick={receiveUserActionOnDate}
              onTermsButtonClick={showterms}
            />
          );
        } else if (userData?.data.state == "VERIFIED") {
          return (
            <MembershipPlansLayout
              fontSize={24}
              marginTop={"8px"}
              onButtonClick={createOrder}
              userPlans={userPlans!}
              onTermsButtonClick={showterms}
            />
          );
        } else if (userData?.data.state == "MEMBER") {
          return (
            <DateConfirmationLayout
              fontSize={24}
              marginTop={"8px"}
              userdates={currentUserDate}
              onButtonClick={updateDateStatus}
            />
          );
        }
        // if (is_member) {
        //   console.log("User is a member");
        // } else {
        //   console.log("User is not a member");
        //   console.log("window.location.hostname" + window.location.hostname);
        //   return (
        //     <MembershipPlansLayout
        //       fontSize={24}
        //       marginTop={"8px"}
        //       onButtonClick={createOrder}
        //       userPlans={userPlans!}
        //       onTermsButtonClick={showterms}
        //     />
        //   );
        // }

        // console.log("inside this..");
        // if (
        //   userVerificationStatus === "PENDING" ||
        //   userVerificationStatus === "VERIFIED"
        // ) {
        //   return (
        //     <DateConfirmationLayout
        //       fontSize={24}
        //       marginTop={"8px"}
        //       userdates={currentUserDate}
        //       onButtonClick={updateDateStatus}
        //     />
        //   );
        // }
        // if (userVerificationStatus === "" || userVerificationStatus === null) {
        //   console.log("Date Exists");
        //   return (
        //     <VerifyAccountLayout
        //       fontSize={24}
        //       marginTop={"8px"}
        //       userdates={userdates![0]}
        //       onButtonClick={verifyUserDetails}
        //     />
        //   );
        // }
      } else if (userActionTaken && userAction === "decline") {
        return (
          <DateDeclinedLayout
            fontSize={24}
            marginTop={"8px"}
            onButtonClick={updateDateStatus}
          />
        );
      } else if (dateExists && !userActionTaken) {
        return (
          <FirstUserDateLayout
            fontSize={24}
            marginTop={"8px"}
            userdates={userdates}
            onButtonClick={receiveUserActionOnDate}
            onTermsButtonClick={showterms}
          />
        );
      }
    } else if (!dateExists) {
      return <NoDatesLayout fontSize={24} marginTop={"8px"} />;
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
    >
      <Header showHamburgerMenu={true} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"0px 16px 16px 16px"}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent={{ xs: "flex-start", md: "center" }}
        height={"100%"}
      >
        {userData?.data?.state == "VERIFICATION_PENDING"  && <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={{ xs: "flex-start", md: "center" }}
          width={{ xs: "100%", md: "450px" }}
        >
          <Grid
            container
            spacing={2}
            paddingLeft={"4px"}
            paddingRight={"4px"}
            width={"100%"}
            paddingBottom={"8px"}
            marginLeft={"0px !important"}
            marginTop={"0px !important"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                background:"#FFA500"
              }}
            >
               <Typography
                  color={"#000"}
                  fontSize={12}
                  style={{ fontWeight: "600" }}
                  marginTop={"4px"}
                 >
                  Account is under verification
                </Typography>
            </Grid>
          </Grid>
        </Box> }

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={{ xs: "flex-start", md: "center" }}
          width={{ xs: "100%", md: "450px" }}
        >
          {BoxContents()}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={{ xs: "flex-start", md: "center" }}
          width={{ xs: "100%", md: "450px" }}
          marginTop={"8px"}
        >
          {/*TODO  We will change the HowIt works to a better worded HowIt Works. Since HowItWorks is no longer needed, existing component will be resused.  */}
          {showHowItWorks ? (
            <HowItWorksLayout fontSize={24} marginTop={"16px"} />
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Loading isLoading={showLoading} />
    </Box>
  );
};
export default Dashboard;
