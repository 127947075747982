import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getPincodeData } from "../../../service/pincode";
import { PostOffice } from "../../../types/signup/pincode";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import Loading from "../../../components/Loading";
import { getInviteCode } from "../../../service/invitecode";
import { Label } from "@mui/icons-material";
import { UserProfile } from "../../../types/signup/profile";
import NextButton from "../../../components/NextButton";

interface UserProfileLayoutProps {
  onPressNext: (value: Partial<UserProfile>) => void;
}

const MAX_YEAR = new Date().getFullYear() - 18;

const Schema = Yup.object({
  pincode: Yup.string().when("city", (val, schema) => {
    if (val?.length === 0 || val?.[0] === undefined) {
      return Yup.string()
        .min(6, "Please enter valid pincode")
        .required("Please enter your pincode");
    } else {
      return Yup.string().notRequired();
    }
  }),
  city: Yup.string().required("Please enter your city"),
  area: Yup.string().required("Please enter your area"),
});

const AreaLayout = ({ onPressNext }: UserProfileLayoutProps) => {
  const [areas, setAreas] = useState<PostOffice[]>([]);
  const { userData } = useUser();
  const [pincodeLoading, setPincodeLoading] = useState<boolean>(false);
  const [validCode, setValidCode] = useState<boolean>(true);
  const [validPinCode, setValidPinCode] = useState<boolean>(true);

  const onSubmit = useCallback(
    (values: Partial<UserProfile>) => {
      onPressNext(values);
    },
    [onPressNext]
  );

  const formik = useFormik({
    initialValues: {
      area: "",
      city: "",
      pincode: "",
    } as Partial<UserProfile>,
    validationSchema: Schema,
    onSubmit: onSubmit,
  });
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    setFieldTouched,
  } = formik;

  useEffect(() => {
    if (userData?.data?.basic_details) {
      setFieldValue("city", userData?.data?.basic_details?.city);
      setFieldValue("area", userData?.data?.basic_details?.area);
    }
  }, [userData, setFieldValue]);

  const setDateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("date", e.target.value);
    },
    [setFieldValue]
  );

  const setMonthValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("month", e.target.value);
    },
    [setFieldValue]
  );

  const setYearValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 4) setFieldValue("year", e.target.value);
    },
    [setFieldValue]
  );

  const setPincodeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 6) setFieldValue("pincode", e.target.value);

      setFieldValue("city", "");
      setFieldValue("area", "");
    },
    [setFieldValue]
  );

  const onChangeArea = useCallback(
    (e: SelectChangeEvent<string>) => {
      setFieldValue("area", e.target.value);
    },
    [setFieldValue]
  );

  const getAreaData = useCallback(async () => {
    if (values.pincode) {
      try {
        setValidPinCode(true);
        setFieldValue("city", "");
        setFieldValue("area", "");
        setPincodeLoading(true);
        const areaData = await getPincodeData(values.pincode);
        if (areaData.length == 0) {
          setValidPinCode(false);
        } else {
          setFieldValue(
            "city",
            areaData[0].PostOffice[0].District.replace("Region", "")
          );
          setAreas(areaData[0].PostOffice);
        }
        setPincodeLoading(false);
      } catch (e) {
        setPincodeLoading(false);
      }
    }
  }, [values.pincode, setFieldValue]);

  useEffect(() => {
    if (values.pincode && values.pincode.length === 6) {
      getAreaData();
    }
  }, [userData, values.pincode, getAreaData, setFieldValue]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="Enter your pincode."  subtitle="We will set up your coffee date with users who are near you!"/>

        {validPinCode ? null : (
          <span style={{ color: "red", fontWeight: "bold", marginTop: "16px" }}>
            We are not servicing your area yet.
          </span>
        )}
        <Grid container spacing={2} marginTop={"8px"}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Pincode"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 10 }}
              value={values.pincode}
              error={Boolean(touched.pincode && errors.pincode)}
              onChange={setPincodeValue}
            />
            {touched.pincode && errors.pincode && (
              <FormHelperText>{errors.pincode}</FormHelperText>
            )}
          </Grid>
          {values.city && (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="City"
                variant="outlined"
                type="text"
                fullWidth
                inputProps={{ maxLength: 10 }}
                value={values.city}
                error={Boolean(touched.city && errors.city)}
                onChange={handleChange("city")}
                disabled
              />
            </Grid>
          )}
          {areas.length > 0 ? (
            <Grid item xs={12}>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={values.area}
                onChange={onChangeArea}
              >
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      color: "#CCCCCC",
                    }}
                  >
                    Area
                  </Typography>
                </MenuItem>
                {areas.map((item) => {
                  return (
                    <MenuItem key={item.Name} value={item.Name}>
                      {item.Name}
                    </MenuItem>
                  );
                })}
              </Select>

              {touched.area && errors.area && (
                <FormHelperText>{errors.area}</FormHelperText>
              )}
            </Grid>
          ) : values.area ? (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="Area"
                variant="outlined"
                type="text"
                fullWidth
                inputProps={{ maxLength: 10 }}
                value={values.area}
                error={Boolean(touched.area && errors.area)}
                onChange={handleChange("area")}
                disabled
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <NextButton onPressNext={handleSubmit} />

        <Loading isLoading={pincodeLoading} />
      </Box>
    </>
  );
};
export default AreaLayout;
