import React from "react";
import { Box, Button } from "@mui/material";
import Logo from "../../assets/logo";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useUser from "../../context/UserContext";
import Icons from "../../assets/icons";
import { useState } from "react";
import "./HeaderMenu.css";
import RouterPath from "../../route/RouterPath";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  onPressBack?: () => void;
  onClickSkip?: () => void;
  showHamburgerMenu: boolean;
}

const Header = ({
  onPressBack,
  onClickSkip,
  showHamburgerMenu,
}: HeaderProps) => {
  const navigate = useNavigate();

  const { userData } = useUser();

  const sendMessage = () => {
    const phoneNumber = "+917483371460";
    const message = "Hello!"; // Replace this with your desired message

    // Construct the WhatsApp URL
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp in a new tab
    window.open(url, "_blank");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteAccount = () => {
    navigate(RouterPath.Delete);
  };

  const handleUpdateProfile = () => {
      navigate(RouterPath.UpdateProfile)
  };

  return (
    <Box
      sx={{
        padding: 0,
        backgroundColor: "transparent",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"12px 16px"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {onPressBack && <ArrowBackIosIcon onClick={onPressBack} />}
          <img
            src={Logo.TWIST_ICON}
            width={"80px"}
            height={"36px"}
            alt="logo"
          />
        </Box>

        {userData?.data?.next_page?.is_optional && (
          <Button
            variant="text"
            sx={{
              color: "#000",
              textDecorationLine: "underline",
              fontSize: 12,
            }}
            onClick={onClickSkip}
          >
            Skip
          </Button>
        )}
        {/* 
        {!userData?.data?.next_page?.is_optional && (
          <Box>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <span style={{ fontSize: "14px" }}>
                  Need Help? <br /> Talk to us
                </span>
              </div>

              <div style={{ display: "inline-block" }}>
                <img
                  src={Icons.WhatsApp}
                  width={"32px"}
                  height={"32px"}
                  style={{ marginTop: "12px" }}
                  onClick={sendMessage}
                />
              </div>
            </div>
          </Box>
        )} */}

        {showHamburgerMenu && (
          <div className="hamburger-menu">
            <div
              className={`menu-btn ${isOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <div className="menu-btn-burger">
                <img
                  src={Icons.Menu}
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
            </div>
            {isOpen && (
              <nav className="menu">
                <ul>
                  {(userData?.data!.state == "MEMBER" || userData?.data!.state == "APPROVED" || userData?.data!.state == "VERIFIED" ) && <li>
                    <a href="#"  onClick={sendMessage}>
                      <div style={{marginBottom:"0px"}}>
                        Need Help?{" "}
                        <img
                          src={Icons.WhatsApp}
                          width={"24px"}
                          height={"24px"}
                          style={{float:"right"}}
                        />{" "}
                      </div>
                    </a>
                  </li>}
                  <li>
                    <a href="#" onClick={handleDeleteAccount}>
                      Delete Account
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={handleUpdateProfile}>
                      Update Profile
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};
export default Header;
