import UpdateProfileLayout from "../../layouts/UpdateProfileLayout";
import React, { useEffect } from 'react';



const UpdateProfile = () => {
   
    return (
        <UpdateProfileLayout/>
    );
};

export default UpdateProfile;