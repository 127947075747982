import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
} from "@mui/material";
import React, { useState } from "react";
import Seperator from "../../../assets/images/seperator";
import { UserDate } from "../../../types/date";
import { updateDateState } from "../../../service/date";
import parse from "html-react-parser";
import Checkbox from "@mui/material/Checkbox";
import { number } from "yup";

interface TermsLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate;
  onButtonClick: (status: string) => void;
  onBackButtonClick: () => void;
}

const TermsLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick,
  onBackButtonClick,
}: TermsLayoutProps) => {
  const [openCompatibility, setOpenCompatibility] = useState(false);

  const [ischecked, setIsChecked] = useState(false);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const [openDateDetails, setOpenDateDetails] = useState(false);

  const handleDateDetailsToggle = () => {
    setOpenDateDetails(!openDateDetails);
  };

  const openGoogleMaps = (url: string) => {
    window.open(url, "_blank");
  };

  const terms = (
    <p>
      Before you begin using Twist , we want to ensure that you are aware of our
      terms and conditions. By accessing and using our services, you agree to be
      bound by the following terms and conditions and cancellation policy:
      <br />
      <br />
      <b>Cancellation, No Show, and Rescheduling Policy</b>
      <br />
      This policy outlines the terms and conditions applicable in the event of
      cancellation, rescheduling, or non-attendance (no show) for arranged dates
      through our dating service. These rules are designed to respect the time
      and commitment of all our users. <br />
      <br />
      1. <b>Cancellation Policy</b>
      - All dates arranged through our service are final and non-refundable. -
      If you have accepted a date but later decide to cancel, please be advised
      that no refunds will be issued.
      <br />
      <br />
      2. <b>Rescheduling Policy</b>
      - We understand that unforeseen circumstances may arise. Therefore, you
      are permitted to reschedule a confirmed date once per engagement. - Should
      you request to reschedule the same date a second time, it will be treated
      as a cancellation, and the terms of our cancellation policy will apply.
      <br />
      <br />
      3. <b>No-Show Policy</b>
      Our goal is to foster a respectful and committed user base. In light of
      this, no refunds will be processed for failure to attend (no show) a
      confirmed date.To maintain the integrity of the user experience, the
      following penalties will apply for no-shows: <br />
      <ul>
        <li>
          <b>First No-Show</b>: Ineligibility to arrange dates through our
          service for the next 3 months.
        </li>
        <br />
        <li>
          <b>Second No-Show</b>: Ineligibility to arrange dates through our
          service for the next 6 months..
        </li>
        <br />
        <li>
          <b>Third No-Show</b>: Ineligibility to arrange dates through our
          service for the next 12 months..
        </li>
        <br />
      </ul>
      These policies are in place to ensure a positive and engaging experience
      for all users of our dating service. By accepting a date, users agree to
      these terms. We appreciate your understanding and cooperation in adhering
      to these guidelines.
      <br />
      <br /> <strong>Terms of Usage</strong> <br />
      <br />
      <b>Eligibility:</b> You must be at least 18 years old to use our dating
      service. By using our service, you confirm that you are at least 18 years
      old. You must be unmarried. By using our service, you confirm that you are
      unmarried. You do not have any unspent convictions, or are subject to any
      court order, relating to assault, violence, sexual misconduct or
      harassment.
      <br />
      <br />
      <b>Account Creation:</b> To use our service, you must create an account by
      providing accurate and complete information about yourself. You must also
      maintain the confidentiality of your account login credentials and notify
      us immediately of any unauthorized use of your account.
      <br />
      <br />
      <b>Safety:</b> The company currently does not conduct any criminal and/or
      other formal background checks of all its users and also does not attempt
      to verify the statements of its users. You are solely responsible for
      taking all appropriate safety precautions in connection with the use of
      the website and app and contacting other users. You accept that there are
      risks interacting online or offline with other users, including dating and
      meeting other users. We do not guarantee or verify the accuracy of
      information provided to you by other users. Twist makes every effort to
      keep the information made available on the website accurate and up to
      date, but we do not guarantee that the information is accurate, complete
      or current. No rights can be derived from it. Any reliance on the provided
      information is at your own risk. We do not warrant that the website will
      be available uninterrupted and in a fully operating condition. All content
      and services on the website or app are provided on an "as is" and "as
      available" basis. Any decisions or actions taken by you on the basis of
      the information provided on or via the website and app are at your sole
      discretion and risk.
      <br />
      <br />
      <b>Risks:</b> Our dating service is provided for entertainment purposes
      only, and we do not guarantee that you will find a romantic partner or
      that any date will be successful. You use our service at your own risk.
      <br />
      <br />
      <b>Liability:</b> We are not liable for any damages, including but not
      limited to emotional distress, injury, or loss, arising from your use of
      our dating service.
      <br />
      <br />
      <b>User Conduct:</b> You must use our service in a lawful and respectful
      manner. You agree not to use our service to harass, stalk, or harm any
      other user. You also agree not to disclose any personal information about
      any other user without their consent. You will not use the Twist for
      soliciting your own business activities or for commercial purposes. You
      will not provide inaccurate, misleading or false information to Twist or
      to any other user. You will not use the service to disrupt public order.
      You will not infringe upon the normal running of Twist or its
      infrastructure in any way. You will immediately cease contacting any user
      who asks you to stop contacting them.
      <br />
      <br />
      <b>Membership Fee:</b> To access the date invite feature of Twist, you are
      required to pay a membership fee.
      <ul>
        <li>
          <b>Fee and Period Disclosure:</b>The amount of the membership fee and the period it covers will be
          clearly communicated to you at the time of sign-up or renewal.
        </li>
        <li>
          <b>Non-refundable:</b>Your membership fee is non-refundable except as expressly set forth in
          these Terms.
        </li>
        <li>
          <b>Consequences of Non-Payment: </b>Failure to pay the membership fee may result in not getting date
          invites.
        </li>
        <li>
         <b>No Guarantee of Date: Please be expressly aware</b> that paying the membership fee does not guarantee that you will secure a date. The fee is for access to the platform's date invite feature only. Our platform facilitates connections between members, but we cannot guarantee that these connections will lead to a date. This is influenced by a variety of factors beyond our control, including but not limited to member preferences, availability, and engagement
        </li>
      </ul>
      <br />

      <b>Service Fee:</b> In addition to the membership fee, users who accept a
      date invite are required to pay a service fee to confirm their date. The
      service fee is separate from and in addition to any membership fee. The
      service fee must be paid prior to the date. Details regarding the amount
      and payment instructions will be provided with the date invite. Service
      fees are used to cover the costs associated with arranging the date and
      are non-refundable, except as provided under our cancellation and refund
      policy.
    </p>
  );

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"8px"}
      >
        <Link
          component="button"
          variant="body2"
          marginTop={"4px"}
          style={{
            color: "blue",
            textDecoration: "underline",
            textAlign: "left",
          }}
          onClick={() => onBackButtonClick()}
        >
          {" "}
          Back
        </Link>
        <br />
        {terms}
      </Box>
    </>
  );
};

export default TermsLayout;
