import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
  Card,
  Collapse,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getPincodeData } from "../../../service/pincode";
import { PostOffice } from "../../../types/signup/pincode";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import Loading from "../../../components/Loading";
import { getInviteCode } from "../../../service/invitecode";
import { Label } from "@mui/icons-material";
import { UserProfile } from "../../../types/signup/profile";
import NextButton from "../../../components/NextButton";
import AudioRecorder from "../../../components/AudioRecorder";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import { pushAnalyticsEvent } from "../../../service/analytics";
import { EVENT_TYPE } from "../../../types/event";
import Icons from "../../../assets/icons";
import { Heartbreak } from "react-bootstrap-icons";
import { subscribeUserForNotifications } from "../../../service/subscription";

interface NotificationsAccessLayoutProps {
  onPressNext: () => void;
}

const NotificationsAccessLayout = ({
  onPressNext,
}: NotificationsAccessLayoutProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCompatibility, setOpenCompatibility] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { userData } = useUser();
  const [audioFile, setaudioFile] = useState<Blob>();
  const [vocalLanguage, setVocalLanguage] = useState<string>("EN");
  const [permissionError, setPermissionError] = useState(false);
  const { userId } = useUser();

  const onSubmit = useCallback(() => {
    onPressNext();
  }, [audioFile, onPressNext]);

  function askPermission() {
   
  }
  
  useEffect(() => {
    if (userData && userData?.data?.preferred_vocal_language) {
      setVocalLanguage(userData?.data?.preferred_vocal_language);
    } else {
      setVocalLanguage("KN");
    }
  
  }, [userData]);

  const onSend = useCallback(async (file: Blob) => {
    setaudioFile(file);
    setShowNextButton(true);
  }, []);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const handleShowingNextButton = () => {
    setShowNextButton(true);
  };

  const subscribeUser = async (userId: string) => {
    try {
      console.log("inside subscribeUser");
      const registration = await navigator.serviceWorker.ready;
      console.log(registration)
      const applicationServerKey = urlBase64ToUint8Array("BOaIAkpSpvmRG-5Q6OuAZzMR4pox5EhXS7dZR9VDYTQCGTU4-gimTiW-rlUgclsXYGA4yYxrZsNVJqVO9lkLrjs");
      console.log("applicationServerKey", applicationServerKey);
      console.log("hello hello");
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      });
      console.log("subscription" + subscription);
  
      const result = await subscribeUserForNotifications(
        userId!,
        subscription
      );
  
      console.log('User is subscribed:', subscription);
    } catch (error) {
      console.error('Failed to subscribe the user:', error);
    }
  };
  
  // Helper function to convert VAPID public key
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

  function requestNotificationPermission() {
    return new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });
  
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function(permissionResult) {
      alert(permissionResult);
      if (permissionResult == 'granted') {
        subscribeUser(userId!);
        onPressNext()
      } else {
        //TODO
        //Show the errors here
        console.log("permission was not denied");
      }
    });
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          paddingBottom: "200px !important",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            <TitleComponent
              title="Allow notifications access"
              subtitle="We need this to send you date notifications."
            />{" "}
            {permissionError && (
              <p>
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Error accessing microphone. Please grant permission to record
                  audio.
                </span>
                <br />
                Follow these steps:
                <ul>
                  <li>
                    Click on the{" "}
                    <img
                      src={Icons.Settings}
                      style={{ width: "40px", height: "40px" }}
                    />{" "}
                    or{" "}
                    <img
                      src={Icons.Info}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                    />
                    icon in the address bar
                  </li>
                  <li>Find 'Microphone' and set it to 'Allow'</li>
                </ul>
              </p>
            )}
            <Button
              variant="contained"
              fullWidth
              onClick={requestNotificationPermission}
              style={{marginTop:"20px"}}
            >
              <MicIcon /> Allow microphone access
            </Button>
          </Grid>
          {showNextButton && <NextButton onPressNext={onSubmit} />}
        </Grid>
      </Card>
    </Box>
  );
};
export default NotificationsAccessLayout;
