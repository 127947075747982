import WelcomeLayout from "../../layouts/Welcome";
import React, { useEffect } from 'react';


const Welcome = () => {
   
    return (
        <WelcomeLayout/>
    );
};

export default Welcome;