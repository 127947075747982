import { Box, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";
import { UserProfile } from "../../../types/signup/profile";


interface UserProfileLayoutProps {
  onPressNext: (value: Partial<UserProfile>) => void;
}

const UserNamesLayout = ({ onPressNext }: UserProfileLayoutProps) => {
  const { userData } = useUser();

  const Schema = Yup.object({
    first_name: Yup.string()
      .min(2, "Please enter valid first name")
      .required("Please enter your first name"),
    last_name: Yup.string()
      .min(1, "Please enter valid last name")
      .required("Please enter your last name"),
  });

  const onSubmit = useCallback(
    (values: Partial<UserProfile>) => {
      onPressNext(values);
    },
    [onPressNext]
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      //invite_code: localStorage.getItem("utm_medium"),
      utm_source: localStorage.getItem("utm_source"),
      utm_medium: localStorage.getItem("utm_medium"),
      utm_campaign: localStorage.getItem("utm_campaign"),
    } as  Partial<UserProfile>,
    validationSchema: Schema,
    onSubmit: onSubmit,
  });
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
  } = formik;

  useEffect(() => {
    if (userData?.data?.basic_details) {
      setFieldValue("first_name", userData?.data?.basic_details?.first_name);
      setFieldValue("last_name", userData?.data?.basic_details?.last_name);
    }
  }, [userData, setFieldValue]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="Hi, Tell us your name!" />

        <Grid container spacing={2} marginTop={"8px"}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="First Name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.first_name}
              error={Boolean(touched.first_name && errors.first_name)}
              onChange={handleChange("first_name")}
            />
            {touched.first_name && errors.first_name && (
              <FormHelperText>{errors.first_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="Last Name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.last_name}
              error={Boolean(touched.last_name && errors.last_name)}
              onChange={handleChange("last_name")}
            />
            {touched.last_name && errors.last_name && (
              <FormHelperText>{errors.last_name}</FormHelperText>
            )}
          </Grid>
        </Grid>
      <NextButton onPressNext={handleSubmit} />
      </Box>
    </>
  );
};
export default UserNamesLayout;
