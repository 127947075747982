import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Box, FormHelperText, TextField } from "@mui/material";
import * as Yup from "yup";
import { isInteger, useFormik } from "formik";
import { ErrorData } from "../../../types/signup/User";
import { Button } from "react-bootstrap";
import shadows from "@mui/material/styles/shadows";
import { getActvityCountData } from "../../../service/user";
import { Link } from "@mui/material";

interface EnterEmailLayoutProps {
  onPressNext: (value: string) => void;
  sendOTPError?: ErrorData;
}

interface EmailValues {
  email: string;
}

const EmailSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/,
      "Please enter a valid email"
    )
    .required("Please enter email"),
});

const EnterEmailLayout = ({
  onPressNext,
  sendOTPError,
}: EnterEmailLayoutProps) => {
  const onSubmit = useCallback(
    (values: EmailValues) => {
      onPressNext(values.email);
    },
    [onPressNext]
  );

  const [malesCount, setMalesUserCount] = useState<any | null>(null);
  const [femalesCount, setFemalesUserCount] = useState<any | null>(null);
  const [datesCount, setDatesCount] = useState<any | null>(null);
  const getActvityData = useCallback(async () => {
    try {
      const result = await getActvityCountData();
      setMalesUserCount(result.male_users_count);
      setFemalesUserCount(result.female_users_count);
      setDatesCount(result.date_count);
    } catch (_) {
      //do nothing for now
    }
  }, []);

  useEffect(() => {
    getActvityData();
  }, [getActvityData]);

  const { values, touched, handleSubmit, errors, handleChange } = useFormik({
    initialValues: {
      email: "",
    } as EmailValues,
    validationSchema: EmailSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Box
        sx={{
          marginTop: "16px",
          padding: "0px 32px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TextField
          id="outlined-basic"
          placeholder="Email"
          variant="outlined"
          type="email"
          sx={{
            maxWidth: { xs: "400px" },
            border: "1px solid #e0e0e0",
            boxShadow: "none",
          }}
          value={values.email}
          onChange={handleChange("email")}
          error={Boolean(touched.email && errors.email)}
          fullWidth
        />
        {touched.email && errors.email && (
          <FormHelperText
            error
            sx={{
              color: { xs: "white", lg: "red" },
            }}
          >
            {errors?.email}
          </FormHelperText>
        )}
        {sendOTPError && (
          <FormHelperText
            error
            sx={{
              color: { sx: "white", lg: "red" },
            }}
          >
            {sendOTPError?.error?.err_msg}
          </FormHelperText>
        )}
      </Box>

      <div className="button-container">
        <Button
          className="btn btn-custom btn-lg w-100 rounded border-none"
          onClick={() => handleSubmit()}
        >
          Create Account
        </Button>
     
        <Button
          style={{ background: "#E0E0E0",
          borderRadius: 8,
          height: "3em",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          color: "#000",
          width:"100%",
          fontWeight: "700",
          border:"1px solid #999"
        
        }}
          onClick={() => handleSubmit()}
        >
          Login
        </Button>
      </div>
    

      <div className="userCountContainer">
        <span> Female users: {femalesCount}, </span>
        <span> Male users: {malesCount}, </span>
        <span> Dates: {datesCount}</span>
      </div>
    </>
  );
};
export default EnterEmailLayout;
