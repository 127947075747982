import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
  Icon,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Seperator from "../../../assets/images/seperator";
import { UserDate } from "../../../types/date";
import { updateDateState } from "../../../service/date";
import parse from "html-react-parser";
import Checkbox from "@mui/material/Checkbox";
import { lightGreen } from "@mui/material/colors";
import { Line } from "react-bootstrap-icons";
import Icons from "../../../assets/icons";
import { cashfree } from "../../../utils/cashfree";
import AudioPlayer from "../../../components/AudioPlayer";
import useUser from "../../../context/UserContext";
import { pushAnalyticsEvent } from "../../../service/analytics";
import { EVENT_TYPE } from "../../../types/event";
import { EVENT_ACTION } from "../../../types/event";
import BottomSheet from "../../../components/BottomSheet";

interface FirstUserDateLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate[];
  onButtonClick: (userdate: UserDate, status: string) => void;
  onTermsButtonClick: () => void;
}

const FirstUserDateLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick,
  onTermsButtonClick,
}: FirstUserDateLayoutProps) => {
  const [openCompatibility, setOpenCompatibility] = useState(false);

  const [ischecked, setIsChecked] = useState(false);
  const [showtermserror, setShowTermsError] = useState(false);
  const [ctaClicked, setCtaClicked] = useState(false);
  const [showAccountError, setShowAccountError] =  useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0); // State to track the current date index
  const { userId, userData } = useUser();

  // Function to navigate between dates
  const navigateCard = (direction: string) => {
    if (direction === "next" && currentCardIndex < userdates!.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ACTION,
        EVENT_ACTION.SEEN_THE_DATE_INVITE,
        currentDate.date_id,
        undefined,
        undefined,
        undefined,
        {}
      );
    } else if (direction === "prev" && currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ACTION,
        EVENT_ACTION.SEEN_THE_DATE_INVITE,
        currentDate.date_id,
        undefined,
        undefined,
        undefined,
        {}
      );
    }
  };

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const openBottomSheet = () => {
    setIsBottomSheetOpen(true);
    console.log("opened");
    console.log(isBottomSheetOpen);
  };

  const closeBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const [openDateDetails, setOpenDateDetails] = useState(false);

  const handleDateDetailsToggle = () => {
    setOpenDateDetails(!openDateDetails);
  };

  const [audioURL, setAudioURL] = useState<string>();

  useEffect(() => {
    pushAnalyticsEvent(
      userId!,
      EVENT_TYPE.ACTION,
      EVENT_ACTION.SEEN_THE_DATE_INVITE,
      currentDate.date_id
    );
    console.log(
      "the about me url is " + currentDate?.match_details.match_about_me_url
    );
    setAudioURL(currentDate?.match_details.match_about_me_url);
  }, []);

  const expiry_time_diff = (date_expiry: string) => {
    const date1 = new Date(date_expiry);
    const date2 = new Date();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffMins = Math.ceil(diffTime / (1000 * 60));
    const diffString = diffHours + " hrs " + diffMins + " mins";
    return diffString;
  };
  const openGoogleMaps = (url: string) => {
    window.open(url, "_blank");
  };

  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to change the active tab
  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current date index
  const [transitionEffect, setTransitionEffect] = useState("");

  const navigate = (direction: string, images: string[]) => {
    setTransitionEffect("opacity 0.5s ease, transform 0.5s ease"); // Smooth and quick transition
    setTimeout(() => {
      if (direction === "next") {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      } else if (direction === "prev") {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
      }
      setTransitionEffect("");
    }, 250); // Half duration of transition for quicker effect
  };

  const goToImage = (index: any) => {
    setCurrentIndex(index);
  };

  const currentDate = userdates![currentCardIndex];

  const TopCardContent = () => {
    return (
      <Grid
        container
        spacing={2}
        paddingLeft={"4px"}
        paddingRight={"4px"}
        width={"100%"}
        paddingBottom={"8px"}
        marginLeft={"0px !important"}
        marginTop={"0px !important"}
      >
        <Grid
          item
          xs={10}
          paddingTop={"0px !important"}
          paddingLeft={"0px !important"}
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}
        >
          <Typography
            color={"#000"}
            fontSize={12}
            style={{ fontWeight: "600" }}
          >
            {currentDate.top_card_section_2_content
              ? currentDate.top_card_section_2_content
              : ""}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const GetUserActionContent = () => {
    return (
      <Grid
        container
        spacing={2}
        marginLeft={"0px !important"}
        marginTop={"0px !important"}
      >
        {currentDate?.bottom_section_content ==
        "show_accept_decline_buttons" ? (
          <>
            <Grid
              container
              spacing={2}
              marginTop={"0px"}
              marginLeft={"0px !important"}
            >
              <Grid
                item
                xs={1}
                paddingTop={"0px !important"}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  paddingLeft: "24px",
                }}
              >
                <Checkbox
                  checked={ischecked}
                  onChange={() => setIsChecked(!ischecked)}
                  style={{ color: lightGreen[500] }}
                />
              </Grid>

              <Grid
                item
                xs={10}
                paddingTop={"0px !important"}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  paddingLeft: "30px",
                }}
              >
                <Typography
                  color={"#000"}
                  fontSize={12}
                  marginTop={"4px"}
                  style={{
                    fontWeight: "400",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  By accepting, I agree to the
                  <Link
                    variant="body2"
                    marginTop={"4px"}
                    fontSize={12}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    /* if the terms and conditions are not accepted, show error and disable the button */
                    onClick={() => {
                      setShowTermsError(false);
                      onTermsButtonClick();
                    }}
                  >
                    {" "}
                    Terms of Usage & Cancellation Policy
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              marginTop={"0px"}
              marginLeft={"0px !important"}
            >
              <Grid item xs={6} style={{ paddingTop: "8px" }}>
                <Button
                  variant="contained"
                  disabled={userData?.data!.state == "VERIFICATION_PENDING"}
                  fullWidth
                  onClick={() => {
                    //send the event here'
                    pushAnalyticsEvent(
                      userId!,
                      EVENT_TYPE.ACTION,
                      EVENT_ACTION.CONSIDERED_ACCEPTING_THE_DATE,
                      currentDate.date_id,
                      undefined,
                      undefined,
                      undefined,
                      {}
                    );

                    if (ischecked) {
                      onButtonClick(currentDate, "confirm");
                      setCtaClicked(true);
                    } else {
                      setCtaClicked(true);
                    }
                  }}
                  // disabled={!ischecked}
                >
                  Accept
                </Button>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: "8px" }}>
                <Button
                  variant="outlined"
                  disabled={userData?.data!.state == "VERIFICATION_PENDING"}
                  fullWidth
                  onClick={() => {
                    //send the event here
                    pushAnalyticsEvent(
                      userId!,
                      EVENT_TYPE.ACTION,
                      EVENT_ACTION.CONSIDERED_REJECTING_THE_DATE,
                      currentDate.date_id,
                      undefined,
                      undefined,
                      undefined,
                      {}
                    );

                    onButtonClick(currentDate, "decline");
                  }}
                  // disabled={!ischecked}
                >
                  Pass
                </Button>
              </Grid>{" "}
              <Grid item xs={12} style={{ paddingTop: "8px" }}>
                <Typography
                  color={"#000"}
                  fontSize={12}
                  style={{ fontWeight: "600" }}
                  marginTop={"4px"}
                  textAlign={"center"}
                  visibility={
                    !ctaClicked ? "hidden" : ischecked ? "hidden" : "visible"
                  }
                >
                  Please agree to the terms and conditions to proceed.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                paddingTop={"0px !important"}
                fontSize={14}
                marginTop={"8px"}
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  color: "#999",
                  fontWeight: "800",
                }}
              >
                {currentDate.date_education_message}
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography
            color={"#000"}
            fontSize={12}
            style={{ fontWeight: "400" }}
            marginTop={"8px"}
            paddingLeft={"12px"}
          >
            {currentDate?.bottom_section_content}
          </Typography>
        )}
      </Grid>
    );
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"8px"}
      >
        {TopCardContent()}
        <Card
          sx={{
            marginTop: "0px",
            padding: "12px 12px 12px 12px !important",
          }}
        >
          {/* Navigation Buttons */}
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-between" }} // Adjusts items to the far left and right
            marginTop={"0px"}
            marginLeft={"0px !important"}
          >
            <Grid
              item
              paddingLeft={"0px !important"}
              paddingTop={"0px !important"}
              style={{
                display: "flex",
                justifyContent: "flex-start", // Aligns this item to the left
              }}
            >
              {currentCardIndex > 0 && (
                <Link
                  onClick={() => navigateCard("prev")}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    textAlign: "left",
                    fontSize: "12px",
                    marginRight: "20px",
                  }}
                >
                  Prev
                </Link>
              )}
            </Grid>
            <Grid
              item
              paddingLeft={"0px !important"}
              paddingTop={"0px !important"}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns this item to the right
              }}
            >
              {currentCardIndex < userdates?.length! - 1 && (
                <Link
                  onClick={() => navigateCard("next")}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    textAlign: "left",
                    fontSize: "12px",
                    marginRight: "20px",
                  }}
                >
                  Next
                </Link>
              )}
            </Grid>
          </Grid>

          {/* More content based on the currentDate */}
          {/* ... */}

          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            style={{
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
              }}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
            >
              {/* <img
                src={usernewdate?.match_details.match_image}
                style={{
                  height: "150px",
                }}    />*/}
              <div
                className="carousel"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="carousel-image-container"
                  style={{
                    width: "100%",
                    height: "336px",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "black",
                  }}
                  onClick={(e) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const x = e.clientX - rect.left; // Get the x position of the click within the container
                    if (x < rect.width / 2) {
                      navigate(
                        "prev",
                        currentDate?.match_details.match_images!
                      );
                    } else {
                      navigate(
                        "next",
                        currentDate?.match_details.match_images!
                      );
                    }
                  }}
                >
                  <img
                    src={currentDate?.match_details.match_images[currentIndex]}
                    alt="Carousel"
                    className="carousel-image"
                    style={{
                      height: "100%",
                      width: "auto",
                      objectFit: "cover",
                      cursor: "pointer",
                      opacity: 1,
                      transition: transitionEffect,
                    }}
                  />
                </div>
                <div
                  className="carousel-navigation"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  {currentDate?.match_details.match_images.map((_, index) => (
                    <span
                      key={index}
                      className={`navigation-bubble ${
                        index === currentIndex ? "active" : ""
                      }`}
                      onClick={() => goToImage(index)}
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#ccc",
                        margin: "0 5px",
                        cursor: "pointer",
                      }}
                    ></span>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            style={{
              width: "100%",
            }}
          >
            <Grid
              item
              xs={8}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "0px",
              }}
              paddingTop={"0px !important"}
            >
              <Grid
                container
                spacing={2}
                marginTop={"0px"}
                marginLeft={"0px !important"}
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingLeft: "0px",
                  }}
                  paddingTop={"0px !important"}
                >
                  <Typography
                    color={"#000"}
                    fontSize={16}
                    textTransform={"capitalize"}
                    style={{ fontWeight: "400" }}
                    display={"inline"}
                  >
                    <strong>
                      {currentDate?.match_details.match_name},
                      {currentDate?.match_details.match_age}{" "}
                    </strong>
                    {currentDate?.match_details.match_is_verified ? (
                      <img
                        src={Icons.Verified}
                        style={{ height: "20px", width: "20px" }}
                        onClick={openBottomSheet}
                      />
                    ) : null}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              marginTop={"8px"}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
            >
              <Grid
                container
                spacing={2}
                marginTop={"0px"}
                marginLeft={"0px !important"}
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: "left",
                    flexDirection: "column",
                    justifyContent: "left",
                    paddingLeft: "0px",
                  }}
                  paddingTop={"0px !important"}
                >
                  <Typography
                    color={"#000"}
                    fontSize={16}
                    marginTop={"8px"}
                    textTransform={"capitalize"}
                    style={{ fontWeight: "300" }}
                  >
                    About {currentDate?.match_details.match_name}{" "}
                    {audioURL != "" && <AudioPlayer audioUrl={audioURL!} />}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    textAlign: "right",
                    flexDirection: "column",
                    justifyContent: "right",
                    paddingLeft: "0px",
                  }}
                  paddingTop={"0px !important"}
                >
                  <Link
                    component="button"
                    variant="body2"
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                    onClick={handleCompatibilityToggle}
                  >
                    {!openCompatibility ? "MORE DETAILS" : "LESS DETAILS"}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              marginTop={"8px"}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
            >
              <Collapse in={openCompatibility}>
                <Grid
                  container
                  spacing={2}
                  marginLeft={"0px"}
                  width={"100%"}
                  marginTop={"0px"}
                >
                  <Grid
                    item
                    xs={12}
                    paddingLeft={"0px !important"}
                    paddingTop={"0px !important"}
                    marginRight={"12px !important"}
                  >
                    {/* <Grid
                      container
                      spacing={2}
                      marginLeft={"0px"}
                      marginTop={"0px"}
                      width={"100%"}
                      borderBottom={"1px solid #c9c9c9"}
                    >
                      <Grid
                        item
                        xs={6}
                        paddingLeft={"0px !important"}
                        paddingTop={"0px !important"}
                      >
                        <div
                          style={
                            activeTab === "tab1"
                              ? {
                                  cursor: "pointer",
                                  border: "1px solid #c9c9c9",
                                  borderBottom: "none",
                                  backgroundColor: "orange",
                                  borderLeft: "1px solid #ccc",
                                  borderRight: "1px solid #ccc",
                                  textAlign: "center",
                                }
                              : {
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  textAlign: "center",
                                }
                          }
                          onClick={() => handleTabClick("tab1")}
                        >
                          Details
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        paddingTop={"0px !important"}
                        paddingLeft={"0px !important"}
                      >
                        <div
                          style={
                            activeTab === "tab2"
                              ? {
                                  cursor: "pointer",
                                  border: "1px solid #c9c9c9",
                                  borderBottom: "none",
                                  backgroundColor: "orange",
                                  borderLeft: "1px solid #ccc",
                                  borderRight: "1px solid #ccc",
                                  textAlign: "center",
                                }
                              : {
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  textAlign: "center",
                                }
                          }
                          onClick={() => handleTabClick("tab2")}
                        >
                          Compatibility
                        </div>
                      </Grid>
                    </Grid> */}

                    <Grid
                      container
                      spacing={2}
                      marginLeft={"0px"}
                      width={"100%"}
                      marginTop={"0px"}
                    >
                      <div>
                        <Grid item xs={12} style={{}}>
                          <ul
                            style={{
                              listStyleType: "none",
                              paddingLeft: "0px",
                              marginTop: "8px",
                              fontSize: "14px",
                            }}
                          >
                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.HobbyC}
                                  style={{ height: "20px" }}
                                />
                              </span>

                              {currentDate?.match_details?.match_interests?.map(
                                (interest, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                      margin: "5px",
                                      display: "inline-block",
                                      backgroundColor: "#fff1ec",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {interest}
                                  </span>
                                )
                              )}
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.RelationshipC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {currentDate?.match_details.match_looking_for}
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.HeightC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                Height -{" "}
                                {currentDate?.match_details.match_height} cms
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.GenderC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {currentDate?.match_details.match_gender}
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.EducationC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {currentDate?.match_details.match_education}
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.WorkoutC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {currentDate?.match_details.match_workout_habit}
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.DrinkingC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {
                                  currentDate?.match_details
                                    .match_drinking_habit
                                }
                              </span>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <span>
                                <img
                                  src={Icons.SmokingC}
                                  style={{ height: "20px" }}
                                />
                              </span>
                              <span style={{ paddingLeft: "10px" }}>
                                {currentDate?.match_details.match_smoke_habit}
                              </span>
                            </li>
                          </ul>
                        </Grid>
                      </div>

                      <div>
                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingTop: "10px",
                          }}
                        >
                          <Typography
                            color={"#000"}
                            fontSize={16}
                            textTransform={"capitalize"}
                            style={{ fontWeight: "600" }}
                          >
                            Your compatibility with{" "}
                            {currentDate?.match_details.match_name}
                          </Typography>

                          <ul
                            style={{
                              listStyleType: "none",
                              paddingLeft: "0px",
                              marginTop: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {Object.keys(
                              currentDate?.compatibility_details
                            ).map((key) => (
                              <li
                                style={{
                                  borderBottom: "1px dotted #c9c9c9",
                                }}
                              >
                                <div>
                                  <img
                                    src={Icons.RelationshipC}
                                    style={{ height: "20px" }}
                                  />{" "}
                                  <span style={{ fontWeight: "700" }}>
                                    {
                                      currentDate?.compatibility_details[key]
                                        .name
                                    }
                                  </span>
                                  <span
                                    style={{
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      marginBottom: "5px",
                                      display: "inline-block",
                                      backgroundColor: "#fff1ec",
                                      borderRadius: "3px",
                                      float: "right",
                                    }}
                                  >
                                    {
                                      currentDate?.compatibility_details[key]
                                        .score
                                    }
                                  </span>
                                </div>
                                <div>
                                  {
                                    currentDate?.compatibility_details[key]
                                      .message
                                  }
                                </div>
                              </li>
                            ))}
                          </ul>

                          {/* <ul
                            style={{
                              listStyleType: "none",
                              paddingLeft: "0px",
                              marginTop: "8px",
                              fontSize: "14px",
                            }}
                          >
                            <li
                              style={{
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.RelationshipC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Relationship Goals Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.common_relationship_goal_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.common_relationship_goal_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.HeightC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Height Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.height_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.height_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.HobbyC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Shared Interests Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.shared_interest_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.shared_interest_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.SmokingC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Smoking Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.lifestyle_smoking_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.lifestyle_smoking_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.DrinkingC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Drinking Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.lifestyle_drinking_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.lifestyle_drinking_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.WorkoutC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Fitness Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.lifestyle_workout_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.lifestyle_workout_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.EarningC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Financial Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.earnings_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.earnings_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.RelationshipC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Relationship Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.relationship_status_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.relationship_status_compatibility
                                }
                              </div>
                            </li>

                            <li
                              style={{
                                marginTop: "10px",
                                borderBottom: "1px dotted #c9c9c9",
                              }}
                            >
                              <div>
                                <img
                                  src={Icons.KidsC}
                                  style={{ height: "20px" }}
                                />{" "}
                                <span style={{ fontWeight: "700" }}>
                                  Kids Compatibility
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginBottom: "5px",
                                    display: "inline-block",
                                    backgroundColor: "#fff1ec",
                                    borderRadius: "3px",
                                    float: "right",
                                  }}
                                >
                                  {
                                    currentDate?.compatibility_details
                                      ?.kids_status_compatibility_score
                                  }
                                </span>
                              </div>
                              <div>
                                {
                                  currentDate?.compatibility_details
                                    ?.kids_status_compatibility
                                }
                              </div>
                            </li>
                          </ul> */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
          >
            <Grid item paddingLeft={"0px !important"}>
              <Typography
                color={"#000"}
                fontSize={16}
                marginTop={"0px"}
                paddingTop={"0px !important"}
                style={{
                  fontWeight: "700",
                  paddingTop: "8px",
                }}
              >
                {currentDate?.date_date_time}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: "0px" }}
              paddingLeft={"0px !important"}
            >
              <Typography
                color={"#000"}
                fontSize={16}
                marginTop={"4px"}
                style={{
                  fontWeight: "400",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {currentDate?.date_address}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <Link
                component="button"
                variant="body2"
                marginTop={"8px"}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  fontSize: "12px",
                  textAlign: "left",
                }}
                onClick={() =>
                  openGoogleMaps(currentDate?.date_google_maps_location!)
                }
              >
                {" "}
                VIEW LOCATION
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
            >
              {/* <Typography
                color={"#000"}
                fontSize={14}
                marginTop={"4px"}
                style={{ display: "inline-flex" }}
              >
                Service fee: &nbsp; &#8377;
                <strong>{currentDate?.date_service_fee} /-</strong>
              </Typography> */}
              {/* <Typography
                color={"#999"}
                fontSize={12}
                marginTop={"4px"}
                marginLeft={"8px"}
                style={{ fontWeight: "700", display: "inline-flex" }}
              >
                To be paid once {usernewdate?.match_name} confirms the date.
              </Typography> */}
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={"16px"}>
            {GetUserActionContent()}

            {showtermserror ? (
              <Typography
                color={"#000"}
                fontSize={12}
                fontWeight={400}
                marginTop={"12px"}
                paddingLeft={"20px"}
                style={{
                  fontWeight: "400",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                Pleace accept the Terms and Conditions to proceed.
              </Typography>
            ) : null}
              {/* {showAccountError ? (
              <Typography
                color={"#000"}
                fontSize={12}
                fontWeight={400}
                marginTop={"12px"}
                paddingLeft={"20px"}
                style={{
                  fontWeight: "400",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                Pleace accept the Terms and Conditions to proceed.
              </Typography>
            ) : null} */}
          </Grid>
          <BottomSheet isOpen={isBottomSheetOpen} onClose={closeBottomSheet}>
            <Grid
              container
              spacing={2}
              marginTop={"0px"}
              marginLeft={"0px !important"}
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "0px",
                }}
                paddingTop={"0px !important"}
              >
                <h1> Verified Account</h1>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "0px",
                }}
                paddingTop={"0px !important"}
              >
                <p>
                  This account is verfied. We have checked the name, age of this
                  profile and matched it with a government id proof and found
                  the information provided correct. This is to ensure safety of
                  all the members.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "0px",
                }}
                paddingTop={"0px !important"}
              >
                {/* <Button
                  variant="contained"
                  fullWidth
                  // disabled={!ischecked}
                >
                  Get verified
                </Button> */}
              </Grid>
            </Grid>
          </BottomSheet>
        </Card>
      </Box>
    </>
  );
};

export default FirstUserDateLayout;
