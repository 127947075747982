import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ErrorData, ResponseData } from "../types/signup/User";
import { Slots } from "../types/signup/slots";

export const getSlots = async () => {
  try {
    const data = await getAxiosClient(false).get(HttpService.SLOTS);
    return data.data as ResponseData<Slots>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};
