import { Box, Button, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";
import { UserProfile } from "../../../types/signup/profile";

interface UserProfileLayoutProps {
  onPressNext: (value: Partial<UserProfile>) => void;
}

const MAX_YEAR = new Date().getFullYear() - 18;
const Schema = Yup.object({
  date: Yup.number().min(1).max(31).required(),
  month: Yup.number().min(1).max(12).required(),
  year: Yup.number().min(1900).max(MAX_YEAR).required(),
});
const DateOfBirthLayout = ({ onPressNext }: UserProfileLayoutProps) => {
  const { userData } = useUser();

  const onSubmit = useCallback(
    (values: Partial<UserProfile>) => {
      console.log(values.year)
      localStorage.setItem("YEAR_OF_BIRTH", values.year!);
      onPressNext(values);
    },
    [onPressNext]
  );

  const formik = useFormik({
    initialValues: {
      date: "",
      month: "",
      year: "",
    } as Partial<UserProfile>,
    validationSchema: Schema,
    onSubmit: onSubmit,
    validateOnChange: true,
  });
  const { values, errors, handleChange, handleSubmit, setFieldValue, touched } = formik;

  useEffect(() => {
    if (userData?.data?.basic_details) {
      const dob = userData?.data?.basic_details?.date_of_birth?.split("-");
      setFieldValue("date", dob?.[0] ?? "");
      setFieldValue("month", dob?.[1] ?? "");
      setFieldValue("year", dob?.[2] ?? "");
    }
  }, [userData, setFieldValue]);

  const setDateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("date", e.target.value);
    },
    [setFieldValue]
  );

  const setMonthValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("month", e.target.value);
    },
    [setFieldValue]
  );

  const setYearValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 4) setFieldValue("year", e.target.value);
    },
    [setFieldValue]
  );


  const hasError = useMemo(() => {
    return Object.keys(errors).length > 0 && touched.date && touched.year && touched.month;
  }, [errors]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="Enter your Date of Birth." subtitle="We will find your match based on your date of birth." />

        <Grid container spacing={2} marginTop={"8px"}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              placeholder="DD"
              variant="outlined"
              type="number"
              inputProps={{ maxLength: 2 }}
              fullWidth
              value={values.date}
              error={hasError}
              onChange={setDateValue}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              placeholder="MM"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 2 }}
              value={values.month}
              error={hasError}
              onChange={setMonthValue}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="YYYY"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 4 }}
              value={values.year}
              error={hasError}
              onChange={setYearValue}
            />
          </Grid>
        </Grid>

        {hasError && (
          <FormHelperText error>{"Please enter valid date of birth"}</FormHelperText>
        )}
        <NextButton onPressNext={handleSubmit} />
      </Box>
    </>
  );
};
export default DateOfBirthLayout;
