import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Seperator from "../../../assets/images/seperator";
import { UserDate } from "../../../types/date";
import { updateDateState } from "../../../service/date";
import { MembershipPlans } from "../../../types/plan";
import Icons from "../../../assets/icons";
import { pushAnalyticsEvent } from "../../../service/analytics";
import { EVENT_TYPE } from "../../../types/event";
import { EVENT_ACTION } from "../../../types/event";

interface MembershipPlansLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate;
  onButtonClick: (plan_id: number) => void;
  userPlans: MembershipPlans;
  onTermsButtonClick: () => void;
}

const MembershipPlansLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick,
  userPlans,
  onTermsButtonClick,

}: MembershipPlansLayoutProps) => {
  const [openLearnMore, setOpenLearnMore] = useState(false);

  const handleLearnMoreToggle = () => {
    setOpenLearnMore(!openLearnMore);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"16px"}
      >
        <Card
          sx={{
            marginTop: "0px",
            padding: "16px 16px",
            background: "#FFFFFF",
          }}
        >
          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
            >
              <Typography
                color={"#000"}
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Pro Members have an edge. Become a Twist Pro Member.
                <Link
                component="button"
                variant="body2"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  textAlign: "left",
                  fontSize: "12px",
                  marginLeft:"10px"
                }}
                onClick={handleLearnMoreToggle}
              >
                {!openLearnMore ? "Learn More" : "Hide Details"}
              </Link>
              </Typography>
            </Grid>
          </Grid>


          <Collapse in={openLearnMore}>
            <Grid
              container
              spacing={2}
              marginLeft={"0px"}
              width={"100%"}
              marginTop={"0px"}
            >
              Pro Members have a higher chance of meeting their best partners than normal members.
              Twist understands that in order to service our users better, it is important to filter users who are not seriously looking for a relationship. Pro Membership helps us in doing this.
            </Grid>
          </Collapse>

          {/* Code for Basic Membership */}
          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginBottom={"20px"}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                background:
                  "linear-gradient(135deg, #E0E0E0 0%, #F5F5F5 50%, #FFCAD4 100%)",
              }}
            >
              <Grid
                container
                spacing={2}
                marginTop={"0px"}
                marginLeft={"0px !important"}
                width={"100%"}
              >
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "30px",
                          marginLeft: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        1 month
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      position={"relative"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {/* <label
                        style={{
                          top: "0",
                          position: "absolute",
                          left: "50%",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#D35400",
                          padding: "3px 5px",
                          fontSize: "12px",
                          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                          zIndex: "10",
                          transform: "translateX(-50%)",
                          whiteSpace: "nowrap", // Ensures the text stays in one line
                        }}
                      >
                        Best Value
                      </label> */}
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "900",
                          marginTop: "30px",
                          marginRight: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        &#8377;{userPlans.BASIC_MEMBERSHIP.discounted_fees}/-
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <ul
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {userPlans.BASIC_MEMBERSHIP.name.split(" ")[0]} plan
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {" "}
                      {userPlans.BASIC_MEMBERSHIP.duration} days of membership.
                
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Access to compatibility scores.
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Daily match for a coffee date.
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: "2.5em",
                          backgroundColor: "gray",
                          color: "white",
                          border: "1px solid #ccc",
                        }}
                        onClick={() =>
                          //send the event here. 
                          onButtonClick(userPlans.BASIC_MEMBERSHIP.id)
                        }
                      >
                        Buy now
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    >
                      {" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Code for Premium Membership */}
          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginBottom={"20px"}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                background:
                  "linear-gradient(135deg, #E0E0E0 0%, #F5F5F5 50%, #FFCAD4 100%)",
              }}
            >
              <Grid
                container
                spacing={2}
                marginTop={"0px"}
                marginLeft={"0px !important"}
                width={"100%"}
              >
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "30px",
                          marginLeft: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        3 months
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      position={"relative"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        style={{
                          top: "0",
                          position: "absolute",
                          left: "50%",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#D35400",
                          padding: "3px 5px",
                          fontSize: "12px",
                          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                          zIndex: "10",
                          transform: "translateX(-50%)",
                          whiteSpace: "nowrap", // Ensures the text stays in one line
                        }}
                      >
                        Best Seller
                      </label>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                          textDecoration: "line-through",
                          fontSize: "18px",
                          marginTop: "30px",
                          fontFamily: "Arial",
                        }}
                      >
                        &#8377;{userPlans.PREMIUM_MEMBERSHIP.fees}
                      </span>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "900",
                          marginTop: "30px",
                          marginRight: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        &#8377;{userPlans.PREMIUM_MEMBERSHIP.discounted_fees}/-
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <ul
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {userPlans.PREMIUM_MEMBERSHIP.name.split(" ")[0]} plan
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {" "}
                      {userPlans.PREMIUM_MEMBERSHIP.duration} days of
                      membership.
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Access to compatibility scores.
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Daily match for a coffee date.
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: "2.5em",
                          color: "white",
                          border: "1px solid #ccc",
                        }}
                        onClick={() =>
                          onButtonClick(userPlans.PREMIUM_MEMBERSHIP.id)
                        }
                      >
                        Buy now
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
                {/* Last row..  */}
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bolder",
                          padding: "5px",
                        }}
                      >
                        {" "}
                        &#8377;{" "}
                        {userPlans.PREMIUM_MEMBERSHIP.discounted_fees / 3}/mon
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Code for Elite Membership */}

          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginBottom={"20px"}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                background:
                  "linear-gradient(135deg, #E0E0E0 0%, #F5F5F5 50%, #FFCAD4 100%)",
              }}
            >
              <Grid
                container
                spacing={2}
                marginTop={"0px"}
                marginLeft={"0px !important"}
                width={"100%"}
              >
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "30px",
                          marginLeft: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        6 months
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      position={"relative"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        style={{
                          top: "0",
                          position: "absolute",
                          left: "50%",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: "#D35400",
                          padding: "3px 5px",
                          fontSize: "12px",
                          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                          zIndex: "10",
                          transform: "translateX(-50%)",
                          whiteSpace: "nowrap", // Ensures the text stays in one line
                        }}
                      >
                        Best Value
                      </label>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                          textDecoration: "line-through",
                          fontSize: "18px",
                          marginTop: "30px",
                          fontFamily: "Arial",
                        }}
                      >
                        &#8377;{userPlans.ELITE_MEMBERSHIP.fees}
                      </span>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "900",
                          marginTop: "30px",
                          marginRight: "10px",
                          fontFamily: "Arial",
                        }}
                      >
                        &#8377;{userPlans.ELITE_MEMBERSHIP.discounted_fees}/-
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <ul
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {userPlans.ELITE_MEMBERSHIP.name.split(" ")[0]} plan
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      {" "}
                      {userPlans.ELITE_MEMBERSHIP.duration} days of membership.
                     
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Access to compatibility scores.
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${Icons.FeatureTick})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "0 0.5em",
                        paddingLeft: "30px",
                        listStyleType: "none",
                        backgroundSize: "15px",
                      }}
                    >
                      Daily match for a coffee date.
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: "2.5em",
                          backgroundColor: "gray",
                          color: "white",
                          border: "1px solid #ccc",
                        }}
                        onClick={() =>
                           //send the event here. 
                          onButtonClick(userPlans.ELITE_MEMBERSHIP.id)
                          
                        }
                      >
                        Buy now
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  paddingTop={"0px !important"}
                  paddingLeft={"0px !important"}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    marginTop={"0px"}
                    marginLeft={"0px !important"}
                    width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bolder",
                          padding: "5px",
                        }}
                      >
                        {" "}
                        &#8377; {userPlans.ELITE_MEMBERSHIP.discounted_fees / 6}
                        /mon
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      paddingTop={"0px !important"}
                      paddingLeft={"0px !important"}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            marginTop={"0px"}
            marginLeft={"0px !important"}
            width={"100%"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginBottom={"20px"}
              textAlign={"center"}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                background:
                  "linear-gradient(135deg, #E0E0E0 0%, #F5F5F5 50%, #FFCAD4 100%)",
              }}
            >
              <Link
                variant="body2"
                marginTop={"4px"}
                fontSize={12}
                onClick={() => {
                  onTermsButtonClick();
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {" "}
                Term and Conditions
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default MembershipPlansLayout;
