import {
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { OptionItem } from "../../types/signup";
interface DropdownProps {
  options: OptionItem[];
  value: string;
  handleChange: (value: string) => void;
}

const Dropdown = ({ value, handleChange, options }: DropdownProps) => {
  const onChangeValue = useCallback(
    (e: SelectChangeEvent<string>) => {
      handleChange(e.target.value);
    },
    [handleChange]
  );

  return (
    <Select
      displayEmpty
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      fullWidth
      value={value}
      sx={{
        marginTop: "16px",
      }}
      onChange={onChangeValue}
    >
      <MenuItem disabled value="">
        <Typography
          sx={{
            color: "#CCCCCC",
          }}
        ></Typography>
      </MenuItem>
      {options.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.value}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default Dropdown;
