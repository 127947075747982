import { Activity } from "react-bootstrap-icons";
import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ErrorData, ResponseData, UploadPic, UserData, ActivityData } from "../types/signup/User";
import { MembershipPlans} from "../types/plan";
import { string } from "yup";

const getMetadata = () => {
  const metadata = {
      browser: navigator.userAgent,
      ip: '', // Placeholder for IP address (since it's not reliable to obtain in JavaScript)
      deviceName: '', // Will be filled with the device name
      screenSize: { // Will store screen width and height
          width: window.innerWidth,
          height: window.innerHeight
      },
      // Add more metadata fields as needed
  };

  // Get device name (example using navigator.platform)
  metadata.deviceName = getDeviceName();
  
  return metadata;
};

const getDeviceName = () => {
  // Some logic to determine the device name
  // You can use various properties of the navigator object or other techniques to infer the device name
  // For example, you might want to check navigator.userAgent or navigator.platform
  // Below is just a simple example, you may need to enhance it for more accurate results
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return 'iOS Device';
  } else if (/Android/.test(navigator.userAgent)) {
      return 'Android Device';
  } else {
      return 'Unknown Device';
  }
};


export const pushAnalyticsEvent = async (user: string, type: string, action: string, value: string, error_name?: string, error_message?: string, error_stack?: any, metadata?: Record<string, any> ) => {
  console.log("value of the error stack is" + value);
  try {
    const eventData = {
      userId: user,
      type: type,
      action: action,
      value: value,
      error: {
        name: error_name,
        message: error_message,
        stack: error_stack
      },
      timestamp: new Date().toISOString(),
      deviceMetadata: getMetadata(),
      metadata: metadata 
      // Add more event-specific data as needed
    };

    console.log("before sending the error to the server" + JSON.stringify(eventData));


    let url = `${HttpService.ERROR}`
    const response = await getAxiosClient(false).post(url,{
      payload: eventData
    });
  } catch (e: any) {
    
  }
};

