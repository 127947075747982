import { Question, QuestionTypes } from "../types/signup";
import { BasicDetails } from "../types/signup/User";
import { UserProfile } from "../types/signup/profile";

export const createRequestBody = (question: Question) => {
  if (question.type === QuestionTypes.CHECKBOX) {
    const list = question.value.split(",").filter((ele) => ele !== "");
    return {
      [question.field]: list,
    };
  } else {
    return {
      [question.field]: question.value,
    };
  }
};

export const createBasicDetailsBody = (profile: Partial<UserProfile>) => {
  const data : Partial<BasicDetails> = {};
  if(profile.first_name && profile.last_name) {
    data.first_name =  profile.first_name
    data.last_name =  profile.last_name
  }
  if(profile.primary_phone) {
    data.primary_phone = {
      country_code:'IN',
      number: profile.primary_phone
    }
  }
  if(profile.date && profile.month && profile.year) {
    data.date_of_birth = profile.date + "-" + profile.month + "-" + profile.year
  }
  if(profile.area && profile.city) {
    data.area = profile.area
    data.city = profile.city
  }
  return data;
};
