import UPIPaymentLayout from "../../layouts/UPIPayment";
import React, { useEffect } from 'react';


const UPIPayment = () => {
   
    return (
        <UPIPaymentLayout/>
    );
};

export default UPIPayment;