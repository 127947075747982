import { useEffect } from "react";
import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ResponseData, Verification, ErrorData } from "../types/signup/User";


export const sendOTP = async (email: string) => {
  /* get the utm_source from the queryparam */
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get('utm_source') || 'none';
  const utm_medium = queryParams.get('utm_medium') || 'none';
  const utm_campaign = queryParams.get('utm_campaign') || 'none';

  try {
    const data = await getAxiosClient(false).post(HttpService.SEND_OTP, 
    {
      email: email,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
    });
    return data.data as ResponseData<Verification>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};


interface VerifyOTPParams {
    verification_id: string,
    otp: string,
}

export const verifyOTP = async ({verification_id,otp}:VerifyOTPParams) => {
  try {
    const data = await getAxiosClient(false).post(HttpService.VERIFY_OTP, {
      verification_id: verification_id,
      verification_code: otp,
    });
    return data;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};

export const resendOTP = async (verification_id:string) => {
  try {
    const data = await getAxiosClient(false).post(HttpService.RESEND_OTP, {
      verification_id: verification_id,
    });
    return data.data as ResponseData<Verification>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};
