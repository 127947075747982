import {
  Box,
  Card,
  Checkbox,
  Fab,
  FormHelperText,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Icons from "../../../assets/icons";
import TitleComponent from "../../../components/TitleComponent";
import {createNotification} from "../../../service/date";

interface TermsLayoutProps {
  onPressNext: () => void;
}
const terms = (
  <p>
    Before you begin using Twist , we want to ensure that you are aware of our
    terms and conditions. By accessing and using our services, you agree to be
    bound by the following terms and conditions:
    <br />
    <br />
    <b>Eligibility:</b> You must be at least 18 years old to use our dating
    service. By using our service, you confirm that you are at least 18 years
    old. You must be unmarried. By using our service, you confirm that you are
    unmarried. You do not have any unspent convictions, or are subject to any
    court order, relating to assault, violence, sexual misconduct or harassment.
    <br />
    <br />
    <b>Account Creation:</b> To use our service, you must create an account by
    providing accurate and complete information about yourself. You must also
    maintain the confidentiality of your account login credentials and notify us
    immediately of any unauthorized use of your account.
    <br />
    <br />
    <b>Safety:</b> The company currently does not conduct any criminal and/or
    other formal background checks of all its users and also does not attempt to
    verify the statements of its users. You are solely responsible for taking
    all appropriate safety precautions in connection with the use of the website
    and app and contacting other users. You accept that there are risks
    interacting online or offline with other users, including dating and meeting
    other users. We do not guarantee or verify the accuracy of information
    provided to you by other users. Twist makes every effort to keep the
    information made available on the website accurate and up to date, but we do
    not guarantee that the information is accurate, complete or current. No
    rights can be derived from it. Any reliance on the provided information is
    at your own risk. We do not warrant that the website will be available
    uninterrupted and in a fully operating condition. All content and services
    on the website or app are provided on an "as is" and "as available" basis.
    Any decisions or actions taken by you on the basis of the information
    provided on or via the website and app are at your sole discretion and risk.
    <br />
    <br />
    <b>Risks:</b> Our dating service is provided for entertainment purposes
    only, and we do not guarantee that you will find a romantic partner or that
    any date will be successful. You use our service at your own risk.
    <br />
    <br />
    <b>Liability:</b> We are not liable for any damages, including but not
    limited to emotional distress, injury, or loss, arising from your use of our
    dating service.
    <br />
    <br />
    <b>User Conduct:</b> You must use our service in a lawful and respectful
    manner. You agree not to use our service to harass, stalk, or harm any other
    user. You also agree not to disclose any personal information about any
    other user without their consent. You will not use the Twist for soliciting
    your own business activities or for commercial purposes. You will not
    provide inaccurate, misleading or false information to Twist or to any other
    user. You will not use the service to disrupt public order. You will not
    infringe upon the normal running of Twist or its infrastructure in any way.
    You will immediately cease contacting any user who asks you to stop
    contacting them.
  </p>
);

const TermsLayout = ({ onPressNext }: TermsLayoutProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    setShowError(false);
  }, [checked]);



  const onAcceptTerms = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      setChecked(value);
    },
    []
  );

  const onSubmit = useCallback(() => {
    if (checked) {
      return onPressNext();
    }
    setShowError(true);
  }, [onPressNext, checked]);

  useEffect(() => {
    const user_id = localStorage.getItem("USER_ID");
    createNotification(user_id!);
  }, []); // The empty dependency array ensures this runs once on mount


  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="Terms & Conditions" />
        <Card
          sx={{
            maxHeight: "50vh",
            overflowY: "scroll",
            padding: "8px 16px",

            marginTop: "16px",

            background: "#FFFFFF",
          }}
        >
          <Typography
            fontWeight={"700"}
            whiteSpace={"pre-line"}
            fontSize={"12px"}
          >
            {terms}
          </Typography>
        </Card>
        <Box display={"flex"} alignItems={"center"}>
          <Checkbox checked={checked} onChange={onAcceptTerms} />

          <Typography fontWeight={"700"} fontSize={"12px"} marginTop={"8px"}>
            I accept to Twist’s terms and conditions & give consent to send
            communication on Whatsapp
          </Typography>
        </Box>
        {showError && (
          <FormHelperText>
            Please accept terms and conditions to continue
          </FormHelperText>
        )}
      </Box>
      <Fab
        sx={{
          position: "absolute",
          bottom: { xs: "32px", md: "64px" },
          right: { xs: "32px", md: "64px" },
        }}
        onClick={onSubmit}
      >
        <img src={Icons.Next} width={"100%"} height={"100%"} alt="Go" />
      </Fab>
    </>
  );
};
export default TermsLayout;
