import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import DogImage from "../../../assets/nodatedog";

interface NoDatesLayoutProps {
    onPressNext?: () => void;
    fontSize?: number;
    marginTop?: string;
  }
  
  const NoDatesLayout = ({
      onPressNext,
      fontSize = 32,
      marginTop = "16px",
    }: NoDatesLayoutProps) => {
        return (
            <>
            <Card
                sx={{
                marginTop: marginTop,
                padding: "16px 16px",
                background: "#FFFFFF",
                 }}
             >
                 <Typography color={"#000"} fontSize={16}>We don't have a right match for you!</Typography>
                 <img style={{display:'flex', width:'100%',marginTop:'16px'}} src={DogImage.DOG_IMAGE}/>
                 <Typography color={"#000"} fontSize={16} marginTop={"16px"}>You are special. It's hard to find the great match. Give us some time. We will reach out once we have the best match for you.</Typography>
             </Card>
            
            </>
        );
    };
    
export default NoDatesLayout;
