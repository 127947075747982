import { TextField } from "@mui/material";
import React, { useCallback } from "react";
interface TextBoxProps {
  value: string;
  handleChange: (value: string) => void;
}

const TextBox = ({ value, handleChange }: TextBoxProps) => {
  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e.target.value);
    },
    [handleChange]
  );

  return (
    <TextField
      style={{
        marginTop: "32px",
      }}
      id="outlined-basic"
      variant="outlined"
      type="text"
      fullWidth
      value={value}
      onChange={onChangeValue}
    />
  );
};
export default TextBox;
