import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import NextButton from "../../../components/NextButton";

interface HowItWorksLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
}

const Details: string[] = [
  "Everyday you will get one match based on your preferences.",
  "We find your daily match based on the inputs you have provided.",
  "We will set up a date for you with your match at a coffee shop if you and your match accept the date.",
];

const HowItWorksLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
}: HowItWorksLayoutProps) => {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
      >
        <Card
          sx={{
            marginTop: marginTop,
            padding: "16px 16px",
            background: "none"
          }}
        >
          <Typography color={"#000"} fontSize={16} style={{fontWeight:'bold'}}>How Twist Works:</Typography>
          {Details.map((item, index) => {
            return (
              <Stack direction={"row"} marginTop={"8px"}>
                <Typography color={"#000"} fontSize={16}>{`${
                  index + 1
                }. `}</Typography>
                <Typography color={"#000"} fontSize={16} marginLeft={"8px"} style={{fontWeight:'400'}}>
                  {item}
                </Typography>
              </Stack>
            );
          })}
        </Card>
      </Box>
      {onPressNext && <NextButton onPressNext={onPressNext} />}
    </>
  );
};
export default HowItWorksLayout;
