import React from 'react';
import logoImage from './twist.png'; // Assuming you have a logo image

const LogoComponentHeader = () => {
  return (
    <div>
      <img src={logoImage} alt="Logo" className="header-logo" />
    </div>
  );
};

export default LogoComponentHeader;
