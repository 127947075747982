import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
  Card,
  Collapse,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getPincodeData } from "../../../service/pincode";
import { PostOffice } from "../../../types/signup/pincode";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import Loading from "../../../components/Loading";
import { getInviteCode } from "../../../service/invitecode";
import { Label } from "@mui/icons-material";
import { UserProfile } from "../../../types/signup/profile";
import NextButton from "../../../components/NextButton";
import AudioRecorder from "../../../components/AudioRecorder";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";

interface AboutMeLayoutProps {
  onPressNext: (file: Blob) => void;
}

const AboutMeLayout = ({ onPressNext }: AboutMeLayoutProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCompatibility, setOpenCompatibility] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { userData } = useUser();
  const [audioFile, setaudioFile] = useState<Blob>();
  const [vocalLanguage, setVocalLanguage] = useState<string>("EN");
  const onSubmit = useCallback(() => {
    onPressNext(audioFile!);
  }, [audioFile, onPressNext]);

  useEffect(() => {
    if (userData && userData?.data?.preferred_vocal_language) {
      setVocalLanguage(userData?.data?.preferred_vocal_language);
    } else {
      setVocalLanguage("EN");
    }
  }, [userData]);

  const onSend = useCallback(async (file: Blob) => {
    setaudioFile(file);
    if (file != null){
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
   
  }, []);

  const hideNextButton =async () => {
    setShowNextButton(false);
  };

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const handleShowingNextButton = () => {
    setShowNextButton(true);
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          paddingBottom: "200px !important",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            <TitleComponent
              title="Record audio introduction"
              subtitle="Record audio introduction for 30 seconds. Check Examples."
            />{" "}
            <Link
              component="button"
              variant="body2"
              style={{
                color: "blue",
                textDecoration: "underline",
                textAlign: "left",
                fontSize: "12px",
              }}
              onClick={handleCompatibilityToggle}
            >
              {!openCompatibility ? "Examples" : "Less Examples"}
            </Link>
            <Collapse in={openCompatibility}>
              {vocalLanguage == "EN" && (
               <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Tell more about your background, which city do you come
                    from?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Tell more about your job. Don't give name of your work place
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Tell more about your family? Who all are there in your
                    family?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    How was your childhood? Tell a story about your childhood.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What is your philosophy of life?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Which subject did you like the most as a child? Why did you
                    like it?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Where did you travel recently? Tell more about it?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What are your hobbies?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    Tell about the challenges you have faced in your life?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What are your long term goals?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What do you day dream the most?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What are some of your unusual or not so normal habits?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    What is your favourite movie? Why did you like it?
                  </p>
                </div>
              )}
              {vocalLanguage == "KN" && (
                <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಹಿನ್ನೆಲೆ ಬಗ್ಗೆ ಹೇಳಿ, ನೀವು ಯಾವ ಊರಿನಿಂದ ಬಂದಿದ್ದೀರಿ?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಕೆಲಸದ ಬಗ್ಗೆ ಹೇಳಿ. ನೀವು ಕೆಲಸ ಮಾಡುವ ಸ್ಥಳದ ಹೆಸರು ಹೇಳಬೇಡಿ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಕುಟುಂಬದ ಬಗ್ಗೆ ಹೇಳಿ? ನಿಮ್ಮ ಕುಟುಂಬದಲ್ಲಿ ಯಾರು ಯಾರು
                    ಇದ್ದಾರೆ?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಬಾಲ್ಯ ಹೇಗಿತ್ತು? ನಿಮ್ಮ ಬಾಲ್ಯದ ಕಥೆ ಹೇಳಿ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಜೀವನದ ತತ್ತ್ವ ಏನು?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನೀವು ಬಾಲ್ಯದಲ್ಲಿ ಯಾವ ವಿಷಯವನ್ನು ಹೆಚ್ಚು ಇಷ್ಟಪಡುತ್ತೀರಿ? ಯಾಕೆ
                    ಇಷ್ಟಪಡುತ್ತೀರಿ?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನೀವು ಇತ್ತೀಚೆಗೆ ಯಾವ ಕಡೆಗೆ ಪ್ರವಾಸ ಮಾಡಿದ್ದೀರಿ? ಅದರ ಬಗ್ಗೆ
                    ವಿವರವಾಗಿ ಹೇಳಿ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಹವ್ಯಾಸಗಳು ಯಾವವು?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ ನೀವು ಎದುರಿಸಿದ ಸವಾಲುಗಳನ್ನು ವಿವರಿಸಿ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ದೀರ್ಘಕಾಲದ ಉದ್ದೇಶಗಳು ಯಾವವು?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನೀವು ಹೆಚ್ಚಿನದಾಗಿ ಯಾವ ಕನಸು ಕಾಣುತ್ತೀರಿ?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಅಸಾಮಾನ್ಯ ಅಥವಾ ಅಜಾಗರೂಕವೆಂಬ ಹವ್ಯಾಸಗಳೆಂಥವು?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    ನಿಮ್ಮ ಪ್ರಿಯ ಚಲನಚಿತ್ರ ಯಾವದು? ಯಾಕೆ ನೀವು ಅದನ್ನು ಇಷ್ಟಪಟ್ಟಿರಿ?
                  </p>
                </div>
              )}
              {vocalLanguage == "HN" && (
                 <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    अपनी पृष्ठभूमि के बारे में बताएं, आप किस शहर से हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    अपने काम के बारे में बताएं। काम की जगह का नाम न बताएं।
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    अपने परिवार के बारे में बताएं। आपके परिवार में कौन-कौन हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपका बचपन कैसा था? अपने बचपन की एक कहानी बताएं।
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपका जीवन का सिद्धांत क्या है?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    बचपन में आपका पसंदीदा विषय कौन सा था? आपको वह क्यों पसंद था?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपने हाल में कहाँ यात्रा की? इसके बारे में बताएं।
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपके शौक क्या हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपके जीवन की चुनौतियों के बारे में बताएं।
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपके दीर्घकालिक लक्ष्य क्या हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आप किसके बारे में सबसे ज्यादा दिवास्वप्न देखते हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपकी कुछ अजीब या अलग आदतें क्या हैं?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    आपकी पसंदीदा फिल्म कौन सी है? आपको वह क्यों पसंद है?
                  </p>
                </div>
              )}
              {vocalLanguage == "ML" && (
               <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ പശ്ചാത്തലം കുറിച്ച് കൂടുതലായി പറയൂ, നിങ്ങൾ ഏത്
                    നഗരത്തിൽ നിന്ന് വന്നത്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ജോലിയെ കുറിച്ച് കൂടുതൽ പറയൂ. നിങ്ങളുടെ
                    തൊഴിൽസ്ഥലത്തിന്റെ പേര് പറയരുത്.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ കുടുംബത്തെ കുറിച്ച് കൂടുതൽ പറയൂ. നിങ്ങളുടെ
                    കുടുംബത്തിലുള്ളവർ ആരെല്ലാമാണ്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ബാല്യം എങ്ങനെയായിരുന്നു? നിങ്ങളുടെ ബാല്യത്തിലെ ഒരു
                    കഥ പറയൂ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ജീവിതത്തിലെ തത്വം എന്താണ്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ബാല്യത്തിൽ ഏറ്റവും ഇഷ്ടപ്പെട്ട വിഷയം ഏത്? അത്
                    നിങ്ങൾക്ക് ഇഷ്ടമായതെന്തുകൊണ്ട്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    അടുത്തിടെ നിങ്ങൾ എവിടെയായിരുന്നു യാത്ര ചെയ്തത്? അതിനെ
                    കുറിച്ച് കൂടുതൽ പറയൂ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ഹോബികൾ എന്തെല്ലാം?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ജീവിതത്തിൽ നിങ്ങൾ നേരിട്ട വെല്ലുവിളികളെ കുറിച്ച്
                    പറയൂ.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ ദീർഘകാല ലക്ഷ്യങ്ങൾ എന്തൊക്കെയാണ്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങൾ ഏറ്റവും കൂടുതൽ സ്വപ്നം കാണുന്നത് എന്തിനെക്കുറിച്ചാണ്?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങളുടെ അസാധാരണമായ അല്ലെങ്കിൽ സാധാരണയല്ലാത്ത ചില ശീലങ്ങൾ
                    എന്തെല്ലാം?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    നിങ്ങൾക്കിഷ്ടമുള്ള സിനിമ ഏത്? അതിനെ നിങ്ങൾക്ക് ഇഷ്ടമായത്
                    എന്തുകൊണ്ട്?
                  </p>
                </div>
              )}
              {vocalLanguage == "TU" && (
               <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ నేపథ్యం గురించి మరిన్ని వివరాలు చెప్పండి, మీరు ఏ నగరం
                    నుండి వచ్చారు?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ పని గురించి మరిన్ని వివరాలు చెప్పండి. మీ కార్యాలయ పేరు
                    చెప్పకండి.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ కుటుంబం గురించి చెప్పండి. మీ కుటుంబంలో ఎవరు ఉన్నారు?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ చిన్నప్పటి గురించి చెప్పండి. మీ చిన్ననాటి ఒక కథ చెప్పండి.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ జీవన దృక్పథం ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీరు చిన్నప్పుడు ఎలాంటి విషయాలు ఎక్కువగా ఇష్టపడ్డారు? ఎందుకు
                    అది ఇష్టం?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీరు ఇటీవల ఎక్కడికి ప్రయాణం చేశారు? దాని గురించి మరిన్ని
                    వివరాలు చెప్పండి.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ హాబీలు ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీరు జీవితంలో ఎదుర్కొన్న సవాళ్లు ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ దీర్ఘకాలిక లక్ష్యాలు ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీరు ఎక్కువగా కలలు కనే విషయాలు ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీ అసాధారణమైన లేదా సాధారణం కాని అలవాట్లు ఏమిటి?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    మీకు ఇష్టమైన సినిమా ఏమిటి? ఎందుకు అది ఇష్టం?
                  </p>
                </div>
              )}
              {vocalLanguage == "TM" && (
              <div style={{padding:"5px 10px", marginTop:"20px", fontStyle: 'italic'}}>
                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் பின்னணி பற்றி மேலும் சொல்லுங்கள், நீங்கள் எந்த
                    நகரத்தில் இருந்து வந்தீர்கள்?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் வேலையின் பற்றி மேலும் சொல்லுங்கள். உங்கள் வேலை
                    செய்யும் இடத்தின் பெயரை குறிப்பிட வேண்டாம்.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் குடும்பத்தைப் பற்றி சொல்லுங்கள்? உங்கள் குடும்பத்தில்
                    யாரெல்லாம் இருக்கின்றனர்?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் குழந்தைப் பருவம் எப்படி இருந்தது? உங்கள் குழந்தைப்
                    பருவம் பற்றிய ஒரு கதை சொல்லுங்கள்.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் வாழ்க்கையின் தத்துவம் என்ன?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    நீங்கள் குழந்தையாக இருந்தபோது எந்த பாடத்தை மிகவும்
                    விரும்பினீர்கள்? ஏன் அதை விரும்பினீர்கள்?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    நீங்கள் சமீபத்தில் எங்கு பயணம் செய்தீர்கள்? அதைப் பற்றி
                    மேலும் சொல்லுங்கள்.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்களுக்கு என்ன பொழுதுபோக்கு உள்ளது?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் வாழ்க்கையில் நீங்கள் சந்தித்த சவால்களைப் பற்றி
                    சொல்லுங்கள்.
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்கள் நீண்டநாள் இலக்குகள் என்ன?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    நீங்கள் அதிகம் கனவுகள் காண்பது என்ன?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்களுக்குள் உள்ள சில விசித்திரமான அல்லது சாதாரணமற்ற
                    பழக்கங்கள் என்ன?
                  </p>

                  <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                    உங்களின் பிடித்த திரைப்படம் என்ன? அது ஏன் பிடித்தமானது?
                  </p>
                </div>
              )}
            </Collapse>
            <AudioRecorder
              onSend={onSend}
              buttonText="Record audio introduction"
              hideNextButton = {hideNextButton}
            />
          </Grid>
          {showNextButton && <NextButton onPressNext={onSubmit} />}
        </Grid>
      </Card>
    </Box>
  );
};
export default AboutMeLayout;
