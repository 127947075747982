import React from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { SlotRow } from "../../../types/signup/slots";

interface SlotItemProps {
  item: SlotRow;
  index: number;
  onSelect: (id: number) => void;
  selected: number[];
}

const SlotItem = ({ item, onSelect, selected }: SlotItemProps) => {
  return (
    <TableRow
      key={item.row_name}
      sx={{
        padding: "0px",
        border: "none"
      }}
    >
      <TableCell
        sx={{
          padding: "0px",
          border: "none",
          fontWeight:'500',
          fontFamily: "Raleway",
          fontSize: "16px"
        }}
      >
        {item.row_name}
      </TableCell>
      {item.row_items.map((item) => {
        return (
          <TableCell
            sx={{
              padding: "0px",
              border: "none",
              
            }}
            key={item.id}
            align="center"
          >
            <Checkbox
              onClick={() => onSelect(item.id)}
              checked={selected.includes(item.id)}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default SlotItem;
