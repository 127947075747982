import React, { useState, useRef } from "react";
import { Button, IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import PauseIcon from "@mui/icons-material/Pause";
import useUser from "../../context/UserContext";
import { pushAnalyticsEvent } from "../../service/analytics";
import { EVENT_TYPE } from "../../types/event";
import Icons from "../../assets/icons";
import { Grid, Link } from "@mui/material";
interface AudioRecorderProps {
  onSend: (file: Blob) => void;
  buttonText: string;
  hideNextButton: () => void;
}

const AudioRecorder = ({ onSend, buttonText, hideNextButton }: AudioRecorderProps) => {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [permissionError, setPermissionError] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const { userId } = useUser();
  const [micDisabled, setMicDisabled] = useState(false);

  // Function to check and request microphone permission
  const checkAndRequestMicrophonePermission = async () => {
    try {
      const permissionStatus = await (navigator.permissions as any).query({
        name: "microphone" as PermissionName,
      });

      if (permissionStatus.state === "granted") {
        startRecording();
      } else if (permissionStatus.state === "prompt") {
        requestMicrophonePermission();
      } else {
        setPermissionError(true);
        console.error("Microphone permission denied");
      }

      permissionStatus.onchange = () => {
        if (permissionStatus.state === "granted") {
          startRecording();
        } else {
          setPermissionError(true);
        }
      };
    } catch (error: any) {
      console.error("Error checking microphone permission:", error);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ERROR,
        "",
        "",
        error.name,
        error.message,
        error.stack,
        {}
      );
      setPermissionError(true);
    }
  };

  const handleRemoveRecording = async () => {
    setMicDisabled(false)
    setAudioBlob(null)
    hideNextButton();
  };

  // Function to request microphone permission
  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop()); // Close the stream immediately
      startRecording();
    } catch (error: any) {
      console.error("Error requesting microphone permission:", error);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ERROR,
        "",
        "",
        error.name,
        error.message,
        error.stack,
        {}
      );
      setPermissionError(true);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const chunks: Blob[] = [];

      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        setRecording(false);
        setRecordingDuration(0);
        if (audioBlob) {
          onSend(audioBlob);
        }
      };

      mediaRecorder.start();
      setRecording(true);
      startTimer();
      mediaRecorderRef.current = mediaRecorder;
    } catch (error: any) {
      console.error("Error accessing microphone:", error);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ERROR,
        "",
        "",
        error.name,
        error.message,
        error.stack,
        {}
      );
      setPermissionError(true);
    }
  };

  const stopRecording = () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder) {
      mediaRecorder.stop();
      clearInterval(timerIdRef.current!);
      const stream = mediaRecorder.stream;
      stream.getTracks().forEach((track) => track.stop());
      setMicDisabled(true)
    }
  };

  const startTimer = () => {
    const timerId = setInterval(() => {
      setRecordingDuration((prevDuration) => prevDuration + 1);
    }, 1000);
    timerIdRef.current = timerId;
  };

  return (
    <div style={{ marginTop: "32px" }}>
      {permissionError && (
        <p>
          <span
            style={{ color: "red", fontWeight: "bold", marginBottom: "20px" }}
          >
            Error accessing microphone. Please grant permission to record audio.
          </span>
          <br />
          Follow these steps:
          <ul>
            <li>
              Click on the{" "}
              <img
                src={Icons.Settings}
                style={{ width: "40px", height: "40px" }}
              />{" "}
              or{" "}
              <img
                src={Icons.Info}
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "10px",
                  marginRight: "20px",
                }}
              />
              icon in the address bar
            </li>
            <li>Find 'Microphone' and set it to 'Allow'</li>
          </ul>
        </p>
      )}

      {!permissionError && (
        <div style={{ width: "100%", textAlign: "center" }}>
          {recording && (
            <progress max={60} value={recordingDuration}></progress>
          )}
          {!recording && audioBlob && (
            <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
              <Grid
                item
                xs={11}
                style={{
                  textAlign: "left",
                  paddingLeft: "0px",
                  paddingTop: "0px",
                }}
              >
                <audio
                  ref={audioRef}
                  src={URL.createObjectURL(audioBlob)}
                  controls
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "0px",
                  paddingTop: "0px",
                }}
              >
                <Link
                  component="button"
                  variant="body2"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={handleRemoveRecording}
                >
                  <img
                    src={Icons.Bin}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Link>
              </Grid>
            </Grid>
          )}
          {!recording && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={checkAndRequestMicrophonePermission}
              
                style={{
                  borderRadius: "50px",
                  height: "60px",
                  background: "gray",
                   display: micDisabled ? "none" : "inline-flex"
                }}
              >
                <MicIcon />
              </Button>
            </div>
          )}
          {recording && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <IconButton onClick={stopRecording}>
                <PauseIcon />
              </IconButton>
              {recording && <span>{recordingDuration}s</span>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
