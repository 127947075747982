import React,{ useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/themes";
import { UserContextProvider } from "./context/UserContext";
import TwistRouter from "./route";
import { QueryClientProvider, QueryClient } from "react-query";
import ReactPixel from 'react-facebook-pixel';


const queryClient = new QueryClient();

function App() {
  console.log("process.env.NODE_ENV" + process.env.NODE_ENV);

  useEffect(() => {
    if(process.env.NODE_ENV == "production"){
       // Initialize Facebook Pixel
       ReactPixel.init('941313847060608');
       ReactPixel.pageView(); 
    } 
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <TwistRouter />
          </UserContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
