import axios from "axios";

const getAxiosClient = (withCredentials = true) => {
  const client = axios.create({
    // Set the base URL for all requests
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      //Commenting this as was getting CORS in localhost. 
      //https://stackoverflow.com/questions/62569594/request-header-field-access-control-allow-origin-is-not-allowed-by-access-contr
      //'Access-Control-Allow-Origin': '*'
    },
    timeout: 1000 * 60,
    withCredentials: withCredentials,
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
      error.response.status === 401 ||
      error.response.data?.error?.err_str === "E_REQUEST_UNAUTHORIZED"
      ) {
      localStorage.clear();
      window.location.reload();
      }
      throw error;
    }
  );

  return client;
};
export default getAxiosClient;
