import PaymentLayout from "../../layouts/Payment";
import React, { useEffect } from 'react';


const Payment = () => {
   
    return (
        <PaymentLayout/>
    );
};

export default Payment;