import React from "react";
import { Typography } from "@mui/material";
interface TitleComponentProps {
  title: string;
  subtitle?: string;
}

const TitleComponent = ({ title, subtitle }: TitleComponentProps) => {
  return (
    <>
      <Typography fontWeight={"700"} fontSize={"24px"}>
        {title}
      </Typography>
      {subtitle && (
        <Typography fontSize={"16px"} sx={{
          marginTop: "8px",
          fontWeight: "400",
          fontFamily: "Raleway"
      
       }}>
          {subtitle}
        </Typography>
      )}
    </>
  );
};
export default TitleComponent;
