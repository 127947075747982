import React, { useRef, useState } from "react";
import { Image } from "react-bootstrap";
import Icons from "../../assets/icons";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from '@mui/icons-material/Pause';
import { Icon } from "@mui/material";

interface AudioPlayerProps {
  audioUrl: string;
}

function AudioPlayer({ audioUrl }: AudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement>(null!);
  const [showPlay, setShowPlay] = useState<boolean>(true);
  const [showPause, setShowPause] = useState<boolean>();

  const handlePlay = () => {
    audioRef.current.play();
    setShowPlay(false);
    setShowPause(true);
   
  };

  const handlePause = () => {
    audioRef.current.pause();
    setShowPlay(true);
    setShowPause(false);
  };

  return (
    <div style={{"display":"inline"}}>
      <audio ref={audioRef} src={audioUrl} />
      {showPlay && (
        <Image
        src={Icons.Play}
          onClick={handlePlay}
          style={{
            width: "25px",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
            zIndex: 1
          }}
        ></Image>
      )}

      {showPause && (
        <Image
        src ={Icons.Pause}
          onClick={handlePause}
          style={{
            width: "25px",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
          }}
        ></Image>
      )}
    </div>
  );
}

export default AudioPlayer;
