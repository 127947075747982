const HttpService = {
  SEND_OTP: "/twist-api/api/v1/verification/otp/send",
  VERIFY_OTP: "/twist-api/api/v1/verification/otp/verify",
  RESEND_OTP: "/twist-api/api/v1/verification/otp/resend",
  USER: "/twist-api/api/v1/users/",
  INTERESTS: "/twist-api/api/v1/interests",
  SLOTS: "/twist-api/api/v1/slots",
  INVITE_CODE: "/twist-api/api/v1/invite_code/",
  ACTIVITY: "/twist-api/api/v1/activity_data",
  PIN_CODE: "/twist-api/api/v1/pin_codes/",
  ARTICLE_COUNT: "/twist-api/api/v1/article_count",
  USER_DATE: "/twist-api/api/v1/date/",
  SEND_NOTIFICATION: "/twist-api/api/v1/date/notifications/newuser",
  ORDER: "/twist-api/api/v1/order",
  ERROR: "/twist-api/api/v1/events",
  SUBSCRIBE: "/twist-api/api/v1/subscribe"
};
export default HttpService;
