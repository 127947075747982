const RouterPath = {
    Home:'/',
    Signup : "/signup",
    UserData : "/user",
    Dashboard:"/dashboard",
    Payment:"/payment",
    UPIPayment:"/upipayment",
    Welcome:"/welcome",
    Delete:"/delete",
    UpdateProfile: "/updateprofile"
}
export default RouterPath;