import React, { useMemo, useCallback } from "react";
import { OptionItem } from "../../types/signup";
import { Box } from "@mui/material";
import Picker from "../Picker";

interface SliderItemProps {
  options: OptionItem[];
  value: string;
  handleChange: (value: string) => void;
}

const Slider = ({ handleChange, options, value }: SliderItemProps) => {
  const pickerValue = useMemo(() => {
    return {
      value,
    };
  }, [value]);

  const setPickerValue = useCallback(
    (result: any) => {
      handleChange(result.value);
    },
    [handleChange]
  );

  return (
    <Box  marginTop={'16px'} borderRadius={'8px'}>
      <Picker value={pickerValue} onChange={setPickerValue} wheelMode="normal">
        <Picker.Column key={"value"} name={"value"}>
          {options.map((option) => (
            <Picker.Item
              key={option.value}
              value={option.value}
              selected={option.value === value}
              style={{
                  color:option.value === value ? '#000':'#000'
              }}
            >
              {option.text}
            </Picker.Item>
          ))}
        </Picker.Column>
      </Picker>
    </Box>
  );
};
export default Slider;
