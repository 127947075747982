import React, { useCallback, useMemo, useState } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { InterestTypes } from "../../../types/signup/interests";

interface InterestItemProps {
  data: InterestTypes;
  values: number[];
  onSelect: (item: number) => void;
}

const InterestItem = ({ data, values, onSelect }: InterestItemProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const onClickItem = useCallback(
    (id: number) => {
      onSelect(id);
    },
    [onSelect]
  );

  const onClickSwapShow = useCallback(() => {
    setShowAll(!showAll);
  }, [showAll]);

  const filteredData = useMemo(() => {
    return showAll ? data.interests : data.interests.slice(0, 5);
  }, [data, showAll]);

  return (
    <Box marginTop={"4px"} marginBottom={"4px"}>
      <Typography fontWeight={"700"} fontSize={"16px"}>
        {data.category_name}
      </Typography>
      <Grid container marginTop={"8px"} marginBottom={"16px"} spacing={1}>
        {filteredData.map((item, index) => {
          return (
            <Grid item key={item.id}>
              <Chip
                label={item.name}
                key={item.id}
                color={values.includes(item.id) ? "secondary" : "primary"}
                clickable
                onClick={() => onClickItem(item.id)}
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              />
            </Grid>
          );
        })}
      </Grid>

      {data.interests.length > 5 && (
        <Typography
          color={"#000"}
          fontWeight={"bold"}
          sx={{
            fontSize: "10px",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={onClickSwapShow}
        >
          {!showAll ? "Show More" : "Show less"}
        </Typography>
      )}
    </Box>
  );
};
export default InterestItem;
