import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
} from "@mui/material";
import React, { useState } from "react";
import Seperator from "../../../assets/images/seperator";
import {UserDate} from "../../../types/date";
import { updateDateState } from "../../../service/date";

interface DateConfirmationLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate
  onButtonClick: (status: string, reason: string) => void;
}

const DateConfirmationLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick

}: DateConfirmationLayoutProps) => {
  const [openCompatibility, setOpenCompatibility] = useState(false);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const [openDateDetails, setOpenDateDetails] = useState(false);

  const handleDateDetailsToggle = () => {
    setOpenDateDetails(!openDateDetails);
  };

  const openGoogleMaps = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"16px"}
      >
        <Card
          sx={{
            marginTop: "0px",
            padding: "16px 16px",
            background: "#FFFFFF",
          }}
        >
          <Typography color={"#000"} fontSize={16}  style={{ fontWeight: "400" }}>
              After you click Confirm, we will send the date invite to your match. The date will happen if your match accepts the invite. 
          </Typography>
          

          <Grid container spacing={2} marginTop={"16px"}>
            <Grid container spacing={2} marginTop={"0px"} marginLeft={"4px"}>
              <Grid item xs={6} style={{paddingTop: "8px"}}>
                <Button variant="contained" fullWidth 
                 onClick={() => onButtonClick("confirm","")}
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={6} style={{paddingTop: "8px"}}>
                <Button variant="outlined" fullWidth
                 onClick={() => onButtonClick("cancel","")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default DateConfirmationLayout;
