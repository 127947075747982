import NextIcon from "./next.svg";
import AddIcon from "./add.svg";
import BlueTick from "./icons8-tick-90.png";
import Drinking from "./drinking.png";
import DrinkingC from "./drinking-c.png";
import Smoking from "./smoking.png";
import SmokingC from "./smoking-c.png";
import Workout from "./workout.png";
import WorkoutC from "./workout-c.png";
import Education from "./education.png";
import EducationC from "./degree-c.png";
import Height from "./height.png";
import HeightC from "./ruler-c.png";
import Relationship from "./relationship.png";
import RelationshipC from "./relationship-c.png";
import Gender from "./gender.png";
import GenderC from "./gender-c.png";
import Hobby from "./hobby.png";
import HobbyC from "./hobby-c.png";
import KidsC from "./kids-c.png";
import EarningC from "./earning-c.png";
import Prev from "./prev.png";
import Next from "./next.png";
import FeatureTick from "./icons8-tick.png"
import Congrats from "./Congrats.gif"
import Error from "./Error.png"
import Error2 from "./Error2.png"
import Copy from "./copy.png"
import QRCode from "./qrcode.png"
import WhatsApp  from "./whatsapp.png"
import Mic from "./mic.png"
import Pause from "./pause2.png"
import Play from "./PLAY2.png"
import Menu from "./menu.png"
import Settings from "./settings.png"
import Info from "./info.png"
import Bin from "./bin.png"

const Icons = {
  Next: NextIcon,
  Add: AddIcon,
  BlueTick: BlueTick,
  Drinking: Drinking,
  DrinkingC: DrinkingC,
  Smoking: Smoking,
  SmokingC: SmokingC,
  Workout: Workout,
  WorkoutC: WorkoutC,
  Education: Education,
  EducationC: EducationC,
  Height: Height,
  HeightC: HeightC,
  Relationship: Relationship,
  RelationshipC: RelationshipC,
  Gender: Gender,
  GenderC: GenderC,
  Hobby: Hobby,
  HobbyC:HobbyC,
  KidsC:KidsC,
  EarningC:EarningC,
  PrevB: Prev,
  NextB: Next,
  FeatureTick:FeatureTick,
  Congrats: Congrats,
  Error:Error2,
  Copy: Copy,
  QRCode: QRCode,
  WhatsApp: WhatsApp,
  Mic: Mic,
  Pause: Pause,
  Play: Play,
  Menu: Menu,
  Verified: BlueTick,
  Settings: Settings,
  Info: Info,
  Bin: Bin
};
export default Icons;
