import {
  Box,
  Card,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import SlotItem from "./SlotItem";
import { Slots } from "../../../types/signup/slots";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";

interface SlotsLayoutProps {
  onPressNext: (selectedSlots: number[]) => void;
  slots?: Slots;
}
const MIN_SLOTS = 5;

const SlotsLayout = ({ onPressNext, slots }: SlotsLayoutProps) => {
  const { userData } = useUser();
  const [selectedSlots, setSelectedSlots] = useState<number[]>([]);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (userData && userData?.data?.slot_ids) {
      setSelectedSlots(userData?.data?.slot_ids);
    }
  }, [userData]);

  const onSelect = useCallback(
    (value: number) => {
      const values = [...selectedSlots];
      if (values.includes(value)) {
        const newValues = values.filter((item) => item !== value);
        setSelectedSlots(newValues);
      } else {
        const newValues = [...values];
        newValues.push(value);
        setSelectedSlots(newValues);
      }
    },
    [selectedSlots]
  );

  const onSubmit = useCallback(() => {
    if (selectedSlots.length < MIN_SLOTS) {
      setShowError(true);
    } else {
      setShowError(false);
      onPressNext(selectedSlots);
    }
  }, [selectedSlots, onPressNext]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"16px"}
      >
        <TitleComponent title="Select 5 slots for the date" subtitle="We will set up your coffee date based on these slots" />
        <Card
          sx={{
            marginTop: "16px",
            padding: "16px 16px",
            overflowX: "scroll",
            overflowY: "scroll",
            background: "#FFFFFF",
          }}
        >
          {slots && (
            <Table
              border={0}
              sx={{
                overflowX: "scroll",
                overflowY: "scroll",
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    padding: "0px",
                    border: "none",
                   
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "4px",
                      border: "none",
                      fontFamily: "Raleway",
                      fontSize: "16px"
                    }}
                  />
                  {slots.headers?.map((item) => {
                    return (
                      <TableCell
                        key={item.header_text}
                        sx={{
                          padding: "4px",
                          border: "none",
                          fontFamily: "Raleway",
                          fontSize: "16px"
                        }}
                        align="center"
                      >
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography fontWeight={"600"}>
                            {item.header_text?.split("-")[0]}
                          </Typography>
                          <Typography fontWeight={"600"}>
                            {item.header_text?.split("-")[1]}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  padding: "0px",
                  border: "none",
                }}
              >
                {slots.rows?.map((item, index) => {
                  return (
                    <SlotItem
                      item={item}
                      key={item.row_name}
                      index={index}
                      onSelect={onSelect}
                      selected={selectedSlots}
                      
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Card>

        <Typography fontSize={"12px"} fontStyle={"normal"} marginTop={"16px"}>
          {`${selectedSlots.length} slots selected`}
        </Typography>
        {showError && (
          <FormHelperText error={true}>
            Please select atleast {MIN_SLOTS} slots
          </FormHelperText>
        )}
      </Box>

      <NextButton onPressNext={onSubmit} />
    </>
  );
};
export default SlotsLayout;
