import { OptionItem, Question, QuestionTypes } from "../types/signup";

export enum SignupSteps {
  EMAIL = "EMAIL",
  OTP = "OTP",
  BASIC_DETAILS = "BASIC_DETAILS",
  FIRST_LAST_NAME  = "FIRST_LAST_NAME",
  PHONE_NUMBER = 'PHONE_NUMBER',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  CITY_AREA = 'CITY_AREA',
  PHOTOS = "PHOTOS",
  QUESTIONS = "QUESTIONS",
  INTERESTS = "INTERESTS",
  TNC = "TNC",
  HOW_IT_WORKS = "HOW_IT_WORKS",
  REFER = "REFER",
  SLOTS = "SLOTS",
  MICROPHONE_ACCESS = "MICROPHONE_ACCESS",
  ABOUT_ME = "ABOUT_ME",
  PREFERRED_VOCAL_LANGUAGE = "PREFERRED_VOCAL_LANGUAGE",
  NOTIFICATION_ACCESS = "NOTIFICATION_ACCESS"
}

const getCentimeters = (feet: number,inches: number)=>  (feet * 30.48 + inches * 2.54).toFixed(2)

const getHeight = ()=> {
  const list:OptionItem[] = [];
  let feet = 4;
  let inch = 8;
  while(feet<=6){
    list.push({
      text:`${feet}' ${inch}''`,
      value: `${getCentimeters(feet,inch)}`
    })
    inch++;
    if(inch === 12) {
      inch = 0;
      feet++;
    }
    if(feet === 6 && inch === 8) {
      break;
    }
  }
  return list;
}


const getWeight = () => {
  const list: OptionItem[] = [];
  let weight = 40;
  while(weight <= 150){
    list.push({
      text: `${weight} kgs`,
      value:  `${weight}`
    })
    weight++
  }
  return list;
}
export const QuestionsList: Question[] = [
  {
    field: "gender",
    question: "What’s your gender?",
    subtitle: "",
    eventkey: "gender",
    options: [
      {
        text: "Woman",
        value: "WM",
      },
      {
        text: "Man",
        value: "MN",
      },
      {
        text: "Non binary",
        value: "NB",
      },
    ],
    page:"GENDER",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a gender",
    metadata: ""
  },
  {
    field: "pref_partner_gender",
    question: "Who would you like to meet?",
    subtitle: "We will find your match among users who match this gender.",
    eventkey: "partner_sex",
    options: [
      {
        text: "Women",
        value: "WM",
      },
      {
        text: "Men",
        value: "MN",
      },
      {
        text: "Nonbinary People",
        value: "NB",
      },
    ],
    page:"PREF_PARTNER_GENDER",
    value: "",
    type: QuestionTypes.CHECKBOX,
    error: "Please select preffered partner genders",
    metadata: ""
  },
  {
    question: "What's your relationship goal?",
    subtitle: "We will find your match based on your relationship goal.",
    field: "pref_commitment",
    eventkey: "pref_commitment",
    options: [
      {
        text: "Life Partner",
        value: "LP",
      },
      {
        text: "Long Term Relationship",
        value: "LR",
      },
      {
        text: "Long Term Open to Short",
        value: "LS",
      },
      {
        text: "Short Term Open to Long",
        value: "SL",
      },
      {
        text: "Short Term Relationship",
        value: "SR",
      },
    ],
    page:"PREF_COMMITMENT",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select your preferred commitment",
    metadata: ""

  },
  {
    question: "Do you smoke?",
    subtitle: "We will match you with users who have similar smoking habits.",
    field: "pref_smoke",
    eventkey: "pref_smoke",
    options: [
      { text: "Socially", value: "SC" },
      { text: "Never", value: "NV" },
      { text: "Regularly", value: "RG" },
    ],
    page:"PREF_SMOKE",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "Do you drink?",
    subtitle: "We will match you with users who have similar drinking habits.",
    field: "pref_drink",
    eventkey: "pref_drink",
    options: [
      { value: "SC", text: "Socially" },  
      { value: "RG", text: "Regularly" },
      { value: "NV", text: "Never" },
    ],
    page:"PREF_DRINK",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "What's your highest education?",
    subtitle: "We will match you with users who have similar education.",
    field: "education",
    eventkey: "education",
    options: [
      { value: "HS", text: "High school" },
      { value: "IC", text: "In college" },
      { value: "GD", text: "Graduate degree" },
      { value: "MD", text: "Master's degree" }
    ],
    page:"EDUCATION",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "Do you work out?",
    subtitle: "We will match you with users who have similar fitness levels.",
    field: "pref_workout",
    eventkey: "pref_workout",
    options: [
      { value: "AT", text: "Active" },
      { value: "SM", text: "Sometimes" },
      { value: "NV", text: "Almost never" },
    ],
    page:"PREF_WORKOUT",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "Would you like to have children?",
    field: "pref_children",
    eventkey: "pref_children",
    options: [
      { value: "WS", text: "Want someday" },
      { value: "DW", text: "Don't want" },
      { value: "NS", text: "Not sure yet" },
      { value: "HK", text: "Have kids" },
    ],
    page:"PREF_CHILDREN",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "Which religion do you identify with?",
    field: "religion",
    eventkey: "religion",
    options: [
      { value: "HN", text: "Hindu" },
      { value: "ML", text: "Muslim" },
      { value: "CR", text: "Christian" },
      { value: "JN", text: "Jain" },
      { value: "SK", text: "Sikh" },
      { value: "BD", text: "Budhism" },
    ],
    page:"RELIGION",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "How tall are you?",
    subtitle: "We will find a match who would look good with you.",
    field: "height",
    options: getHeight(),
    page:"HEIGHT",
    value: "174",
    type: QuestionTypes.SLIDER,
    error: "Please select your height",
    eventkey: "height",
    metadata: ""
  },
  {
    question: "What's your weight?",
    subtitle: "We will find a match who would look good with you.",
    field: "weight",
    options: getWeight(),
    page:"WEIGHT",
    value: "",
    type: QuestionTypes.SLIDER,
    error: "Please select your weight",
    eventkey: "weight",
    metadata: ""
  },
  {
    question: "Where is your home town?",
    field: "home_town",
    eventkey: "home_town",
    options: [],
    page:"HOME_TOWN",
    value: "",
    type: QuestionTypes.TEXTBOX,
    error: "Please enter your role",
    metadata: ""
  },
  {
    question: "How soon do you want to settle down?",
    subtitle: "We will match you with users ready to settle down when you are.",
    field: "settle_down_period",
    eventkey: "settle_down_period",
    options: [
      { value: "ST", text: "0 - 1 Year" },
      { value: "OT", text: "1 - 2 Years" },
      { value: "TF", text: "2 - 4 Years" },
      { value: "NV", text: "Never " },
    ],
    page:"SETTLE_DOWN_PERIOD",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "Select your income range.",
    subtitle: "We will match you with users who have similar income.",
    field: "salary_range",
    eventkey: "salary_range",
    options: [
      { value: "OT", text: "1 - 3 Lacs" },
      { value: "TS", text: "3 - 6 Lacs" },
      { value: "ST", text: "6 - 12 Lacs" },
      { value: "TT", text: "12 - 20 Lacs " },
      { value: "TF", text: "20 - 50 Lacs " },
      { value: "FA", text: "50+ Lacs " }

    ],
    page:"SALARY_RANGE",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: ""
  },
  {
    question: "What about kids?",
    subtitle: "We will match you with users who have similar kids status.",
    field: "kids_status",
    eventkey: "kids_status",
    options: [
      { value: "HK", text: "Have Kids" },
      { value: "NK", text: "Don't Have Kids" }
    ],
    page:"KIDS_STATUS",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: "NOT SHOWN ON YOUR PROFILE."
  },
  {
    question: "What's your relationship status?",
    field: "relationship_status",
    eventkey: "relationship_status",
    options: [
      { value: "NM", text: "Never married" },
      { value: "SP", text: "Seperated" },
    ],
    page:"RELATIONSHIP_STATUS",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
    metadata: "NOT SHOWN ON YOUR PROFILE."
  },
  {
    question: "What's your preferred vocal language?",
    subtitle: "You would need to add a voice introduction in this language.",
    field: "preferred_vocal_language",
    eventkey: "preferred_vocal_language",
    options: [
      { value: "EN", text: "English" },
      { value: "HN", text: "Hindi" },
      { value: "KN", text: "Kannada" },
      { value: "ML", text: "Malyalam" },
      { value: "TU", text: "Telugu" },
      { value: "TM", text: "Tamil" },
    ],
    page:"PREFERRED_VOCAL_LANGUAGE",
    value: "",
    type: QuestionTypes.RADIO,
    error: "Please select a option",
  },

];
