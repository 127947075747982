import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "../pages/Signup";
import RouterPath from "./RouterPath";
import User from "../pages/User";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Post from "../layouts/Blog";
import Payment from "../pages/Payment";
import UPIPayment from "../pages/UPIPayment";
import Welcome from "../pages/Welcome";
import DeleteAccount from "../pages/DeleteAccount";
import UpdateProfile from "../pages/UpdateProfile";

const TwistRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path={RouterPath.Home} element={<Home />} />
          <Route path={RouterPath.Signup} element={<Signup />} />
          <Route path="/blog/:postTitle/:posterId/:headingId" element={ <Post />} />
         

        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path={RouterPath.UserData} element={<User />} />
          <Route path={RouterPath.Dashboard} element={<Dashboard />} />
          <Route path={RouterPath.Payment} element={<Payment />} />
          <Route path={RouterPath.UPIPayment} element={<UPIPayment />} />
          <Route path={RouterPath.Welcome} element={<Welcome />} />
          <Route path={RouterPath.Delete} element={<DeleteAccount />} />
          <Route path={RouterPath.UpdateProfile} element={<UpdateProfile />} />
         

        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default TwistRouter;
