import React from "react";
import useUser from "../context/UserContext";
import { Navigate, Outlet } from "react-router-dom";
import RouterPath from "./RouterPath";

const PublicRoutes = () => {
  const { userId, accountDeleted } = useUser();
  return (
    <>
      {console.log(userId)}
      {userId!=null? (
        <>
          {console.log("navigating to userdata")}
          <Navigate to={RouterPath.UserData} />
        </>
      ) : (
        <>
        {console.log("navigating to outlet")}
          <Outlet />
        </>
      )}
    </>
  );
};
export default PublicRoutes;
