// Post.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from "../../assets/logo";
import LadyImage from "../../assets/images/ladyImage"
import { Border } from 'react-bootstrap-icons';
import "./blogmobile.styles.scss";
import HttpService from "../../http-client/services";
import PressureCooker from '../../assets/images/pressureCooker';
import { fetchInviteCount, updateInviteCount } from '../../service/blog';
import CoupleImage from '../../assets/images/couple';


const openWhatsApp = (postId: any, posterId: any, headingId: any) => {
  const message = `Check this out!\n https://thetwistapp.com?utm_source=${headingId}&utm_medium=${posterId}&utm_campaign=${postId}`
  updateInviteCount()
  const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
  window.location.href = url;
};

const firstcontent = <p>
 Amrita is a Senior Architect in her early thirties and lives in the heart of Bangalore city. She says, <i>“I love living my life independently, being in a fulfilling career and having attained financial stability. However, despite the enchantment of work and the vibrant city life, there are many times when I still yearn for a connection, a true partner, to go through life’s ups and downs.” </i>    
 <p className="break"></p>
 <img className='ladyimage' src={LadyImage.LADY_IMAGE}/>
 <p className="break"></p>
 In India today, there are several stories like Amrita’s, with <b>over 13.2 million unmarried single women</b> above the age of 20. With women fighting hard to build careers and be self-sufficient, one of their internal struggles has always been trying to find the right partner to fit into their lives. Working on building careers in their early 20s has pushed a lot of women into looking at their companionship needs only in their late 20s and even 30s. 
 <p className="break"></p>
 Amrita further says, <i>“There are also family expectations and societal pressure added to the mix. But I’m not one to be rushed. I still believe in the magic of love and envision a partner who will share my dreams, complement me and cherish my soul.”</i>
 <p className="break"></p>
 <img className='ladyimage' src={CoupleImage.Couple_IMAGE}/>
 <p className="break"></p>
 <i>“Then there is also the swiping culture, where people make split-second judgements based on a handful or photos and a brief bio. This feels starkly disconnected from the intricate nuances of real-life connections. As I walk by the park in the evenings, I see the laughter of families or an elderly couple holding hands on a bench, and moments like this kindle a hope in me that my own love story is waiting to unfold.”</i>
 <p className="break"></p>
 This story resonates with <b>more than 47% of women</b>, who feel that finding a companion has become more challenging today. And sure enough changing times need updated solutions.
 <p className="break"></p>
 Modern day <b>companionship apps</b> like <b>Twist</b>, aim to address this concern by analyzing users’ personalities, interests, and values to find their perfect match and then curate an experience for a real-time conversation over a coffee date.
 <p className="break"></p>
 {/* Share this story with that friend who is going through this struggle so they feel a little less alone and stay positive. */}
</p>

const secondcontent = <p>
India, where over 13.2 million fabulous <b>single</b> women lead the charge in the 'never-married' category.
<p className="break"></p>
Ever wondered about these women? While some proudly celebrate their independence, many battle the societal pressure cooker. But that's not all - the dating app scene? It's like a jungle out there. Swipe fatigue, shallow convos, and scams galore.
<p className="break"></p>
While some bask in their solo glory, many battle the raised eyebrows and awkward questions that come with being unattached. It's like a societal treasure hunt - searching for a partner while dodging family inquiries, especially for those in their early thirties.
<p className="break"></p>
<img className='ladyimage' src={PressureCooker.PRESSURE_COOKER}/>
<div className='imageContent'>Rising Steam: The Weight of Societal Expectations</div>
<p className="break"></p>
<span className='bold-heading'>"The swiping culture, where people make split-second judgments based on a handful of photos and a brief bio, feel starkly disconnected from the intricate nuances of real-life connections. Online conversations often begin with surface-level pleasantries, lacking the depth and emotional resonance" says Amrita, an architect by profession.</span>
<p className="break"></p>
<p className="break"></p>
Neha, the Engineering Manager, paints a vivid picture: "Ah, the joy of scrolling through friends' lovey-dovey posts while dodging the well-meaning 'So, any dates?' inquiries. It's like my pursuit of excellence accidentally swiped left on love!"
<p className="break"></p>
<span className='highChar'>T</span><span className='lowChar'>wist understands the hurdles faced by career-focused women who've dedicated years to their professions. In response to the companionship challenge in modern society, Twist specializes in handpicking top-notch matches and arranging coffee dates for individuals seeking their perfect companions.</span>
<p className="break"></p>
"Existing dating apps meant filtering endless messages daily, lacking conversations. Twist saved time, found the right match, and offered ideal conversation spots." - Anjali Jain, Senior Product Manager in a Fintech startup.
<p className="break"></p>
</p>

const thirdcontent = <p>
  Once upon a time.. whoops, scratch that. Let's dive into the modern-day fairy tale of Amrita, a bright-eyed architect in a city where dreams are as high as the skyscrapers. But in the maze of love? She's wandering without a GPS. 🤧
  <p className="break"></p>
  She's not your typical heroine. She's navigating through the concrete jungle of a city that never sleeps, balancing her architectural career with a social life that's, well, more architectural than social. She's the girl who'd rather spend Friday nights with her sketches than swiping left and right on dating apps. And why not? Buildings are less complicated, no?
  <p className="break"></p>
  You're probably thinking, 'Been there, done that, got the t-shirt'. Ever craved a real, heart-to-heart conversation in a world where 'deep talk' often means debating whether pineapple belongs on pizza? Welcome to Amrita's world.
  <p className="break"></p>
  Amrita's idea of romance is less 'knight in shining armour' and more 'someone who doesn't think 'architect' is spelt with an 'e' at the end'. She's tried the dating scene, but let's face it, finding someone who gets you is a tad bit tough. 
  <p className="break"></p>
  Then comes Twist – no, not a new yoga pose nor just an app, but a new way to connect meaningfully, minus the usual dating game. Twist understands that Amrita isn't looking for her Prince Charming; she just wants a guy who won't yawn when she talks about sustainable architecture. So, they set her up – coffee dates at reasonable hours, in places where you can actually hear each other. Sounds real, right?

  <p className="break"></p>
  First date, the guy's nice, but he thinks 'Art Deco' is a fancy coffee drink. Next!

  <p className="break"></p>
  The second date, better, but he's obsessed with making his cat an Instagram star. Amrita likes cats, but she's more into concrete jungles than cats.
  <p className="break"></p>
  By the third date, Amrita's starting to think her soulmate got stuck in a tree. But then, she meets someone. He's not an architect, but he doesn't run away screaming when she talks about her work. He's into graphic design, loves a good laugh, and thinks her obsession with Brutalist architecture is, surprisingly, not brutal.
  <p className="break"></p>
  So, what's the moral of the story? Love might not be like the movies, where you bump into Mr. Right in the rain, and suddenly everything's in slow motion. It's more like a construction project – it needs a good plan, a bit of patience, and, sometimes, a hard hat.
  <p className="break"></p>
  And Amrita? She's still figuring it out, one coffee date at a time. But at least now, she's got more to look forward to. 
  <p className="break"></p>
  Do you know a 'Amrita', Lend a helping hand! 
  <p className="break"></p>


</p>


const posts = [
  {
    id: 1,
    title: 'is-finding-love-a-pain',
    headline: 'Is Finding Love A Pain?',
    subHeading: 'The race between building a career and finding the right partner is a struggle for today’s women.',
    content: firstcontent,
  },
  {
    id: 2,
    title: 'societal-pressure-cooker-for-13.2-million',
    headline: 'The Societal Pressure Cooker.',
    content: secondcontent,
  },
  {
    id: 3,
    title: 'modern-day-fairy-tale-of-amrita',
    content: thirdcontent,
  },
];

const PostLayout = () => {
    // State to hold the invite count
    const [inviteCount, setInviteCount] = useState(2312); // Default count
    const { postTitle, posterId, headingId } = useParams();
    const post = posts.find(post => post.title === postTitle);
    const postsource = "BL00" + post?.id;
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchInviteCount();
          setInviteCount(data);
        } catch (error) {
          // Handle error if the fetch fails
          console.error("Error fetching invite count:", error);
        }
      };
    
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
    <div style={{background:'white'}}>
        <div>
        <img
            src={Logo.TWIST_ICON}
            width={"80px"}
            height={"36px"}
            alt="logo"
          />
        </div>
        

        <h2 className ='heading'>{post?.headline}</h2>
        <h2 className ='subheading'>{post?.subHeading}</h2>
        <div className="author-info">
          <div className="author-avatar">
            <span className="author-initials">M</span>
          </div>
        <div className="author-details">
          <span className="author-name">Mridula Shankar</span><br/>
          <span className="time-to-read">2 min read</span>
        </div>
        </div>
        <p className='postcontainer' style={{padding: '20px', fontSize: '18px', fontFamily:'Georgia, Times New Roman, serif'}}>
          
          {post?.content}
          
          <p>Imagine being the catalyst for your friends' happiness! Introduce them to Twist. Your invitation might just complete their love puzzle. </p>
          <p>Join the {inviteCount} readers who've invited friends to Twist and lend a helping hand.</p>
<button onClick={() => openWhatsApp(postsource,posterId, headingId)} style={{backgroundColor:'#FF5A5F',color:'#ffffff',boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)',transition:'all 0.3s ease', border:'none', padding: '10px 20px', fontWeight: '700', borderRadius: '5px'}}>Tell a friend about Twist</button><br/>
<span>Invite shared {inviteCount} times</span>
          </p>
       
    </div>
    );
};

export default PostLayout;
