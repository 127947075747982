import React from 'react';
import logoImage from './twist.png'; // Assuming you have a logo image

const LogoComponent = () => {
  return (
    <div>
      <img src={logoImage} alt="Logo" className="logo" />
    </div>
  );
};

export default LogoComponent;
