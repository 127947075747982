import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getPincodeData } from "../../../service/pincode";
import { PostOffice } from "../../../types/signup/pincode";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import Loading from "../../../components/Loading";
import { getInviteCode } from "../../../service/invitecode";
import { Label } from "@mui/icons-material";
import { UserProfile } from "../../../types/signup/profile";


interface UserProfileLayoutProps {
  onPressNext: (value: UserProfile) => void;
}

const MAX_YEAR = new Date().getFullYear() - 18;

const UserProfileLayout = ({ onPressNext }: UserProfileLayoutProps) => {
  const [areas, setAreas] = useState<PostOffice[]>([]);
  const { userData } = useUser();
  const [pincodeLoading,setPincodeLoading] = useState<boolean>(false);
  const [validCode, setValidCode] = useState<boolean>(true);
  const [validPinCode, setValidPinCode] = useState<boolean>(true);
 

  const Schema = Yup.object({
    first_name: Yup.string()
      .min(2, "Please enter valid first name")
      .required("Please enter your first name"),
    last_name: Yup.string()
      .min(2, "Please enter valid last name")
      .required("Please enter your last name"),
    primary_phone: Yup.string()
      .min(10, "Please enter valid phone number")
      .required("Please enter your phone number"),
     /*  invite_code: Yup.string()
    .required("Please enter valid invite code"), */
    date: Yup.number().min(1).max(31).required(),
    month: Yup.number().min(1).max(12).required(),
    year: Yup.number().min(1900).max(MAX_YEAR).required(),
    pincode: Yup.string().when("city", (val, schema) => {
      if (val?.length === 0 || val?.[0] === undefined) {
        return Yup.string()
          .min(6, "Please enter valid pincode")
          .required("Please enter your pincode");
      } else {
        return Yup.string().notRequired();
      }
    }),
    city: Yup.string().required("Please enter your city"),
    area: Yup.string().required("Please enter your area"),
  });

 

  /* const validateInviteCode  = async (values: UserProfile) => {
    console.log("validating invite code");
    const response = await getInviteCode(values.invite_code);
    console.log("the response is " + response);
    if(response){
      console.log("continuing onPressNext with the values" + values);
      onPressNext(values);
    } else {

      setValidCode(false);
    }
  } */

  const onSubmit = useCallback(
    (values: UserProfile) => {
      onPressNext(values);
    },
    [onPressNext]
  );

  const formik  =
    useFormik({
      initialValues: {
        area: "",
        city: "",
        date: "",
        first_name: "",
        last_name: "",
        month: "",
        primary_phone: "",
        year: "",
        pincode: "",
        //invite_code: localStorage.getItem("utm_medium"),
        utm_source: localStorage.getItem("utm_source"),
        utm_medium: localStorage.getItem("utm_medium"),
        utm_campaign: localStorage.getItem("utm_campaign"),
      } as UserProfile,
      validationSchema: Schema,
      onSubmit: onSubmit,
    });
    const { values, errors, handleChange, handleSubmit, setFieldValue, touched, setFieldTouched } = formik;

  useEffect(() => {
    if (userData?.data?.basic_details) {
      setFieldValue("first_name", userData?.data?.basic_details?.first_name);
      setFieldValue("last_name", userData?.data?.basic_details?.last_name);
      setFieldValue("city", userData?.data?.basic_details?.city);
      setFieldValue("area", userData?.data?.basic_details?.area);
      setFieldValue(
        "primary_phone",
        userData?.data?.basic_details?.primary_phone?.number
      );
      const dob = userData?.data?.basic_details?.date_of_birth?.split("-");
      setFieldValue("date", dob?.[0] ?? "");
      setFieldValue("month", dob?.[1] ?? "");
      setFieldValue("year", dob?.[2] ?? "");
      
    }
  }, [userData, setFieldValue, ]);

  const setDateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("date", e.target.value);
    },
    [setFieldValue]
  );

  const setMonthValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 2) setFieldValue("month", e.target.value);
    },
    [setFieldValue]
  );

  const setYearValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 4) setFieldValue("year", e.target.value);
    },
    [setFieldValue]
  );

  const setPincodeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 6) setFieldValue("pincode", e.target.value);

      setFieldValue("city", "");
      setFieldValue("area", "");
    },
    [setFieldValue]
  );

  const onChangeArea = useCallback(
    (e: SelectChangeEvent<string>) => {
      setFieldValue("area", e.target.value);
    },
    [setFieldValue]
  );

  const getAreaData = useCallback(async () => {
    try {
      setValidPinCode(true);
      setFieldValue("city", "");
      setFieldValue("area", "");
      setPincodeLoading(true)
      const areaData = await getPincodeData(values.pincode);
      if (areaData.length == 0){
        setValidPinCode(false);
      } else{
        setFieldValue("city", areaData[0].PostOffice[0].District.replace('Region', ''));
        setAreas(areaData[0].PostOffice);
      }
      setPincodeLoading(false)
    } catch (e) { 
      setPincodeLoading(false)
    }
  }, [values.pincode, setFieldValue]);

  useEffect(() => {
    if (values.pincode.length === 6) {
      getAreaData();
    } 
  }, [userData, values.pincode, getAreaData, setFieldValue]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent title="Signup" />
        
        {
          validCode? null : <span style={{ color: 'red', fontWeight: 'bold', marginTop:'16px'}}>Invalid invitecode.</span>
         
        }
        {
           validPinCode? null : <span style={{ color: 'red', fontWeight: 'bold', marginTop:'16px'}}>We are not servicing your area yet.</span>
        } 
        <Grid container spacing={2} marginTop={"8px"}>
        <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="First Name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.first_name}
              error={Boolean(touched.first_name && errors.first_name)}
              onChange={handleChange("first_name")}
            />
            {touched.first_name && errors.first_name && (
              <FormHelperText>{errors.first_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="Last Name"
              variant="outlined"
              type="text"
              fullWidth
              value={values.last_name}
              error={Boolean(touched.last_name && errors.last_name)}
              onChange={handleChange("last_name")}
            />
            {touched.last_name && errors.last_name && (
              <FormHelperText>{errors.last_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Mobile Number"
              variant="outlined"
              type="tel"
              fullWidth
              inputProps={{ maxLength: 10 }}
              value={values.primary_phone}
              error={Boolean(touched.primary_phone && errors.primary_phone)}
              onChange={handleChange("primary_phone")}
            />
            {touched.primary_phone && errors.primary_phone && (
              <FormHelperText>{errors.primary_phone}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              placeholder="DD"
              variant="outlined"
              type="number"
              inputProps={{ maxLength: 2 }}
              fullWidth
              value={values.date}
              error={Boolean(touched.date && errors.date)}
              onChange={setDateValue}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              placeholder="MM"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 2 }}
              value={values.month}
              error={Boolean(touched.month && errors.month)}
              onChange={setMonthValue}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              placeholder="YYYY"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 4 }}
              value={values.year}
              error={Boolean(touched.year && errors.year)}
              onChange={setYearValue}
            />
          </Grid>
          {touched.date &&
            touched.month &&
            touched.year &&
            (errors.date || errors.month || errors.year) && (
              <Grid item xs={12}>
                <FormHelperText>Please enter a valid date</FormHelperText>
              </Grid>
            )}
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Pincode"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ maxLength: 10 }}
              value={values.pincode}
              error={Boolean(touched.pincode && errors.pincode)}
              onChange={setPincodeValue}
            />
            {touched.pincode && errors.pincode && (
              <FormHelperText>{errors.pincode}</FormHelperText>
            )}
          </Grid>
          {values.city && (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="City"
                variant="outlined"
                type="text"
                fullWidth
                inputProps={{ maxLength: 10 }}
                value={values.city}
                error={Boolean(touched.city && errors.city)}
                onChange={handleChange("city")}
                disabled
              />
            </Grid>
          )}
          {areas.length > 0 ? (
            <Grid item xs={12}>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={values.area}
                onChange={onChangeArea}
              >
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      color: "#CCCCCC",
                    }}
                  >
                    Area
                  </Typography>
                </MenuItem>
                {areas.map((item) => {
                  return (
                    <MenuItem key={item.Name} value={item.Name}>
                      {item.Name}
                    </MenuItem>
                  );
                })}
              </Select>

              {touched.area && errors.area && (
                <FormHelperText>{errors.area}</FormHelperText>
              )}
            </Grid>
          ) : values.area ? (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                placeholder="Area"
                variant="outlined"
                type="text"
                fullWidth
                inputProps={{ maxLength: 10 }}
                value={values.area}
                error={Boolean(touched.area && errors.area)}
                onChange={handleChange("area")}
                disabled
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleSubmit()}
            >
              Sign up
            </Button>
          </Grid>
        </Grid>
        
        <Loading isLoading={pincodeLoading} />
      </Box>
    </>
  );
};
export default UserProfileLayout;
