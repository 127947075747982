import { Box, Checkbox, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { OptionItem } from "../../../types/signup";

interface GenderItemProps {
  item: OptionItem;
  values: string[];
  handleChange: (value: string) => void;
}

const CheckBoxItem = ({ item, values, handleChange }: GenderItemProps) => {
  const onClickRadio = useCallback(() => {
    if (values.includes(item.value)) {
      const newValues = values.filter((ele) => ele !== item.value && ele !=='');
      handleChange(newValues.join(","));
    } else {
      const newValues = [...values];
      const filteredValues =  newValues.filter((ele) => ele !=='');
      filteredValues.push(item.value);
      handleChange(filteredValues.join(","));
    }
  }, [item, handleChange, values]);

  const isChecked = useMemo(() => values.includes(item.value), [item, values]);

  return (
    <Box
      display={"flex"}
      padding={"4px 12px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={"16px"}
      marginBottom={"16px"}
      onClick={onClickRadio}
      sx={{
        background: "#FFFFFF",
        //boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: "1px solid  #FF5A5F"
      }}
      borderRadius={"8px"}
    >
      <Typography fontWeight={"500"} fontSize={"16px"}>
        {item.text}
      </Typography>
      <Checkbox checked={isChecked} onClick={onClickRadio} />
    </Box>
  );
};
export default CheckBoxItem;
