import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
  Radio,
} from "@mui/material";
import React, { useState } from "react";
import Seperator from "../../../assets/images/seperator";
import { UserDate } from "../../../types/date";
import { updateDateState } from "../../../service/date";

interface DateDeclinedLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate;
  onButtonClick: (status: string, reason: string) => void;
}

const DateDeclinedLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick,
}: DateDeclinedLayoutProps) => {
  const [openCompatibility, setOpenCompatibility] = useState(false);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const [openDateDetails, setOpenDateDetails] = useState(false);

  const handleDateDetailsToggle = () => {
    setOpenDateDetails(!openDateDetails);
  };

  const openGoogleMaps = (url: string) => {
    window.open(url, "_blank");
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"16px"}
      >
        <Card
          sx={{
            marginTop: "0px",
            padding: "16px 16px",
            background: "#FFFFFF",
          }}
        >
          <Typography color={"#000"} fontSize={16}>
            Help us improve by telling us why you declined this date
          </Typography>

          <Grid container spacing={2} marginTop={"16px"}>
            <ul>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="DATE"
                    checked={selectedOption === "DATE"}
                    onChange={handleOptionChange}
                  />
                  <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    I am not available on that day.
                  </Typography>
                </label>
              </li>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="TIME"
                    checked={
                      selectedOption === "TIME"
                    }
                    onChange={handleOptionChange}
                  />
                   <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    The time is not suitable.
                  </Typography>
                </label>
              </li>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="PRICE"
                    checked={selectedOption === "PRICE"}
                    onChange={handleOptionChange}
                  />
                  <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    Service fees is high.
                  </Typography>
                </label>
              </li>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="LOCATION"
                    checked={selectedOption === "LOCATION"}
                    onChange={handleOptionChange}
                  />
                   <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    Location is far from my place.
                  </Typography>
                </label>
              </li>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="LOOKS"
                    checked={selectedOption === "LOOKS"}
                    onChange={handleOptionChange}
                  />
                  <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    I don't find my match attractive.
                  </Typography>
                </label>
              </li>
              <li style={{"listStyleType":"none", "marginBottom":"10px"}}>
                <label style={{"display":"flex"}}>
                  <input
                    type="radio"
                    value="COMPATIBILITY"
                    checked={selectedOption === "COMPATIBILITY"}
                    onChange={handleOptionChange}
                  />
                  <Typography color={"#000"} fontSize={16} marginLeft={"10px"} style={{"fontWeight":"400"}}>
                    We are not compatible.
                  </Typography>
                </label>
              </li>
            </ul>
          </Grid>

          <Grid container spacing={2} marginTop={"16px"}>
            <Grid container spacing={2} marginTop={"0px"} marginLeft={"4px"}>
              <Grid item xs={6} style={{ paddingTop: "8px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    console.log("selectedOption", selectedOption);
                    onButtonClick("decline",selectedOption);
                  }}
                >
                  {" "}
                  Sumbit
                </Button>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: "8px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => onButtonClick("cancel","")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default DateDeclinedLayout;
