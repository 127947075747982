import React from "react";
import useUser from "../context/UserContext";
import { Navigate, Outlet } from "react-router-dom";
import RouterPath from "./RouterPath";

const PrivateRoutes = () => {

  //get the query string here. "order"
  const queryString = window.location.search;
  console.log("queryString" + queryString);
  const urlParams = new URLSearchParams(queryString);
  console.log("urlParams" + urlParams);
  const paramExists = urlParams.has("pgdetails") && urlParams.has("orderId"); 
  if (paramExists) {

    const orderId = urlParams.get("orderId");
    console.log("paramExists" + paramExists);
    localStorage.setItem("pgdetails", urlParams.get("pgdetails")!);
    localStorage.setItem("pgorderId", orderId!);
  } 
  const { userId } = useUser();
  return <>{userId ?  <Outlet /> : <Navigate to={RouterPath.Home} />}</>;


};
export default PrivateRoutes;
