import { Fab } from "@mui/material";
import React from "react";
import Icons from "../../assets/icons";

interface NextButtonProps {
  onPressNext: () => void;
}

const NextButton = ({ onPressNext }: NextButtonProps) => {
  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: { xs: "32px", md: "64px" },
        right: { xs: "32px", md: "64px" },
      }}
      onClick={onPressNext}
    >
      <img src={Icons.Next} width={"100%"} height={"100%"} alt="Go" />
    </Fab>
  );
};
export default NextButton;
