import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ErrorData, ResponseData } from "../types/signup/User";
import { InterestTypes } from "../types/signup/interests";

export const getInterests = async () => {
  try {
    const data = await getAxiosClient(false).get(HttpService.INTERESTS);
    return data.data as ResponseData<InterestTypes[]>;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
};
