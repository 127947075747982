import React, { useState, useEffect,useRef } from 'react';
import './BottomSheet.css'; // You can style your bottom sheet in this CSS file

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isOpen, onClose, children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(isOpen);
  const bottomSheetRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    setIsVisible(isOpen);
    const handleOutsideClick = (event: MouseEvent) => {
      if (bottomSheetRef.current && !bottomSheetRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible,isOpen]);


  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <>
     {isVisible && (
        <div className={`bottom-sheet-overlay`}>
          <div className={`bottom-sheet-content ${isVisible ? 'slide-up' : ''}`}>
            <div className="bottom-sheet-header">
              <button onClick={handleClose}>Close</button>
            </div>
            <div className="bottom-sheet-body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default BottomSheet;
