// mobile.component.jsx
import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LogoComponent from "./LogoComponent";
import LogoComponentHeader from "./LogoComponentHeader";
import { Button, Modal, Form } from "react-bootstrap";
import "./laptop.styles.scss";
import { Instagram, Twitter } from "react-bootstrap-icons";
import RouterPath from "../../../route/RouterPath";
import { useNavigate } from "react-router-dom";
import EnterEmailLayout from "../../Signup/EmailLayout";
import { ErrorData } from "../../../types/signup/User";

interface SignupProps {
  onSubmitEmail: (value: string) => void;
  sendOTPError?: ErrorData;
}

export const Laptop = ({ onSubmitEmail, sendOTPError }: SignupProps) => {
  const sectionOneRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const scrollToSection = () => {
    if (sectionOneRef)
      sectionOneRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  //sign up complete modal settings
  const [showSignUpCompleteModal, setShowSignUpCompleteModal] = useState(false);
  const handleSignupCompleteCloseModal = () => {
    setShowSignUpCompleteModal(false);
  };
  const handleShowSignUpCompleteModal = () => {
    setShowSignUpCompleteModal(true);
  };

  //terms & conditions modal settings
  const [showTermsModal, setShowTermsModal] = useState(false);
  const handleTermsCloseModal = () => {
    setShowTermsModal(false);
  };
  const handleShowTermsModal = () => {
    setShowTermsModal(true);
  };

  //cancellation modal settings
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const handleCancellationCloseModal = () => {
    setShowCancellationModal(false);
  };
  const handleShowCancellationModal = () => {
    setShowCancellationModal(true);
  };

  //contact modal settings
  const [showContactModal, setShowContactModal] = useState(false);
  const handleContactCloseModal = () => {
    setShowContactModal(false);
  };
  const handleShowContactModal = () => {
    setShowContactModal(true);
  };

  //faq modal settings
  const [showFaqModal, setShowFaqModal] = useState(false);
  const handleFaqCloseModal = () => {
    setShowFaqModal(false);
  };
  const handleShowFaqModal = () => {
    setShowFaqModal(true);
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    city: "",
    dob: "",
    relationType: "",
  });
  const handleSignupCloseModal = () => {
    setShowSignUpModal(false);
  };
  const handleShowSignUpModal = () => {
    setShowSignUpModal(true);
    navigate(RouterPath.Signup);
  };

  const handleSignupChange = (e: any) => {
    const { name, value } = e.target;
    setSignUpFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignupSubmit = (e: any) => {
    e.preventDefault();

    const requestData = {
      // Data to be sent in the request payload
      // Modify this as per your API requirements
      user_first_name: "John",
      user_last_name: "Doe",
      user_dob: "1990-01-01",
      user_gender: "M",
      user_phone_number: "9008992736",
    };

    fetch("https://110e-49-206-131-236.ngrok-free.app/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        handleSignupCloseModal();
        handleShowSignUpCompleteModal();
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        //alert("error");
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="laptop">
        <div className="background-container">
          <LogoComponent /> {/* Include the LogoComponent here */}
          <div className="fullscreen-section-laptop" id="signup">
            <div className="centered-container">
              <div className="overlay-laptop">
                <div className="textContainer-laptop">
                  <h1>Meet genuine people over coffee!</h1>
                  <h3 className="h3-hero">We find the best match for you!</h3>
                </div>
                <EnterEmailLayout
                  onPressNext={onSubmitEmail}
                  sendOTPError={sendOTPError}
                />
              </div>
            </div>
          </div>
          <div className="content">
          <div className="why-twist" id="why-twist" ref={sectionOneRef}>
              <div className="twist-explaination-container">
                <h2 className="twist-feature-heading">How it Works?</h2>
                <p>
                  Twist is built on a belief that great interactions happen when two interesting people meet in real life. 
                </p>
                <p>
                We believe that swiping right or left will not give you anything more than artifical coversations and relationships. Genuine conversations happen in the real world.  
                </p>

                <p>
                After you create an account, we find like minded people and set you up on a coffee date with them.  
                </p>
                <a
                  className="btn btn-custom btn-lg rounded"
                  href="#signup"
                  color="white"
                >
                  Create account
                </a>
              </div>
            </div>
            <div className="footer">
              <div className="footer-content">
                <ul>
                  <li className="footer-link-list">
                    <a href="#" className="col-sm-3 col-xs-3 col-md-3">
                      <Instagram size={32} color={"#006f62"} />
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" className="col-md-6">
                      <Twitter size={32} color={"#006f62"} />
                    </a>
                  </li>
                </ul>

                <ul className="footer-links">
                  <li className="footer-link-list">
                   <a href="#" onClick={handleShowContactModal}>
                      Contact Us
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowTermsModal}>
                      Terms
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowFaqModal}>
                      FAQs
                    </a>
                  </li>
                  {/* Add more links as needed */}
                </ul>
              </div>
            </div>
          </div>
          <div className="modals" >
            <div className="terms-modal">
              <Modal
                show={showTermsModal}
                onHide={handleTermsCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            Terms
                          </h1>
                          <p className="twist-signup-complete-description">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="cancellation-modal">
              <Modal
                show={showCancellationModal}
                onHide={handleCancellationCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. Lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSignupSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="contact-modal">
              <Modal
                show={showContactModal}
                onHide={handleContactCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            Contact us
                          </h1>
                          <p className="twist-signup-complete-description">
                          In case of any queries or concerns please reach out to contact@thetwistapp.com
                          </p>
                          
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="faq-modal">
              <Modal
                show={showFaqModal}
                onHide={handleFaqCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            FAQs
                          </h1>
                          <p className="twist-signup-complete-description">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSignupSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
