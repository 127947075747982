import HttpService from "../http-client/services";
import { PincodeData } from "../types/signup/pincode";
import getAxiosClient from "../http-client/httpClient";

export const getPincodeData = async (pincode: string) => {
  try {
   const result = await getAxiosClient(false).get(HttpService.PIN_CODE+pincode);
    console.log(result.data);
    return result.data as PincodeData[]; 
 
  } catch (e) {
    throw e;
  }
};
