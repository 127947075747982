import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import React, { useCallback, useRef, useState, useEffect, useMemo } from "react";
import useUser from "../../../context/UserContext";
import PhotosItem from "./PhotosItem";
import { deletePhoto, uploadPhoto } from "../../../service/user";
import Loading from "../../../components/Loading";
import heic2any from "heic2any";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";

interface PhotosLayoutProps {
  onPressNext: (files: string[]) => void;
}

const MAX_PHOTOS = 6;

const PhotosLayout = ({ onPressNext }: PhotosLayoutProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { userData, userId } = useUser();

  const [photos, setPhotos] = useState<string[]>([]); //Initially this would be the photos already saved.
  const [error, setError] = useState<string | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const onClickAddImage = useCallback(() => {
    if (inputRef.current) inputRef.current.value = "";

    inputRef.current?.click();
  }, []);


  useEffect(() => {
    if (userData && userData.data && Array.isArray(userData.data.photos)) {
      // Extract URLs from userData
      const urls = userData.data.photos.map((photo: { url: string }) => photo.url);
      // Set the photos state with the URLs
      setPhotos(urls);
    }
  }, [userData]);
 

  const onSelectFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const data = [...photos]; //create a copy of the bucket
      if (event.target.files?.length && userId) {
        try {
          let file: File = event.target.files[0];
          setShowLoading(true);

          const fileBlocks = file.name.split(".");
          const fileExtension = fileBlocks[fileBlocks.length - 1];
          if (
            event.target.files[0].type === "image/heic" ||
            fileExtension === "heic"
          ) {
            const blob = (await heic2any({
              blob: file,
              toType: "image/jpeg",
            })) as Blob;
            fileBlocks[fileBlocks.length - 1] = "jpeg";
            const fileName = fileBlocks.join(".");
            file = new File([blob], fileName, { type: "image/jpeg" });
          }

          const result = await uploadPhoto({
            file: file,
            user: userId,
          });
          data.push(result.data.url);
          setPhotos(data); 
          setShowLoading(false);
        } catch (e) {
          // TODO
          setShowLoading(false);
        }
      }
    },
    [photos, userId]
  );

  const photosLength = useMemo(() => {
    return userData?.data?.photos?.length ?? 0; //this should always give the photos of one bucket.
  }, [userData]);

  const onSubmit = useCallback(() => {
    const totalLength = photosLength + photos.length; //this would be the length of the bucket.
    if (totalLength < 6) {
      setError("Please upload 6 photos");
    } else {
      setError(null);
      onPressNext(photos);
    }
  }, [photos, onPressNext, photosLength]);

  const onClickDelete = useCallback(
    async (index: number) => {
      if (userId)
        try {
          await deletePhoto({ user: userId, url: photos[index] }); //this will remove the url which is deleted form the bucket
          const newPhotos = [...photos];
          newPhotos.splice(index,1);
          setPhotos(newPhotos)
        } catch (e) {}
    },
    [photos, userId], 
  );


  

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"32px"}
      >
        <TitleComponent
          title="Upload your 6 different best photos"
          subtitle="Make a great first impression! Upload your best photos, facing the camera, with a nice background. Avoid selfies, pictures in the bathroom and nudity."
        
        />
        <Box marginTop={"32px"}>
          <Grid container spacing={2}>
            {Array(MAX_PHOTOS)
              .fill(0)
              .map((_, index) => {
                return (
                  <PhotosItem
                    index={index}
                    key={index.toString()}
                    onClickAddImage={onClickAddImage}
                    photos={photos}
                    onClickDelete={onClickDelete}
                  />
                );
              })}
          </Grid>
        </Box>

        <Typography fontSize={"12px"} fontStyle={"normal"} marginTop={"16px"}>
          {`${photos.length}/${MAX_PHOTOS} photos selected`}
        </Typography>
        {error && <FormHelperText error={true}>{error}</FormHelperText>}
      </Box>

      <input
        type="file"
        hidden
        accept="image/*"
        ref={inputRef}
        onChange={onSelectFile}
      />
      <NextButton onPressNext={onSubmit} />
      <Loading isLoading={showLoading} />
    </>
  );
};
export default PhotosLayout;
