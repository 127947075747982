import DeleteAccountLayout from "../../layouts/DeleteAccountLayout";
import React, { useEffect } from 'react';



const DeleteAccount = () => {
   
    return (
        <DeleteAccountLayout/>
    );
};

export default DeleteAccount;