import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
  Card,
  Collapse,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getPincodeData } from "../../../service/pincode";
import { PostOffice } from "../../../types/signup/pincode";
import useUser from "../../../context/UserContext";
import TitleComponent from "../../../components/TitleComponent";
import Loading from "../../../components/Loading";
import { getInviteCode } from "../../../service/invitecode";
import { Label } from "@mui/icons-material";
import { UserProfile } from "../../../types/signup/profile";
import NextButton from "../../../components/NextButton";
import AudioRecorder from "../../../components/AudioRecorder";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import { pushAnalyticsEvent } from "../../../service/analytics";
import { EVENT_TYPE } from "../../../types/event";
import Icons from "../../../assets/icons";

interface MicrophoneAccessLayoutProps {
  onPressNext: () => void;
}

const MicrophoneAccessLayout = ({
  onPressNext,
}: MicrophoneAccessLayoutProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCompatibility, setOpenCompatibility] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { userData } = useUser();
  const [audioFile, setaudioFile] = useState<Blob>();
  const [vocalLanguage, setVocalLanguage] = useState<string>("EN");
  const [permissionError, setPermissionError] = useState(false);
  const { userId } = useUser();

  const onSubmit = useCallback(() => {
    onPressNext();
  }, [audioFile, onPressNext]);

  useEffect(() => {
    if (userData && userData?.data?.preferred_vocal_language) {
      setVocalLanguage(userData?.data?.preferred_vocal_language);
    } else {
      setVocalLanguage("KN");
    }
  }, [userData]);

  const onSend = useCallback(async (file: Blob) => {
    setaudioFile(file);
    setShowNextButton(true);
  }, []);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const handleShowingNextButton = () => {
    setShowNextButton(true);
  };

  // Function to request microphone permission
  const requestMicrophonePermission = async () => {
    try {
      const permissionStatus = await (navigator.permissions as any).query({
        name: "microphone" as PermissionName,
      });

      if (permissionStatus.state === "granted") {
        onPressNext();
      } else if (permissionStatus.state === "prompt") {
        
        await navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((stream) => {
            if (permissionStatus.state === "granted") {
              onPressNext();
            }
            if (permissionStatus.state === "denied") {
              setPermissionError(true);
              console.error("Microphone permission denied");
            }
          })
          // If there is an error, we can't get access to the mic
          .catch((err) => {
            throw new Error("Unexpected error getting microphone access");
          });
      } else if (permissionStatus.state === "denied") {
        setPermissionError(true);
        console.error("Microphone permission denied");
      }

      permissionStatus.onchange = () => {
        if (permissionStatus.state === "granted") {
          onPressNext();
        } 
      };
    } catch (error: any) {
      console.error("Error requesting microphone permission:", error);
      pushAnalyticsEvent(
        userId!,
        EVENT_TYPE.ERROR,
        "",
        "",
        error.name,
        error.message,
        error.stack,
        {}
      );
      setPermissionError(true);
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: "100%",
        height: "100vh",
      }}
      display={"flex"}
      flexDirection={"column"}
      width={{ xs: "100%", md: "450px" }}
      marginTop={"16px"}
    >
      <Card
        sx={{
          marginTop: "0px",
          padding: "12px 12px 12px 12px !important",
          paddingBottom: "200px !important",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              paddingLeft: "0px",
              paddingTop: "0px",
            }}
          >
            {!permissionError && <TitleComponent
              title="Allow microphone access"
              subtitle="Give microphone permission to record audio introduction in next step."
            />}
            {permissionError && (
              <p>
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize:"1.4em",
                    marginBottom: "20px",
                  }}
                >
                  Error accessing microphone. Please grant permission to record
                  audio.
                </span>
                <br /><br/>
                <span style={{marginBottom:'20px'}}>Follow these steps:</span><br /><br/>
                <ul style={{listStyleType:'none'}}>
                  <li style={{marginBottom:'20px'}}>
                    1. Click on the{" "}
                    <img
                      src={Icons.Settings}
                      style={{ width: "40px", height: "40px" }}
                    />{" "}
                    or{" "}
                    <img
                      src={Icons.Info}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                    />
                    icon in the address bar of your browser.
                  </li>
                  <li>2. Find 'Microphone' and set it to 'Allow'</li>
                </ul>
              </p>
            )}
           { !permissionError && <Button
              variant="contained"
              fullWidth
              onClick={requestMicrophonePermission}
              style={{ marginTop: "20px" }}
            >
              <MicIcon /> Allow microphone access
            </Button>}
          </Grid>
          {showNextButton && <NextButton onPressNext={onSubmit} />}
        </Grid>
      </Card>
    </Box>
  );
};
export default MicrophoneAccessLayout;
