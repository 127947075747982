import { Box, Typography } from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import InterestItem from "./InterestItem";
import { InterestTypes } from "../../../types/signup/interests";
import useUser from "../../../context/UserContext";
import NextButton from "../../../components/NextButton";

interface InterestLayoutProps {
  onPressNext: (onPressNext: number[]) => void;
  interestTypes?: InterestTypes[];
}

const MAX_INTERESTS = 5;

const InterestLayout = ({
  onPressNext,
  interestTypes = [],
}: InterestLayoutProps) => {
  const { userData } = useUser();

  const [interests, setInterests] = useState<number[]>([]);

  useEffect(() => {
    if (userData && userData?.data?.interest_ids) {
      setInterests(userData?.data?.interest_ids);
    }
  }, [userData]);

  const onSubmit = useCallback(() => {
    onPressNext(interests);
  }, [interests, onPressNext]);

  const onSelect = useCallback(
    (value: number) => {
      const values = [...interests];
      if (values.includes(value)) {
        const newValues = values.filter((item) => item !== value);
        setInterests(newValues);
      } else if (interests.length < MAX_INTERESTS) {
        const newValues = [...values];
        newValues.push(value);
        setInterests(newValues);
      }
    },
    [interests]
  );

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        paddingBottom={"100px"}
        marginTop={"32px"}
      >
        <Typography fontWeight={"700"} fontSize={"24px"}>
           Select upto 5 interests
        </Typography>
        <Typography fontSize={"16px"} marginTop={"8px"}>
          We will find you matches based on your interests 
        </Typography>
        <Box marginTop={"16px"} height={"60vh"} overflow={"scroll"}
        >
          {interestTypes.map((item) => {
            return (
              <Box key={item.category_name}>
                <InterestItem
                  data={item}
                  values={interests}
                  onSelect={onSelect}
                />
              </Box>
            );
          })}
        </Box>

        <Typography fontSize={"16px"} fontStyle={"normal"}>
          {`${interests.length}/${MAX_INTERESTS} interests selected`}
        </Typography>
      </Box>

      <NextButton onPressNext={onSubmit} />
    </>
  );
};
export default InterestLayout;
