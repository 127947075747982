import { string } from "yup";

export enum EVENT_TYPE{
  ERROR = "ERROR",
  ACTION = "ACTION"
}

export enum EVENT_ACTION {
  SKIP_PAGE_ABOUT_ME = "SKIP_PAGE_ABOUT_ME",
  SEEN_THE_DATE_INVITE = "SEEN_THE_DATE_INVITE",
  CONSIDERED_ACCEPTING_THE_DATE = "CONSIDERED_ACCEPTING_THE_DATE",
  CONSIDERED_REJECTING_THE_DATE = "CONSIDERED_REJECTING_THE_DATE",
  CONSIDERED_PAYMENT_AFTER_CHECKING_DATE_INVITE = "CONSIDERED_PAYMENT_AFTER_CHECKING_DATE_INVITE"
}

