import { Box, Radio, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { OptionItem } from "../../../types/signup";

interface RadioItemProps {
  item: OptionItem;
  value: string;
  handleChange: (value: string) => void;
}

const RadioItem = ({ item, value, handleChange }: RadioItemProps) => {

  const onClickRadio = useCallback(() => {
    handleChange(item.value);
  }, [item,handleChange]);
  

  return (
    <Box
      display={"flex"}
      padding={"4px 12px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={"16px"}
      marginBottom={"16px"}
      sx={{
        background: "#FFFFFF",
        //boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: "1px solid  #FF5A5F"
      }}
      borderRadius={"8px"}
      onClick={onClickRadio}
    >
      <Typography fontWeight={'500'} fontSize={'16px'}>{item.text}</Typography>
      <Radio checked={item.value === value} onClick={onClickRadio} />
    </Box>
  );
};
export default RadioItem;
