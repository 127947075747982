// mobile.component.jsx
import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LogoComponent from "./LogoComponent";
import LogoComponentHeader from "./LogoComponentHeader";
import { FaQuoteRight } from "react-icons/fa"; // Import the quote icon from react-icons
import { Button, Carousel, Modal, Form } from "react-bootstrap";
import "./tablet-mobile.styles.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Instagram, Twitter } from "react-bootstrap-icons";
import RouterPath from "../../../route/RouterPath";
import EnterEmailLayout from "../../Signup/EmailLayout";
import { ErrorData } from "../../../types/signup/User";

interface SignupProps {
  onSubmitEmail: (value: string) => void;
  sendOTPError?: ErrorData;
}
export const TabletMobile = ({ onSubmitEmail, sendOTPError }: SignupProps) => {
  const sectionOneRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const scrollToSection = () => {
    if (sectionOneRef)
      sectionOneRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  //sign up complete modal settings
  const [showSignUpCompleteModal, setShowSignUpCompleteModal] = useState(false);
  const handleSignupCompleteCloseModal = () => {
    setShowSignUpCompleteModal(false);
  };
  const handleShowSignUpCompleteModal = () => {
    setShowSignUpCompleteModal(true);
  };

  //terms & conditions modal settings
  const [showTermsModal, setShowTermsModal] = useState(false);
  const handleTermsCloseModal = () => {
    setShowTermsModal(false);
  };
  const handleShowTermsModal = () => {
    setShowTermsModal(true);
  };

  //cancellation modal settings
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const handleCancellationCloseModal = () => {
    setShowCancellationModal(false);
  };
  const handleShowCancellationModal = () => {
    setShowCancellationModal(true);
  };

  //contact modal settings
  const [showContactModal, setShowContactModal] = useState(false);
  const handleContactCloseModal = () => {
    setShowCancellationModal(false);
  };
  const handleShowContactModal = () => {
    setShowContactModal(true);
  };

  //faq modal settings
  const [showFaqModal, setShowFaqModal] = useState(false);
  const handleFaqCloseModal = () => {
    setShowFaqModal(false);
  };
  const handleShowFaqModal = () => {
    setShowFaqModal(true);
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    city: "",
    dob: "",
    relationType: "",
  });
  const handleSignupCloseModal = () => {
    setShowSignUpModal(false);
  };
  const handleShowSignUpModal = () => {
    // setShowSignUpModal(true);

    navigate(RouterPath.Signup);
  };

  const handleSignupChange = (e: any) => {
    const { name, value } = e.target;
    setSignUpFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignupSubmit = (e: any) => {
    e.preventDefault();

    const requestData = {
      // Data to be sent in the request payload
      // Modify this as per your API requirements
      user_first_name: "John",
      user_last_name: "Doe",
      user_dob: "1990-01-01",
      user_gender: "M",
      user_phone_number: "9008992736",
    };

    fetch("https://110e-49-206-131-236.ngrok-free.app/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        handleSignupCloseModal();
        handleShowSignUpCompleteModal();
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        //alert("error");
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="tablet">
        <div className="background-container">
          <LogoComponent /> {/* Include the LogoComponent here */}
          <div className="fullscreen-section-laptop" id="signup">
            <div className="centered-container">
              <div className="overlay-laptop">
                <div className="textContainer-laptop">
                  <h1>Meet you over coffee!</h1>
                  <h3 className="h3-hero">We find the best match for you!</h3>
                </div>
                <EnterEmailLayout
                  onPressNext={onSubmitEmail}
                  sendOTPError={sendOTPError}
                />
              </div>
            </div>
          </div>
          <div className="content">
            {/* <div className="twist-testimonials">
                <div className="testimonial-carousel">
                  <Carousel>
                    <Carousel.Item>
                      <div className="testimonial-item">
                        <p className="testimonial-people-name">Joice & Shoan</p>
                        <p className="testimonial-text">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                        <div className="quote-icon">
                          <FaQuoteRight />
                        </div>
                      </div>
                    </Carousel.Item>

                    <Carousel.Item>
                      <div className="testimonial-item">
                        <p className="testimonial-people-name">Joice & Shoan</p>
                        <p className="testimonial-text">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                        <div className="quote-icon">
                          <FaQuoteRight />
                        </div>
                      </div>
                    </Carousel.Item>

                    <Carousel.Item>
                      <div className="testimonial-item">
                        <p className="testimonial-people-name">Joice & Shoan</p>
                        <p className="testimonial-text">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                        <div className="quote-icon">
                          <FaQuoteRight />
                        </div>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div> */}
            <div className="twist-features">
              <div className="feature-section">
                <h2 className="twist-feature-heading">Meet & Talk.</h2>
                <img className="feature1-img"></img>
                <h3 className="twist-feature-description">
                  Twist is a different dating site. We prefer to talk rather
                  than swiping. You talk to your match in a public place.
                </h3>
                <a
                  className="btn btn-custom btn-lg rounded"
                  onClick={scrollToSection}
                  href="#why-twist"
                >
                  Learn more
                </a>
                {/* Content for Subsection 2 of Section 1 */}
              </div>
              <div className="feature-section">
                <h2 className="twist-feature-heading">
                  Meet at Public Places!
                </h2>
                <img className="feature2-img"></img>
                <h3 className="twist-feature-description">
                  The date happens at cafes. Cafes give you a cosy environment
                  to have meaningful conversations!
                </h3>
                <a
                  className="btn btn-custom btn-lg rounded"
                  onClick={scrollToSection}
                  href="#why-twist"
                >
                  Learn more
                </a>

                {/* Content for Subsection 2 of Section 1 */}
              </div>
              <div className="feature-section">
                <h2 className="twist-feature-heading">It's Safe!</h2>
                <img className="feature3-img"></img>
                <h3 className="twist-feature-description">
                  Every member is verified. We take government id proof's at
                  Signup.
                </h3>
                <a
                  className="btn btn-custom btn-lg rounded"
                  onClick={scrollToSection}
                  href="#why-twist"
                >
                  Learn more
                </a>
              </div>
            </div>
            <div className="why-twist" id="why-twist" ref={sectionOneRef}>
              <div className="twist-explaination-container">
                <h2 className="twist-feature-heading">Why Twist?</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make
                  a type specimen book.
                </p>
                <a
                  className="btn btn-custom btn-lg rounded"
                  href="#signup"
                  color="white"
                >
                  Create account
                </a>
              </div>
            </div>
            <div className="footer">
              <div className="footer-content">
                <ul>
                  <li className="footer-link-list">
                    <a href="#" className="col-sm-3 col-xs-3 col-md-3">
                      <Instagram size={32} color={"#006f62"} />
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" className="col-md-6">
                      <Twitter size={32} color={"#006f62"} />
                    </a>
                  </li>
                </ul>

                <ul className="footer-links">
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowContactModal}>
                      Contact Us
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowTermsModal}>
                      Terms
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowCancellationModal}>
                      Cancellation Policy
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowFaqModal}>
                      FAQs
                    </a>
                  </li>
                  {/* Add more links as needed */}
                </ul>
              </div>
            </div>
          </div>
          <div className="modals">
            <div className="login-modal">
              <Modal
                show={showSignUpModal}
                onHide={handleSignupCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                  {/* Include the LogoComponent here */}
                  {/* <Modal.Title>SignUp</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSignupSubmit}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4"></div>

                        <div className="col-md-4">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <Form.Group
                                  controlId="firstName"
                                  className="form-group"
                                >
                                  <Form.Label
                                    className="form-field-name"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "1.6rem",
                                    }}
                                  >
                                    Sign up for free:
                                  </Form.Label>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <Form.Group
                                  controlId="firstName"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    First Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={signUpFormData.firstName}
                                    onChange={handleSignupChange}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-6 col-6">
                                <Form.Group
                                  controlId="lastName"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    Last Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={signUpFormData.lastName}
                                    onChange={handleSignupChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="mobile"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    Mobile
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={signUpFormData.mobile}
                                    onChange={handleSignupChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="mobile"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    Gender
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="relationType"
                                    value={signUpFormData.relationType}
                                    onChange={handleSignupChange}
                                  >
                                    <option value="-1">Select</option>
                                    <option value="men">Men</option>
                                    <option value="women">Women</option>
                                    <option value="non binary people">
                                      Non Binary
                                    </option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="mobile"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    I am open to dating
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="relationType"
                                    value={signUpFormData.relationType}
                                    onChange={handleSignupChange}
                                  >
                                    <option value="-1">Select</option>
                                    <option value="men">Men</option>
                                    <option value="women">Women</option>
                                    <option value="non binary people">
                                      Non Binary People
                                    </option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="dob"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    Date of Birth
                                  </Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="dob"
                                    value={signUpFormData.dob}
                                    onChange={handleSignupChange}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="city"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    City
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="relationType"
                                    value={signUpFormData.relationType}
                                    onChange={handleSignupChange}
                                  >
                                    <option value="-1">Select</option>
                                    <option value="Bangalore">Bangalore</option>
                                    <option value="New Delhi">New Delhi</option>
                                    <option value="Chennai">Chennai</option>
                                    <option value="Mumbai">Mumbai</option>
                                    <option value="Pune">Pune</option>
                                    <option value="Kolkata">Kolkata</option>
                                    <option value="Gurgaon">Gurgaon</option>
                                    <option value="Noida">Noida</option>

                                    <option value="Other">Other</option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 col-12">
                                <Form.Group
                                  controlId="dob"
                                  className="form-group"
                                >
                                  <Form.Label className="form-field-name">
                                    What are you looking for?
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="relationType"
                                    value={signUpFormData.relationType}
                                    onChange={handleSignupChange}
                                  >
                                    <option value="-1">Select</option>

                                    <option value="Long Term Partner">
                                      Long Term Partner
                                    </option>
                                    <option value="Short Term Fun">
                                      Short Term Fun
                                    </option>
                                    <option value="New Friends">
                                      New Friends
                                    </option>
                                    <option value="Still Figuring It Out">
                                      Still Figuring It Out
                                    </option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-custom btn-lg rounded"
                                onClick={handleSignupSubmit}
                              >
                                Register
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
            <div className="signup-complete-modal">
              <Modal
                show={showSignUpCompleteModal}
                onHide={handleSignupCompleteCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      Hi, Thank you for registering with us. We are processing
                      your membership. We will send a message on your given
                      Whatsapp Number to let you know more about your membership
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSignupSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="terms-modal">
              <Modal
                show={showTermsModal}
                onHide={handleTermsCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            Terms
                          </h1>
                          <p className="twist-signup-complete-description">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSignupSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="cancellation-modal">
              <Modal
                show={showCancellationModal}
                onHide={handleCancellationCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. Lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSignupSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="contact-modal">
              <Modal
                show={showContactModal}
                onHide={handleContactCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            Contact us
                          </h1>
                          <p className="twist-signup-complete-description">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSignupSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="faq-modal">
              <Modal
                show={showFaqModal}
                onHide={handleFaqCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <LogoComponentHeader />
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="success-message-container">
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.6rem",
                            }}
                          >
                            FAQs
                          </h1>
                          <p className="twist-signup-complete-description">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. Lorem
                            Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={handleSignupSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
