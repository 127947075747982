import { Activity } from "react-bootstrap-icons";
import getAxiosClient from "../http-client/httpClient";
import HttpService from "../http-client/services";
import { ErrorData, ResponseData, UploadPic, UserData, ActivityData } from "../types/signup/User";
import { TrySharp } from "@mui/icons-material";


interface Order {
  order_id: string;
  payment_session_id: string;
}

// Assuming you have a function where you make the Axios request
export const createPGOrder = async (userId: string, planId: string) => {
  try {
    const response = await getAxiosClient(false).post(`${HttpService.ORDER}/create-order`, {
      user_id: userId,
      plan_id: planId
    });
    console.log(response.data);
    // Handle response
    return response.data  as Order;
  } catch (error: any) {
    console.error('Error:', error);
    throw error.response.data as ErrorData;
  }
};



interface OrderStatus{
  payment_status: string,
  membership_name: string,
  membership_starts_at: string,
  membership_ends_at: string
}

export const getOrderStatus = async (order: string) => {
  try {
    const response = await getAxiosClient(false).get(`${HttpService.ORDER}/${order}/status`);
    return response.data as OrderStatus;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
}


export const getUserSelectedPlanDetails = async (planId: string) => {
  try{
    const response = await getAxiosClient(false).get(`${HttpService.ORDER}/${planId}/plan-details`);
    return response.data as string;
  } catch (e: any) {
    throw e.response.data as ErrorData;
  }
}
