// Post.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/logo";
import LadyImage from "../../assets/images/ladyImage";
import { Border } from "react-bootstrap-icons";
import "./blogmobile.styles.scss";
import HttpService from "../../http-client/services";
import PressureCooker from "../../assets/images/pressureCooker";
import { fetchInviteCount, updateInviteCount } from "../../service/blog";
import CoupleImage from "../../assets/images/couple";
import { getOrderStatus } from "../../service/order";
import { useNavigate } from "react-router-dom";
import RouterPath from "../../route/RouterPath";
import Icons from "../../assets/icons";
import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
  Icon,
} from "@mui/material";

interface OrderStatus {
  payment_status: string;
  membership_name: string;
  membership_starts_at: string;
  membership_ends_at: string;
}

const PaymentLayout = () => {
  const orderId = localStorage.getItem("pgorderId");
  const [membershipActivated, setMembershipActivated] = useState(false);
  const [paymentFailedOrUnknown, setPaymentFailedOrUnknown] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderStatus>();
  const navigate = useNavigate();

  function extractDate(dateString: any) {
    const date = new Date(dateString);
    // Use Date methods to extract year, month, and day
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();

    // Format the date as you need
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  }

  const getOrderDetails = async () => {
    console.log("checking order status");
    const result = await getOrderStatus(orderId!);
    console.log(result);
    if (result.payment_status === "PAID") {
      console.log("Order is successfull");
      setOrderDetails(result);
      setMembershipActivated(true);
    } else if (result.payment_status === "ACTIVE" || result.payment_status === "FAILED") {
      setPaymentFailedOrUnknown(true);
    } 
  };
  useEffect(() => {
    getOrderDetails();
  }, []);

  const redirectToDashboard = () => {
    localStorage.removeItem("pgdetails");
    localStorage.removeItem("pgorderId");
    navigate(RouterPath.Dashboard);
  };

  return (
    <div>
      {membershipActivated && (
        <div>
          <Grid
            container
            spacing={2}
            paddingLeft={"4px"}
            paddingRight={"4px"}
            width={"100%"}
            paddingBottom={"8px"}
            marginLeft={"0px !important"}
            marginTop={"0px !important"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <img src={Icons.Congrats} alt="congrats" />
            </Grid>

            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginTop={"20px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <ul>
                <li style={{"listStyleType":"none"}}>
                  <span>Membership Name</span>{" "}
                  <strong><span>{orderDetails?.membership_name}</span></strong>
                </li>

                <li style={{"listStyleType":"none", "marginTop":"20px"}}>
                  <span>Membership Starts</span>{" "}
                  <strong><span>{extractDate(orderDetails?.membership_starts_at)}</span></strong>
                </li>

                <li style={{"listStyleType":"none", "marginTop":"20px"}}>
                  <span>Membership Ends</span>{" "}
                  <strong> <span>{extractDate(orderDetails?.membership_ends_at)}</span></strong>
                </li>
              </ul>
            </Grid>

            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginTop={"20px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <Button
                variant="contained"
                style={{ width: "50%", marginLeft: "25%" }}
                onClick={() => {
                  redirectToDashboard();
                }}
              >
                CONTINUE
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {paymentFailedOrUnknown && (
        <div>
          <Grid
            container
            spacing={2}
            paddingLeft={"4px"}
            paddingRight={"4px"}
            width={"100%"}
            paddingBottom={"8px"}
            marginLeft={"0px !important"}
            marginTop={"0px !important"}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                color={"#000"}
                fontSize={20}
                fontWeight={400}
                marginTop={"12px"}
                paddingLeft={"20px"}
                style={{
                  fontWeight: "400",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                Payment Failed
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src={Icons.Error}
                alt="congrats"
                style={{ width: "200px", height: "200px" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginTop={"20px !important"}
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                color={"#000"}
                fontSize={16}
                fontWeight={400}
                marginTop={"12px"}
                paddingLeft={"20px"}
                style={{
                  fontWeight: "400",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                Unfortunately, your payment was not successful. In case any
                amount has been deducted from your account, it will be refunded
                within 7 working days. <br/><br/> In case you want to reach us, please drop an email at - <b>contact@thetwistapp.com</b>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              paddingTop={"0px !important"}
              paddingLeft={"0px !important"}
              marginTop={"20px !important"}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
              }}
            >
              <Button
                variant="contained"
                style={{ width: "50%", marginLeft: "25%" }}
                onClick={() => {
                  redirectToDashboard();
                }}
              >
                CONTINUE
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default PaymentLayout;
