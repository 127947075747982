import {
  Box,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Button,
  Collapse,
  TextField,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import Seperator from "../../../assets/images/seperator";
import { UserDate } from "../../../types/date";
import useUser from "../../../context/UserContext";
import { updateDateState } from "../../../service/date";
import parse from "html-react-parser";
import Checkbox from "@mui/material/Checkbox";
import { number } from "yup";
import Loading from "../../../components/Loading";
import heic2any from "heic2any";
import { uploadIDProof } from "../../../service/user";

interface VerifyAccountLayoutProps {
  onPressNext?: () => void;
  fontSize?: number;
  marginTop?: string;
  userdates?: UserDate;
  onButtonClick: (
    idProofUrl: string
  ) => void;
}

const VerifyAccountLayout = ({
  onPressNext,
  fontSize = 32,
  marginTop = "16px",
  userdates,
  onButtonClick,
}: VerifyAccountLayoutProps) => {
  const [openCompatibility, setOpenCompatibility] = useState(false);

  const [ischecked, setIsChecked] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [idProofUrl, setIdProofUrl] = useState("");
  const [linkedin_url, setlinkedin_url] = useState("");
  const [instagram_url, setinstagram_url] = useState("");
  const [showKnowMore, setShowKnowMore] = useState(false);

  const handleCompatibilityToggle = () => {
    setOpenCompatibility(!openCompatibility);
  };

  const [openDateDetails, setOpenDateDetails] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { userId } = useUser();

  const handleDateDetailsToggle = () => {
    setOpenDateDetails(!openDateDetails);
  };

  const openSavedDocument = (url: string) => {
    window.open(idProofUrl, "_blank");
  };

  const removedSavedDocument = (url: string) => {
    setIdProofUrl("");
    setIsFileUploaded(false);
  }

  const handleKnowMoreLinkClick = () => {
    setShowKnowMore(!showKnowMore);
  };

  const onSelectFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length && userId) {
        console.log("userId", userId);
        try {
          let file: File = event.target.files[0];
          setShowLoading(true);

          const fileBlocks = file.name.split(".");
          const fileExtension = fileBlocks[fileBlocks.length - 1];
          if (
            event.target.files[0].type === "image/heic" ||
            fileExtension === "heic"
          ) {
            const blob = (await heic2any({
              blob: file,
              toType: "image/jpeg",
            })) as Blob;
            fileBlocks[fileBlocks.length - 1] = "jpeg";
            const fileName = fileBlocks.join(".");
            file = new File([blob], fileName, { type: "image/jpeg" });
          }

          const result = await uploadIDProof({
            file: file,
            user: userId,
          });
          setIsFileUploaded(true);
          setIdProofUrl(result.data.url);
          setShowLoading(false);
        } catch (e) {
          // TODO
          setShowLoading(false);
        }
      }
    },
    []
  );

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"8px"}
      >
        <Card
          sx={{
            marginTop: "0px",
            padding: "12px 12px",
            background: "#FFFFFF",
          }}
        >
          {showKnowMore ? 
          <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "10px",
                backgroundColor: "#90ee90",
              }}
            >
              <span>We take these details to ensure the safety of our members. These details are mandatory and without these details, we will not be able to arrange a date for you. We do not save this information on our systems and also no 3rd party service or any user has access to this data. </span>
            </Grid>
          </Grid> : ""}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color={"#000"} fontSize={16} marginTop={"8px"}>
                Please verify your account by uploading your PAN card as a govt ID proof.
             
              <Link
                component="button"
                variant="body2"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  textAlign: "left",
                  fontSize: "14px",
                  marginLeft: "10px",
                  marginTop: "-1px",
                }}
                onClick={handleKnowMoreLinkClick}
              >
                {" "}
                Know More
              </Link>
              </Typography>{" "}
            </Grid>
          </Grid>

          {/* <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
            <Grid
              item
              xs={5}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Typography
                color={"#000"}
                fontSize={14}
                textTransform={"capitalize"}
                marginTop={"8px"}
                textAlign={"left"}
              >
                LinkedIn profile *
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={linkedin_url}
                onChange={(e) => {
                  setlinkedin_url(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid> */}
          {/* <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
            <Grid
              item
              xs={5}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Typography
                color={"#000"}
                fontSize={14}
                textTransform={"capitalize"}
                marginTop={"8px"}
                textAlign={"left"}
              >
                Instagram handle *
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                fullWidth
                value={instagram_url}
                onChange={(e) => {
                  setinstagram_url(e.target.value);
                }}
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
            <Grid
              item
              xs={5}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Typography
                color={"#000"}
                fontSize={14}
                textTransform={"capitalize"}
                marginTop={"8px"}
                textAlign={"left"}
              >
                PAN card
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                textAlign: "left",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Link
                component="button"
                variant="body2"
                hidden={!isFileUploaded}
                style={{
                  color: "gray",
                  textDecoration: "underline",
                  textAlign: "left",
                  marginTop: "5px",
                }}
                onClick={() => openSavedDocument("")}
              >View</Link>

              <Link
                component="button"
                variant="body2"
                lineHeight={"1.5"}
                hidden={!isFileUploaded}
                style={{
                  color: "gray",
                  textDecoration: "underline",
                  textAlign: "left",
                  marginLeft: "10px",
                  marginTop: "5px",
                }}
                onClick={() => removedSavedDocument("")}
              >Remove</Link>

              <Button variant="contained" component="label" hidden={isFileUploaded}>
                Upload File
                <input type="file" hidden accept="*" onChange={onSelectFile} />
              </Button>

              

            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={"16px"} paddingLeft={"16px"}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                paddingLeft: "0px",
                paddingTop: "0px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                // disabled={!linkedin_url || !instagram_url || !isFileUploaded}
                disabled={ !isFileUploaded}
                onClick={() =>
                  onButtonClick(idProofUrl)
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Loading isLoading={showLoading} />
    </>
  );
};

export default VerifyAccountLayout;
