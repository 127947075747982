import { Box, FormHelperText } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RadioItem from "./RadioItem";
import { Question, QuestionTypes } from "../../../types/signup";
import CheckBoxItem from "./CheckboxItem";
import TitleComponent from "../../../components/TitleComponent";
import NextButton from "../../../components/NextButton";
import Slider from "../../../components/Slider";
import TextBox from "../../../components/TextBox";
import Dropdown from "../../../components/Dropdown";
import ReactPixel from 'react-facebook-pixel';


interface QuestionLayoutProps {
  question: Question;
  onPressNext: () => void;
  onChangeValue: (value: string) => void;
}

const QuestionLayout = ({
  question,
  onPressNext,
  onChangeValue,
}: QuestionLayoutProps) => {
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    setShowError(false);
  }, [question]);

  const onChangeGender = useCallback(
    (value: string) => {
      onChangeValue(value);
    },
    [onChangeValue]
  );

  const onSubmit = useCallback(() => {
   
    if (question.value === "") {
      setShowError(true);
    } else {
      const customParameters = {
        eventkey:question.eventkey,
        value:question.value
      };
      ReactPixel.track('onboarding_questions_submission', customParameters);
      ReactPixel.track('onboarding_questions_submission_'+ question.eventkey, customParameters);
      if(question.eventkey === "gender"){
        localStorage.setItem("GENDER", question.value);
      }
      if (question.eventkey === "gender" && question.value === "WM") {
        ReactPixel.track('onboarding_questions_submission_women', customParameters);
      }
      if (question.eventkey === "relationship_status" && question.value === "SP") {
        ReactPixel.track('onboarding_questions_submission_SP', customParameters);
      }
      if(question.eventkey === "salary_range"){
        const salary_range = question.value;
        const gender = localStorage.getItem("GENDER");
        const year_of_birth = localStorage.getItem("YEAR_OF_BIRTH");
        const age = new Date().getFullYear() - parseInt(year_of_birth!) + 1
        const age_range = age < 25 ? "18-24" : age < 30 ? "25-29" : age < 35 ? "30-34" : age < 40 ? "35-39" : age < 45 ? "40-44" : age < 55 ? "45-54" : "55+";

        const customParameters = {
          salary_range: salary_range,
          gender: gender,
          year_of_birth: year_of_birth,
          age: age,
          age_range: age_range
        };

        const event_key = 'user_cohort_gender_age_salary_range_' + gender + "_" + age_range + "_" + salary_range;

        const validSalaryRanges = ["ST", "TT", "TF", "FA"];
        if (validSalaryRanges.includes(salary_range)) {
          if (gender === "MN") {
            const event_key = 'HIVLMN';
            ReactPixel.track(event_key, customParameters);
          }
        }
        
        console.log(event_key);
        ReactPixel.track(event_key, customParameters );
      }
      onPressNext();
    }
  }, [question, onPressNext]);

  const values = useMemo(() => question.value.split(","), [question.value]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        width={{ xs: "100%", md: "450px" }}
        marginTop={"40px"}
      >
        <TitleComponent
          title={question.question}
          subtitle={question.subtitle}
        />
        <Box overflow={question.type === QuestionTypes.SLIDER ? 'auto': "scroll"} height={"50vh"} marginTop={"16px"}>
          {question.type === QuestionTypes.RADIO && (
            <>
              {question.options.map((item, index) => {
                return (
                  <>
                    <RadioItem
                      key={index.toString()}
                      item={item}
                      value={question.value}
                      handleChange={onChangeGender}
                    />
                  </>
                );
              })}
            </>
          )}
          {question.type === QuestionTypes.CHECKBOX && (
            <>
              {question.options.map((item, index) => {
                return (
                  <CheckBoxItem
                    key={index.toString()}
                    item={item}
                    values={values}
                    handleChange={onChangeGender}
                  />
                );
              })}
            </>
          )}
          {question.type === QuestionTypes.SLIDER && (
            <>
              <Slider
                options={question.options ?? []}
                value={question.value}
                handleChange={onChangeGender}
              />
            </>
          )}
          {question.type === QuestionTypes.TEXTBOX && (
            <>
              <TextBox
                value={question.value}
                handleChange={onChangeGender}
              />
            </>
          )}
          {question.type === QuestionTypes.DROPDOWN && (
            <>
              <Dropdown
                options={question.options}
                value={question.value}
                handleChange={onChangeGender}
              />
            </>
          )}
         <span style={{fontSize:"14px", fontWeight:"500"}}>{question.metadata}</span>
        </Box>

        {showError && (
          <FormHelperText error={true}>{question.error}</FormHelperText>
        )}
      </Box>
      <NextButton onPressNext={onSubmit} />
    </>
  );
};
export default QuestionLayout;
