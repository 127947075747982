// mobile.component.jsx
import React, { useState, useRef, useCallback, useEffect, useMemo, } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LogoComponent from "./LogoComponent";
import LogoComponentHeader from "./LogoComponentHeader";
import { FaQuoteRight } from "react-icons/fa"; // Import the quote icon from react-icons
import { Button, Carousel, Modal, Form } from "react-bootstrap";
import "./mobile.styles.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Instagram, Twitter } from "react-bootstrap-icons";
import RouterPath from "../../../route/RouterPath";
import EnterEmailLayout from "../../Signup/EmailLayout";
import { ErrorData } from "../../../types/signup/User";

interface SignupProps {
 onSubmitEmail: (value: string) => void;
  sendOTPError?: ErrorData;
}
export const Mobile = ({ onSubmitEmail, sendOTPError }: SignupProps) => {
  const sectionOneRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const scrollToSection = () => {
    if (sectionOneRef)
      sectionOneRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  //sign up complete modal settings
  const [showSignUpCompleteModal, setShowSignUpCompleteModal] = useState(false);
  const handleSignupCompleteCloseModal = () => {
    setShowSignUpCompleteModal(false);
  };
  const handleShowSignUpCompleteModal = () => {
    setShowSignUpCompleteModal(true);
  };

  //terms & conditions modal settings
  const [showTermsModal, setShowTermsModal] = useState(false);
  const handleTermsCloseModal = () => {
    setShowTermsModal(false);
  };
  const handleShowTermsModal = () => {
    setShowTermsModal(true);
  };

  //cancellation modal settings
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const handleCancellationCloseModal = () => {
    setShowCancellationModal(false);
  };
  const handleShowCancellationModal = () => {
    setShowCancellationModal(true);
  };

  //contact modal settings
  const [showContactModal, setShowContactModal] = useState(false);
  const handleContactCloseModal = () => {
    setShowContactModal(false);
  };
  const handleShowContactModal = () => {
    setShowContactModal(true);
  };

  //faq modal settings
  const [showFaqModal, setShowFaqModal] = useState(false);
  const handleFaqCloseModal = () => {
    setShowFaqModal(false);
  };
  const handleShowFaqModal = () => {
    setShowFaqModal(true);
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    city: "",
    dob: "",
    relationType: "",
  });
  const handleSignupCloseModal = () => {
    setShowSignUpModal(false);
  };
  const handleShowSignUpModal = () => {
    // setShowSignUpModal(true);
    navigate(RouterPath.Signup);
  };

  const handleSignupChange = (e: any) => {
    const { name, value } = e.target;
    setSignUpFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignupSubmit = (e: any) => {
    e.preventDefault();

    const requestData = {
      // Data to be sent in the request payload
      // Modify this as per your API requirements
      user_first_name: "John",
      user_last_name: "Doe",
      user_dob: "1990-01-01",
      user_gender: "M",
      user_phone_number: "9008992736",
    };

    fetch("https://110e-49-206-131-236.ngrok-free.app/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        handleSignupCloseModal();
        handleShowSignUpCompleteModal();
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        //alert("error");
        console.error("Error:", error);
      });
  }; 

  return (
    <>
      <div className="mobile">
        <div className="background-container">
          <div className="fullscreen-section">
            <LogoComponent /> {/* Include the LogoComponent here */}
            <div className="overlay" id="signup">
              <img className="logo" />

              <div className="textContainer">
                <h1>Meet your <br/><u>Perfect</u> Companion <br/> on a Coffee Date!</h1>
              </div>
              <EnterEmailLayout
                onPressNext={onSubmitEmail}
                sendOTPError={sendOTPError}
              />
            </div>
          </div>
          <div className="content">
            <div className="why-twist" id="why-twist" ref={sectionOneRef}>
              <div className="twist-explaination-container">
                <p className="twist-explaination-container-heading">
                 <h1>Twist sets up a coffee date with your best match.</h1>
      
                </p>

                <p className="twist-explaination-container-text">
                 We do the filtering for you. <b> Verified members only.  No spams. No fake profiles!</b>

                </p>

                <p className="twist-explaination-container-text">
                Meet your best match and <b>check your compatibility over a coffee. No swiping left, right or center</b>.
                </p>
               
                <p className="twist-explaination-container-text">
                 
                   <b>Affordable</b>.

                </p>

                <p>
                  After you create an account, we find like minded people and
                  set them up on a coffee date with an amazing person like you
                  at a safe & cozy cafe.
                </p>
                <a
                  className="btn btn-custom btn-lg rounded"
                  href="#signup"
                  color="white"
                >
                  Create account
                </a>


              </div>
            </div>
            <div className="twist-testimonials">
              <div className="testimonial-carousel">
                <Carousel>
                  <Carousel.Item>
                    <div className="testimonial-item">
                      <p className="testimonial-people-name">Aparna</p>
                      <p className="testimonial-text">
                        I was using a lot of existing Dating apps but I had to
                        do a lot of filtering as I was getting hundereds of
                        messages daily and the conversations were not that
                        great. Twist saved a lot of time by finding me the right
                        match and providing the right place to have a great
                        conversation.
                      </p>
                      <div className="quote-icon">
                        <FaQuoteRight />
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item>
                    <div className="testimonial-item">
                      <p className="testimonial-people-name">Kirti & Rahul</p>
                      <p className="testimonial-text">
                        Date over a coffee is a new thing. The conversation
                        became really nice and we were able to find our
                        compatibility really fast. It's much better than trying
                        to find a genuine match among hunderds of fake accounts
                        and bots. We were able to talk on various things such as
                        our likes and dislikes and the entire experience was
                        really nice.
                      </p>
                      <div className="quote-icon">
                        <FaQuoteRight />
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item>
                    <div className="testimonial-item">
                      <p className="testimonial-people-name">Joice</p>
                      <p className="testimonial-text">
                        It was a great experience to meet some you don't know
                        over a coffee date. It was fun and thrilling at the same
                        time. The best part of the date was that you get to know
                        your compatibility really fast. I highly recommend this
                        service to someone who is looking for serious
                        relationships.
                      </p>
                      <div className="quote-icon">
                        <FaQuoteRight />
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="footer">
              <div className="footer-content">
                <ul>
                  <li className="footer-link-list">
                    <a href="#" className="col-sm-3 col-xs-3 col-md-3">
                      <Instagram size={32} color={"#006f62"} />
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" className="col-md-6">
                      <Twitter size={32} color={"#006f62"} />
                    </a>
                  </li>
                </ul>

                <ul className="footer-links">
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowContactModal}>
                      Contact Us
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowTermsModal}>
                      Terms
                    </a>
                  </li>
                  <li className="footer-link-list">
                    <a href="#" onClick={handleShowFaqModal}>
                      FAQs
                    </a>
                  </li>
                  {/* Add more links as needed */}
                </ul>
              </div>
            </div>
          </div>
          <div className="modals">
            <div className="terms-modal">
              <Modal
                show={showTermsModal}
                onHide={handleTermsCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Terms</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      Before you begin using Twist , we want to ensure that you
                      are aware of our terms and conditions. By accessing and
                      using our services, you agree to be bound by the following
                      terms and conditions:
                      <br />
                      <br />
                      <b>Eligibility:</b> You must be at least 18 years old to
                      use our dating service. By using our service, you confirm
                      that you are at least 18 years old. You must be unmarried.
                      By using our service, you confirm that you are unmarried.
                      You do not have any unspent convictions, or are subject to
                      any court order, relating to assault, violence, sexual
                      misconduct or harassment.
                      <br />
                      <br />
                      <b>Account Creation:</b> To use our service, you must
                      create an account by providing accurate and complete
                      information about yourself. You must also maintain the
                      confidentiality of your account login credentials and
                      notify us immediately of any unauthorized use of your
                      account.
                      <br />
                      <br />
                      <b>Safety:</b> The company currently does not conduct any
                      criminal and/or other formal background checks of all its
                      users and also does not attempt to verify the statements
                      of its users. You are solely responsible for taking all
                      appropriate safety precautions in connection with the use
                      of the website and app and contacting other users. You
                      accept that there are risks interacting online or offline
                      with other users, including dating and meeting other
                      users. We do not guarantee or verify the accuracy of
                      information provided to you by other users. Twist makes
                      every effort to keep the information made available on the
                      website accurate and up to date, but we do not guarantee
                      that the information is accurate, complete or current. No
                      rights can be derived from it. Any reliance on the
                      provided information is at your own risk. We do not
                      warrant that the website will be available uninterrupted
                      and in a fully operating condition. All content and
                      services on the website or app are provided on an "as is"
                      and "as available" basis. Any decisions or actions taken
                      by you on the basis of the information provided on or via
                      the website and app are at your sole discretion and risk.
                      <br />
                      <br />
                      <b>Risks:</b> Our dating service is provided for
                      entertainment purposes only, and we do not guarantee that
                      you will find a romantic partner or that any date will be
                      successful. You use our service at your own risk.
                      <br />
                      <br />
                      <b>Liability:</b> We are not liable for any damages,
                      including but not limited to emotional distress, injury,
                      or loss, arising from your use of our dating service.
                      <br />
                      <br />
                      <b>User Conduct:</b> You must use our service in a lawful
                      and respectful manner. You agree not to use our service to
                      harass, stalk, or harm any other user. You also agree not
                      to disclose any personal information about any other user
                      without their consent. You will not use the Twist for
                      soliciting your own business activities or for commercial
                      purposes. You will not provide inaccurate, misleading or
                      false information to Twist or to any other user. You will
                      not use the service to disrupt public order. You will not
                      infringe upon the normal running of Twist or its
                      infrastructure in any way. You will immediately cease
                      contacting any user who asks you to stop contacting them.
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="cancellation-modal">
              <Modal
                show={showCancellationModal}
                onHide={handleCancellationCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. Lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSignupSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="contact-modal">
              <Modal
                show={showContactModal}
                onHide={handleContactCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      In case of any queries or concerns please reach out to
                      contact@thetwistapp.com
                    </p>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="faq-modal">
              <Modal
                show={showFaqModal}
                onHide={handleFaqCloseModal}
                dialogClassName="custom-modal"
                contentClassName="custom-modal-content"
              >
                <Modal.Header closeButton className="custom-modal-header">
                  <Modal.Title>FAQs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="success-message-container">
                    <p className="twist-signup-complete-description">
                      <b>
                        1. Why can’t I see the picture of the person whom I am
                        going to meet?
                      </b>
                      <br />
                      First of all most of the pictures on other dating apps are
                      either fake or they have used a lot of filters. So photos
                      are not the best way to find how the person looks. We take
                      into consideration the photos which members add before
                      suggesting a match but overall meeting a person directly
                      without having any expectations is the best way to meet.
                      You never know you might find a really good friend.
                      <br></br>
                      <b>2. Is this like a Blind date?</b>
                      <br />
                      Sort of. But with a change, you will know some details
                      about your match before hand.
                      <br></br>
                      <b>3. How do I know this is a safe option?</b>
                      <br />
                      Safety of our members really matters to us. So the first
                      thing we have done is that we take government proof of all
                      the users - DL/ Passport/ Aadhar card. We match the photos
                      on the proofs with the photos provided by the user so that
                      we know that identity is correct. In addition to that, we
                      only pick cafes in busy locations where you and your match
                      is safe and it provides a good environment to have a good
                      chat. But in the end, you would need to take precaution
                      and don’t trust the match till you spend a lot of time
                      with him/ her.
                      <br></br>
                      <b>4. How long will my date last?</b>
                      <br />
                      For us the date ends in 45 mins. You and your match can
                      extend or reduce the time based on the conversation.
                      <br></br>
                      <b>5. What happens if my match doesn’t show up?</b>
                      <br />
                      This is possible. Sometimes people can’t keep up the
                      commitment and it will be a bad experience for anyone. We
                      will try to avoid it by confirming with both the people a
                      day before the date. If one of the users denies or is
                      unresponsive to our calls, we will cancel the date.
                      <br></br>
                      <b>6. What happens if my date is canceled?</b>
                      <br />
                      This is how our cancellation policy works in a nutshell -
                      If your date doesn't show up, we will refund 100% money
                      back to the payment method which was used to make the
                      payment in 7 working days. If you confirmed going for a
                      date and you don’t show up for the date, there will be
                      zero refunds.
                    </p>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSignupSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
